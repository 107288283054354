import { SnackbarKey, useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { removeSnackbar } from 'store/notifier/actions'
import { getNotifications } from 'store/selectors'

let displayed: SnackbarKey[] = []

interface StateProps {
  notifications: ReduxStore.Notifications.KeyedNotification[]
}

interface DispatchProps {
  removeSnackbar: (key: SnackbarKey) => void
}

type Props = StateProps & DispatchProps

const SnackBarNotifier: React.FunctionComponent<Props> = props => {
  const { notifications, removeSnackbar } = props
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          removeSnackbar(myKey)
          removeDisplayed(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar])

  return null
}

const mapStateToProps = (state: ReduxStore.State) => ({
  notifications: getNotifications(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  removeSnackbar: (key: SnackbarKey) => dispatch(removeSnackbar(key)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackBarNotifier)
