const initState: ReduxStore.Drawings.State = {
  isFetching: false,
  list: [],
  updateFloorplanAndTableCanvas: 0,
}

export default function drawingsReducer(
  state: ReduxStore.Drawings.State = initState,
  action: ReduxStore.Drawings.Action
) {
  switch (action.type) {
    case 'REQUEST_DRAWINGS':
      return {
        ...state,
        isFetching: true,
      }

    case 'RECEIVE_DRAWINGS': {
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      }
    }

    case 'UPDATE_FLOORPLAN_AND_TABLE_CANVAS_DIMENSIONS': {
      return {
        ...state,
        updateFloorplanAndTableCanvas: action.payload,
      }
    }

    default:
      return state
  }
}
