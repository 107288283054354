import React from 'react'
import { withRouter } from 'react-router-dom'
export interface DataloaderInfo {
  projectId: number
  selectedProject: number | null
  selectedDrawing: string | null
  selectedMaterialType: number | null
  match: any
  location: any
  history: any
}

function withSelectedValues(Component: React.ComponentType<DataloaderInfo>) {
  return withRouter(({ match, location, history, ...rest }) => {
    const {
      params: { selectedProject, projectId },
    } = match
    const params = new URLSearchParams(location.search)
    const drawing = params.get('drawing')
    const materialType = params.get('materialType')
    return (
      <Component
        projectId={projectId}
        selectedProject={selectedProject || null}
        selectedDrawing={drawing}
        selectedMaterialType={materialType ? parseInt(materialType, 10) : null}
        match={match}
        location={location}
        history={history}
        {...rest}
      />
    )
  })
}

export default withSelectedValues
