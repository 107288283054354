const initState: ReduxStore.Draft.State = {
  isEnabled: false,
  isPending: false,
  status: 'Default',
}

export default function DraftReducer(
  state: ReduxStore.Draft.State = initState,
  action: ReduxStore.Draft.Action
): ReduxStore.Draft.State {
  switch (action.type) {
    case 'FETCH_DRAFT':
      return { ...state, isPending: true }
    case 'FETCH_DRAFT_SUCCESS':
      return { ...state, ...action.payload, isPending: false, status: 'FETCH_DRAFT_SUCCESSS' }
    case 'FETCH_DRAFT_ERROR':
      return { ...state, isPending: false }
    case 'CREATE_DRAFT':
      return { ...state, isPending: true, status: 'CREATE_DRAFT' }
    case 'CREATE_DRAFT_SUCCESS':
      return { ...state, ...action.payload, isPending: false, status: 'CREATE_DRAFT_SUCCESS' }
    case 'CREATE_DRAFT_ERROR':
      return { ...state, isPending: false }
    case 'CLEAN_UP_DRAFT':
      return { ...state, isPending: true }
    case 'CLEAN_UP_DRAFT_SUCCESS':
      return { ...state, ...action.payload, isPending: false, status: 'CLEAN_UP_DRAFT_SUCCESS' }
    case 'CLEAN_UP_DRAFT_ERROR':
      return { ...state, isPending: false }
    case 'INIT_DRAFT':
      return { ...state, isPending: true }
    case 'INIT_DRAFT_SUCCESS':
      return { ...state, ...action.payload, isPending: false }
    case 'INIT_DRAFT_ERROR':
      return { ...state, isPending: false }
    case 'PUBLISH_DRAFT':
      return { ...state, isPending: true, status: 'PUBLISH_DRAFT' }
    case 'PUBLISH_DRAFT_SUCCESS':
      return { ...state, ...action.payload, isPending: false, status: 'PUBLISH_DRAFT_SUCCESS' }
    case 'PUBLISH_DRAFT_ERROR':
      return { ...state, isPending: false }
    case 'TOGGLE_DRAFT_MODE':
      return { ...state, ...action.payload }
    case 'SET_STATUS':
      return { ...state, ...action.payload }
    default:
      return state
  }
}
