import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import Swal from 'sweetalert2'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

interface Props {
  errors: ReduxStore.Error.Data[]
  removeError: (index: number) => void
}

const Error: React.FC<Props> = props => {
  const { errors, removeError } = props

  // Push url when draftdata changed
  useEffect(() => {
    if (errors.length === 0) {
      return
    }

    const index = errors.length - 1
    const error = errors[index]
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: `${error.error}! ${error.actionType}`,
    })
    // Remove error
    removeError(index)
  }, [errors])

  return <React.Fragment />
}

const mapStateToProps = (state: ReduxStore.State) => ({
  errors: state.error.errors,
})

const mapDispatchToProps = (dispatch: any) => ({
  removeError: (index: number) => dispatch(action.removeError(index)),
})

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Error)
)
