import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardActions from '@material-ui/core/CardActions/CardActions';
import CardContent from '@material-ui/core/CardContent/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { isFailedStatus } from 'utils/status';
import classNames from 'utils/classNames';
import action from 'store/actions';
import { getLoginStatus } from 'store/selectors';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  card: {
    width: '400px',
  },
  justifyRight: {
    justifyContent: 'flex-end',
  },
});

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  };

  getErrorMessage() {
    const { loginStatus } = this.props;
    if (isFailedStatus(loginStatus)) {
      switch (loginStatus.error) {
        case 'Invalid Parameters.':
          return 'Invalid email or password';

        default:
          return 'Unknown error';
      }
    }
    return '';
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleLogin = (event) => {
    event.preventDefault();
    const { dispatchLogin } = this.props;
    const { email, password } = this.state;
    dispatchLogin(email, password);
  };

  loginForm() {
    const { classes } = this.props;
    const { email, password } = this.state;
    const loginError = this.getErrorMessage();

    return (
      <div className={classNames(classes.root, 'hide-splash')}>
        <Card className={classes.card}>
          <form onSubmit={this.handleLogin}>
            <CardContent>
              <Typography component="h1" variant="h5" gutterBottom>Login</Typography>
              <TextField
                id="input-email"
                label="Email or username"
                value={email.value}
                onChange={this.handleEmailChange}
                margin="normal"
                variant="outlined"
                fullWidth
                autoFocus
                required
              />
              <TextField
                id="input-password"
                label="Password"
                type="password"
                value={password.value}
                onChange={this.handlePasswordChange}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />

              {loginError && (
                <Typography color="error">
                  {loginError}
                </Typography>
              )}
            </CardContent>

            <CardActions className={classes.justifyRight}>
              <Button type="submit" color="primary" variant="contained">Login</Button>
            </CardActions>
          </form>
        </Card>
      </div>
    );
  }

  render() {
    return this.loginForm();
  }
}

const mapStateToProps = state => ({
    loginStatus: getLoginStatus(state),
  })

const mapDispatchToProps = {
  dispatchLogin: action.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
