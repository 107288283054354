import CONST from 'const'
import {
  drawGuides,
  getAnchorPoints,
  getGuides,
  getLineGuideStops,
} from 'dataloader/utils/guideLines'
import Konva from 'konva'
import { chunk, cloneDeep, findIndex, round } from 'lodash'
import React, { useState } from 'react'
import { Circle, Group, Line } from 'react-konva'
import { jsonParse } from 'utils/json'
import { throttleRaf } from 'utils/throttleRaf'
import { v4 as uuidv4 } from 'uuid'

interface PointsPos {
  x: number
  y: number
}

interface Props {
  selectedMaterials: Array<number | string>
  material: ReduxStore.Materials.Data.IMaterial
  scale: { x: number; y: number }
  onMouseDown: (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragStart: (overlayId: string | number) => void
  onDragMove: (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragEnd: (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => void
  dispatchUpdateCirclePos: (newPos: ReduxStore.Materials.Data.INewCirclePos) => void
  snappingEnabled: boolean
  snappingDistance: number
}

const Polygon: React.FC<Props> = props => {
  const lineRef = React.createRef<any>()
  const [highlightedCircleIndex, setHighlightedCircleIndex] = useState<number>(-1)

  const {
    selectedMaterials,
    material,
    scale,
    onMouseDown,
    onDragStart,
    onDragMove,
    onDragEnd,
    dispatchUpdateCirclePos,
  } = props
  const { overlay_id, shape_data } = material
  const selected = selectedMaterials.some(id => id === overlay_id)
  const color = selected ? CONST.OVERLAY_YELLOW : CONST.OVERLAY_BLUE

  const shapeData = jsonParse(shape_data)
  if (!shapeData) {
    return <></>
  }

  const points = shapeData.points
  const chunkedPoints = chunk(points, 2) as Array<[number, number]>

  const handleCircleMouseOver = throttleRaf((evt: Konva.KonvaEventObject<MouseEvent>) => {
    const pointIndex = findIndex(
      chunkedPoints,
      point => point[0] === evt.target.x() && point[1] === evt.target.y()
    )
    setHighlightedCircleIndex(pointIndex)
  })

  const eventToCoordinates = (evt: Konva.KonvaEventObject<MouseEvent>): PointsPos => {
    const transform = evt.target.getAbsoluteTransform().copy()
    transform.invert()
    const stage = evt.target.findAncestor('Stage')
    // @ts-ignore
    const pos = transform.point(stage.getPointerPosition())
    return { x: round(pos.x, 2), y: round(pos.y, 2) }
  }

  const handleCircleDragMove = throttleRaf(
    (evt: Konva.KonvaEventObject<MouseEvent>, pointIndex: number) => {
      evt.cancelBubble = true
      if (!evt.target.getLayer()) {
        return
      }
      evt.target
        .getLayer()
        .find('.guid-line')
        .each((node: Konva.Node) => node.destroy())

      const lineGuideStops = getLineGuideStops(
        evt.target,
        eventToCoordinates(evt),
        props.snappingDistance
      )
      const itemBounds = getObjectSnappingEdges(evt)
      const guides = getGuides(lineGuideStops, itemBounds)
      const { presetX, presetY } = getAnchorPoints(guides)

      if (props.snappingEnabled) {
        drawGuides(guides, evt.target)
        if (presetX) {
          evt.target.x(presetX)
        }
        if (presetY) {
          evt.target.y(presetY)
        }
      }

      const posX = round(evt.target.x(), 2)
      const posY = round(evt.target.y(), 2)
      const lineShape = lineRef.current
      if (lineShape) {
        lineShape.attrs.points[pointIndex * 2] = posX
        lineShape.attrs.points[pointIndex * 2 + 1] = posY
      }
    }
  )

  const getObjectSnappingEdges = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const pos = { x: evt.target.x(), y: evt.target.y() }
    if (pos.x && pos.y) {
      return {
        vertical: [
          {
            guide: Math.round(pos.x),
            offset: 0,
            snap: 'start',
          },
        ],
        horizontal: [
          {
            guide: Math.round(pos.y),
            offset: 0,
            snap: 'start',
          },
        ],
      }
    }
  }

  const handleCircleMouseLeave = () => {
    setHighlightedCircleIndex(-1)
  }

  const handleCircleDragEnd = (evt: Konva.KonvaEventObject<MouseEvent>, pointIndex: number) => {
    evt.cancelBubble = true
    evt.target
      .getLayer()
      .find('.guid-line')
      .each((node: Konva.Node) => node.destroy())

    const posX = round(evt.target.x(), 2)
    const posY = round(evt.target.y(), 2)
    dispatchUpdateCirclePos({ pos: [posX, posY], pointIndex, material: cloneDeep(material) })
  }

  return (
    // @ts-ignore
    <Group
      ref={instance => instance && window.shapeRefs.push(instance)}
      key={`${overlay_id}-${JSON.stringify(points)}`} // must force to change the key so that the polygon can be rendered again
      shapeId={overlay_id}
      name={overlay_id.toString()}
      materialBelongsTo={overlay_id}
      shape="polygon"
      draggable={true}
      onMouseDown={evt => onMouseDown(evt, material)}
      onDragStart={() => onDragStart(overlay_id)}
      onDragMove={evt => onDragMove(evt, material)}
      onDragEnd={evt => onDragEnd(evt, material)}
      points={cloneDeep(points)}
      x={0}
      y={0}
    >
      {
        <Line
          ref={lineRef}
          points={cloneDeep(points)}
          scaleX={1}
          scaleY={1}
          closed={true}
          fill={color}
        />
      }
      {selectedMaterials &&
        selectedMaterials.some(id => id === overlay_id) &&
        chunkedPoints.map((point, pointIndex) => (
          <Circle
            draggable={true}
            key={uuidv4()}
            x={point[0]}
            y={point[1]}
            radius={highlightedCircleIndex === pointIndex ? 10 / scale.x : 4 / scale.x}
            stroke="red"
            strokeWidth={Math.round(2 / scale.x)}
            fill={color}
            onMouseDown={evt => evt.evt.stopPropagation()}
            onMouseEnter={handleCircleMouseOver}
            onMouseLeave={handleCircleMouseLeave}
            onDragMove={evt => {
              handleCircleDragMove(evt, pointIndex)
            }}
            onDragEnd={evt => {
              handleCircleDragEnd(evt, pointIndex)
            }}
          />
        ))}
    </Group>
  )
}

export default Polygon
