import { padStart } from 'lodash'

export default function incrementSuffix(value: string, increment: number = 1) {
  if (!value || value.trim() === '') {
    return `${Math.max(0, increment)}`
  }
  // find the numeric suffix on the value if it exists
  const numericSuffixMatchResult = value.match(/[0-9]+$/)
  if (numericSuffixMatchResult == null) {
    return `${value}${Math.max(0, increment)}`
  }
  const numericSuffix = numericSuffixMatchResult.pop()
  const incremented = Math.max(0, Number(numericSuffix) + increment)
  // @ts-ignore
  const padded = padStart(incremented, numericSuffix.length, '0')
  // @ts-ignore
  const prefix = value.slice(0, value.lastIndexOf(numericSuffix))
  return prefix + padded
}
