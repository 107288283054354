export const selectTool = (toolName: string): ReduxStore.Tool.SelectTool => ({
  type: 'SELECT_TOOL',
  payload: toolName,
})

export const setSnappingEnabled = (enabled: boolean): ReduxStore.Tool.SetSnappingEnabled => ({
  type: 'SET_SNAPPING_ENABLED',
  payload: enabled,
})

export const setHotTableRef = (hotTableRef: any): ReduxStore.Tool.SetHotTableRef => ({
  type: 'SET_HOTTABLE_REF',
  payload: hotTableRef,
})

export const setToolOptions = (
  toolName: string,
  options: ReduxStore.Tool.ToolOptions
): ReduxStore.Tool.SetToolOptions => ({
  type: 'SET_TOOL_OPTIONS',
  toolName,
  options,
})

export const updateSnappingDistance = (
  snappingDistance: number
): ReduxStore.Tool.SetSnappingDistance => ({
  type: 'SET_SNAPPING_DISTANCE',
  payload: snappingDistance,
})

export const updateAlignDistance = (alignDistance: number): ReduxStore.Tool.SetAlignDistance => ({
  type: 'SET_ALIGN_DISTANCE',
  payload: alignDistance,
})

export const updateImage = (image: HTMLImageElement): ReduxStore.Tool.SetImage => ({
  type: 'SET_IMAGE',
  payload: image,
})
