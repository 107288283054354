import { WithStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { AddCircle, ExposurePlus1, ExposureZero, RemoveCircle } from '@material-ui/icons'
import React from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import { incrementMaterialPresets, setAutoIncrement } from 'store/materialPresets/actions'
import {
  getAutoIncrementPresetsEnabled,
  getDescriptions,
  getDescriptionTypes,
  getListIds,
  getListIdValueMap,
  getMaterialTypePresets,
  getSnappingEnabled,
} from 'store/selectors'
import { getHotColumns } from 'utils/hotTable'
import withSelectedValues from 'utils/withSelectedValues'

const heightTransitionMS = 400

const styles = (theme: Theme) => ({
  root: {
    position: 'absolute',
    left: '350px',
    top: '30px',
    borderRadius: '15px',
    padding: '22px 17px',
    width: '242px',
    maxHeight: 'calc(50% - 82px)',
    overflow: 'auto',
    transition: `all ${heightTransitionMS}ms ease-in-out;`,
    transitionProperty: 'width, max-height',
  },
  rootCollapsed: {
    maxHeight: '25px',
    width: '122px',
  },
  rootDisabledScroll: {
    overflow: 'hidden',
  },
  rootEnabledScroll: {
    overflow: 'auto',
  },
  presetLabel: {
    color: '#4986FF',
    fontFamily: 'Muli',
    fontSize: '16px',
    fontWeight: 600,
  },
  addField: {
    color: '#4986FF',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
    paddingLeft: '8px',
  },
  addFieldHasFields: {
    paddingTop: '5px',
  },
  selectGrid: {
    flexGrow: 10,
    maxWidth: '182px',
  },
  selectGridItemCross: {
    flexGrow: 1,
  },
  selectCross: {
    float: 'right',
  },
  collapseButton: {
    position: 'absolute',
    left: '108px',
    top: '0',
    transition: `top ${heightTransitionMS}ms ease-in-out`,
    zIndex: 1,
  },
  collapsedCollapseButton: {
    top: '12px',
  },
  displayNone: {
    display: 'none',
  },
})

interface StateProps {
  selectedMaterialType: string
  descriptions: string[]
  selectedDrawing: string | null
  materialShapeData: string[]
  descriptionTypes: string[]
  listIds: number[]
  listIdValueMap: { [key in string]: string[] }
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  autoIncrementEnabled: boolean
  snappingEnabled: boolean
}

interface ComponentState {
  showAddField: boolean
  addedFields: string[]
  collapsed: boolean
  enableScroll: boolean
  displayFields: boolean
  collapsing: boolean
  prevMaterialType: string | null
  hasIncrementors: boolean
}

interface DispatchProps {
  setMaterialPresets: (presets: ReduxStore.MaterialPresets.MaterialPreset[]) => void
  deleteMaterialPresets: (presets: ReduxStore.MaterialPresets.MaterialPreset[]) => void
  resetMaterialPresets: () => void
  incrementPresets: (materialTypeId: number, increment?: number) => void
  setAutoIncrementEnabled: (enabled: boolean) => void
  dispatchSnappingEnabled: (enabled: boolean) => void
}

type Props = WithStyles<StyleRules> & StateProps & DispatchProps

class MaterialPresets extends React.Component<Props, ComponentState> {
  static getDerivedStateFromProps(props: Props, state: ComponentState) {
    // Reset added fields when we switch material type.
    if (props.selectedMaterialType !== state.prevMaterialType) {
      return {
        prevMaterialType: props.selectedMaterialType,
        addedFields: [],
      }
    }
    return null
  }

  state = {
    showAddField: false,
    addedFields: Array(),
    collapsed: false,
    displayFields: true,
    enableScroll: true,
    collapsing: false,
    prevMaterialType: null,
    hasIncrementors: false,
    snappingEnabled: false,
  }
  _collapseTimeOut: number | undefined = undefined

  getPresetOrInit = (fieldName: any) => {
    return (
      this.props.presets.find(preset => preset.description === fieldName) ||
      this.blankPresetForField(fieldName)
    )
  }

  blankPresetForField = (fieldName: any) => {
    return {
      materialTypeId: this.props.selectedMaterialType,
      description: fieldName,
      value: '',
      increment: false,
    }
  }

  handleAddField = (event: any) => {
    const addedFields: string[] = [...this.state.addedFields, event.target.value]
    this.setState({ addedFields, showAddField: false })
  }

  handleRemoveField = (field: string) => {
    const addedFields: string[] = this.state.addedFields.filter(
      existingField => existingField !== field
    )
    this.setState({ addedFields })
    this.props.deleteMaterialPresets([this.blankPresetForField(field)])
  }

  handleSetPresetValues = (fieldName: any, values: { value?: string; increment?: boolean }) => {
    const preset = this.getPresetOrInit(fieldName)
    this.props.setMaterialPresets([
      {
        ...preset,
        ...values,
      },
    ])
  }

  handleCollapse = () => {
    const oldCollapsedState = this.state.collapsed

    this.setState({
      collapsing: true,
      collapsed: !this.state.collapsed,
      enableScroll: false,
      displayFields: true,
    })
    clearTimeout(this._collapseTimeOut)
    // Enable scroll after height transition effect.
    if (oldCollapsedState) {
      // update after expand
      this._collapseTimeOut = window.setTimeout(() => {
        this.setState({ enableScroll: true, collapsing: false })
      }, heightTransitionMS)
    } else {
      // update after collapse
      this._collapseTimeOut = window.setTimeout(() => {
        this.setState({ displayFields: false, collapsing: false })
      }, heightTransitionMS)
    }
  }

  checkForIncrementors() {
    const hasIncrementor = this.props.presets.find(preset => preset.increment === true)
    this.setState({ hasIncrementors: hasIncrementor !== undefined })
  }

  addOrRemoveDisplayNoneOnAddAFieldBox = (e: any) => {
    const { classes } = this.props
    // Prevent event bubbling
    e.stopPropagation()
    // Add displayNone class if it is not on addAFieldBox
    const fieldBox = document.getElementById('addAFieldBox')
    if (e.altKey === true && e.shiftKey && e.code === 'KeyF') {
      // @ts-ignore
      if (fieldBox.classList.contains(classes.displayNone)) {
        // @ts-ignore
        fieldBox.classList.remove(classes.displayNone)
      } else {
        // @ts-ignore
        fieldBox.classList.add(classes.displayNone)
      }
    }
  }

  enableOrDisableSnappingByShortCut = (e: any) => {
    const { dispatchSnappingEnabled, snappingEnabled } = this.props
    // Prevent event bubbling
    e.stopPropagation()
    // Add displayNone class if it is not on addAFieldBox
    if (e.altKey === true && e.shiftKey && e.code === 'KeyS') {
      dispatchSnappingEnabled(!snappingEnabled)
    }
  }

  componentDidMount() {
    this.checkForIncrementors()
    // Event listener to handle clicking option shift a
    // Doing so will remove/show the 'Add a Field' box
    window.addEventListener('keydown', this.addOrRemoveDisplayNoneOnAddAFieldBox)
    window.addEventListener('keydown', this.enableOrDisableSnappingByShortCut)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (JSON.stringify(this.props.presets) !== JSON.stringify(prevProps.presets)) {
      this.checkForIncrementors()
    }
  }

  componentWillUnmount(): void {
    this.props.resetMaterialPresets()
    clearTimeout(this._collapseTimeOut)
    window.removeEventListener('keydown', this.addOrRemoveDisplayNoneOnAddAFieldBox)
    window.removeEventListener('keydown', this.enableOrDisableSnappingByShortCut)
  }

  renderFields = () => {
    // Map fields to appropriate input components.
    const { classes } = this.props

    const fields = []
    const columns = this.getColumns()
    for (const field of this.state.addedFields) {
      const fieldData = columns.find(col => col.data === field)
      if (!fieldData) {
        continue
      }
      const preset = this.getPresetOrInit(field)
      if (fieldData.type === 'dropdown' && fieldData.source) {
        fields.push(
          <FormControl fullWidth={true} key={field}>
            <Grid container={true} wrap={'nowrap'}>
              <Grid item={true} className={classes.selectGrid}>
                <InputLabel htmlFor={`${field}-helper`} shrink={preset.value !== ''}>
                  {field}
                </InputLabel>
                <Select
                  fullWidth={true}
                  value={preset.value}
                  onChange={(event: any) =>
                    this.handleSetPresetValues(field, { value: event.target.value })
                  }
                  input={<Input name={field} id={`${field}-helper`} />}
                >
                  {fieldData.source.map(col => (
                    <MenuItem value={col} key={col}>
                      {col}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item={true} className={classes.selectGridItemCross}>
                <IconButton
                  color={'secondary'}
                  onClick={() => this.handleRemoveField(field)}
                  className={classes.selectCross}
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </FormControl>
        )
      } else {
        fields.push(
          <TextField
            key={field}
            id="test-field"
            label={field}
            value={preset.value}
            margin="normal"
            fullWidth={true}
            onChange={event => this.handleSetPresetValues(field, { value: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Incrementable">
                    <Checkbox
                      icon={<ExposureZero />}
                      checkedIcon={<ExposurePlus1 />}
                      name={`auto-inc-${field}`}
                      checked={preset.increment}
                      onChange={event =>
                        this.handleSetPresetValues(field, {
                          increment: event.target.checked,
                        })
                      }
                    />
                  </Tooltip>
                  <IconButton color={'secondary'} onClick={() => this.handleRemoveField(field)}>
                    <Icon>close</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )
      }
    }
    return fields
  }

  incrementorControls = () => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={this.props.autoIncrementEnabled}
            onChange={(e, value) => {
              this.props.setAutoIncrementEnabled(value)
            }}
            name="autoIncEnabled"
          />
        }
        label="Auto increment"
      />
      <IconButton
        size="small"
        onClick={() => {
          this.props.incrementPresets(parseInt(this.props.selectedMaterialType, 10), -1)
        }}
      >
        <RemoveCircle />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          this.props.incrementPresets(parseInt(this.props.selectedMaterialType, 10))
        }}
      >
        <AddCircle />
      </IconButton>
    </div>
  )

  getColumns = () => {
    const { descriptions, descriptionTypes, listIds, listIdValueMap } = this.props
    return getHotColumns(descriptions, descriptionTypes, listIds, listIdValueMap)
  }

  render() {
    const { classes, selectedMaterialType, selectedDrawing } = this.props

    if (!selectedMaterialType || !selectedDrawing) {
      return null
    }

    const {
      showAddField,
      addedFields,
      collapsed,
      enableScroll,
      displayFields,
      collapsing,
    } = this.state
    const columns = this.getColumns()

    const rootClasses = [classes.root]
    if (collapsed) {
      rootClasses.push(classes.rootCollapsed)
    }

    if (enableScroll) {
      rootClasses.push(classes.rootEnabledScroll)
    } else {
      rootClasses.push(classes.rootDisabledScroll)
    }

    return (
      <Paper className={rootClasses.join(' ')} id="addAFieldBox">
        {addedFields.length > 0 && (
          <IconButton
            onClick={this.handleCollapse}
            className={`${classes.collapseButton} ${
              collapsed ? classes.collapsedCollapseButton : ''
            }`}
          >
            <Icon fontSize="small">{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
          </IconButton>
        )}
        {displayFields && this.renderFields()}
        <div
          className={`${classes.addField} ${
            addedFields.length > 0 && !collapsed ? classes.addFieldHasFields : ''
          }`}
          onClick={() => this.setState({ showAddField: !showAddField })}
        >
          {(!collapsed || collapsing) && (showAddField ? 'cancel' : '+ add a field')}
        </div>
        {!collapsing && collapsed && <span className={classes.presetLabel}>Presets</span>}
        {showAddField && !collapsed && (
          <Select onChange={this.handleAddField} fullWidth={true} autoFocus={true}>
            {columns
              .filter(col => !addedFields.includes(col.data))
              .map(col => (
                <MenuItem value={col.data} key={col.data}>
                  {col.data}
                </MenuItem>
              ))}
          </Select>
        )}
        {!collapsed && this.state.hasIncrementors && this.incrementorControls()}
      </Paper>
    )
  }
}

const mapStateToProps = (
  state: ReduxStore.State,
  {
    selectedDrawing,
    selectedMaterialType,
  }: { selectedDrawing: string; selectedMaterialType: string }
) => ({
  descriptions: getDescriptions(state),
  selectedDrawing,
  descriptionTypes: getDescriptionTypes(state),
  listIds: getListIds(state),
  listIdValueMap: getListIdValueMap(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  selectedMaterialType,
  autoIncrementEnabled: getAutoIncrementPresetsEnabled(state),
  snappingEnabled: getSnappingEnabled(state),
})

const mapDispatchToProps = {
  dispatchSelectTool: action.selectTool,
  deleteMaterials: action.deleteMaterials,
  setMaterialPresets: action.setMaterialPresets,
  deleteMaterialPresets: action.deleteMaterialPresets,
  resetMaterialPresets: action.resetMaterialPresets,
  incrementPresets: incrementMaterialPresets,
  setAutoIncrementEnabled: setAutoIncrement,
  dispatchSnappingEnabled: action.setSnappingEnabled,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(withStyles(styles)(MaterialPresets))
)
