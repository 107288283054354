import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { applyToPoint, identity, Matrix, scale, transform, translate } from 'transformation-matrix'
import classNames from 'utils/classNames'
import { vectorAdd, vectorSubtract } from 'utils/mathUtils'
import { throttleRaf } from 'utils/throttleRaf'

const scrollZoomSpeed = 500

const styles = createStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
  },
  translateBase: {
    display: 'flex',
    flex: 1,
  },
})

interface Props extends WithStyles<typeof styles> {
  initialScale: number
  initialTranslate: [number, number?]
  className?: string
  style?: React.CSSProperties
}

interface State {
  matrix: Matrix
}

function calculateOffset(element: HTMLElement): number[] {
  const offset = [element.offsetLeft, element.offsetTop]
  return element.offsetParent
    ? vectorAdd(offset, calculateOffset(element.offsetParent as HTMLElement))
    : offset
}

class Zoomable extends React.PureComponent<Props, State> {
  static defaultProps = {
    initialScale: 1,
    initialTranslate: [0, 0],
  }

  childRef = React.createRef<HTMLDivElement>()
  panReferencePoint: number[] | null = null

  updateZoom = throttleRaf((event: React.WheelEvent) => {
    const { matrix } = this.state
    let childOffset: number[] = []
    if (this.childRef.current) {
      childOffset = calculateOffset(this.childRef.current)
    }

    // @ts-ignore
    const childCenter = vectorAdd(childOffset, applyToPoint(matrix, [0, 0]))
    const scaleMatrix = scale(1 + event.deltaY / scrollZoomSpeed)
    const mouseInitial = vectorSubtract([event.clientX, event.clientY], childCenter)
    const mouseScaled = applyToPoint(scaleMatrix, mouseInitial)
    const mouseDiff: [number, number] = vectorSubtract(mouseInitial, mouseScaled)
    const newMatrix = transform(translate(...mouseDiff), matrix, scaleMatrix)

    this.setState({ matrix: newMatrix })
  })

  handleMouseMove = throttleRaf((event: React.MouseEvent) => {
    if (this.panReferencePoint) {
      const currentPoint = [event.clientX, event.clientY]
      const translateAmount: [number, number?] = vectorSubtract(
        currentPoint,
        this.panReferencePoint
      )
      this.panReferencePoint = currentPoint

      const { matrix } = this.state
      const newMatrix = transform(translate(...translateAmount), matrix)
      this.setState({ matrix: newMatrix })
    }
  })

  constructor(props: Props) {
    super(props)
    const { initialScale, initialTranslate } = this.props
    this.state = {
      matrix: transform(identity(), translate(...initialTranslate), scale(initialScale)),
    }
  }

  handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    // event.preventDefault();
    this.updateZoom(event)
  }

  handleMouseUp = () => {
    this.panReferencePoint = null
  }

  handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.button === 2) {
      // ignore right click
      return
    }
    this.panReferencePoint = [event.clientX, event.clientY]
  }

  render() {
    const { style, className, classes, children } = this.props
    const { matrix } = this.state
    const childrenWithMatrix = React.Children.map(children, child => {
      return React.cloneElement(child as React.ReactElement<any>, { matrix })
    })
    return (
      <div
        role="presentation"
        style={style}
        className={classNames(className, classes.root)}
        onWheel={this.handleScroll}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseMove={e => this.panReferencePoint && this.handleMouseMove(e)}
      >
        <div className={classes.translateBase} ref={this.childRef}>
          {childrenWithMatrix}
        </div>
      </div>
    )
  }
}

// @ts-ignore
export default withStyles(styles)(Zoomable)
