import { WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture'
import { API } from 'api'
import 'rc-slider/assets/index.css'
import React from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import {
  getDescriptions,
  getDrawings,
  getMatchedMaterials,
  getMaterialsShapeData,
  getMaterialTypePresets,
  getSelectedMaterials,
  getSelectedMaterialsShape,
  getSelectedMaterialType,
  getSelectedProject,
  getTool,
} from 'store/selectors'
import randomId from 'utils/randomId'
import withSelectedValues from 'utils/withSelectedValues'
import MLDebugViewer from './MLDebugViewer'
import MLOptions from './MLOptions'

interface TemplateMatch {
  x: number
  y: number
  width: number
  height: number
  weight: number
  points: number[]
  shape: 'rectangle' | 'polygon'
  selectedModel: string
}

const styles = (theme: Theme) => ({
  toolWrapper: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '8px',
  },
  tool: {
    width: '100%',
  },
  popperPaper: {
    padding: '10px',
  },
  popperButton: {
    margin: '5px',
  },
  popper: {
    zIndex: 1000,
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
})

interface StateProps {
  currentTool: string
  selectedMaterials: Array<number | string>
  materials: ReduxStore.Materials.Data.IMaterial[]
  drawings: ReduxStore.Drawings.Data.Drawing[]
  selectedProject: number | null
  selectedMaterialType: number | null
  descriptions: string[]
  selectedDrawing: string | null
  materialShapeData: string[]
  matchedMaterials: ReduxStore.Materials.Data.IMaterial[]
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  project: ReduxStore.Projects.Data.Project | undefined
  materialType: ReduxStore.MaterialTypes.Data.MaterialType | undefined
}

interface ComponentState {
  loading: boolean
  error: boolean
  certainty: number
  shapeData: Array<{ points: number[]; shape: string; weight: number } | null>
  open: boolean
  models: string[]
  extractionResults: any
  extractionOverrides: any
}

interface DispatchProps {
  dispatchSelectTool: (tool: string) => void
  dispatchAddMaterials: (materia: ReduxStore.Materials.Data.IMaterial[]) => void
  updateMatchShape: (weight: number) => null
  deleteMatchedMaterials: () => void
  confirmAllMatches: () => void
}

type Props = WithStyles<StyleRules> & StateProps & DispatchProps

class MLExtract extends React.Component<Props & ComponentState, ComponentState> {
  state = {
    loading: false,
    error: false,
    certainty: 0.8,
    shapeData: [],
    open: false,
    models: [],
    extractionResults: null,
    extractionOverrides: {
      remove_noise: false,
      apply_filter: true,
      max_height: 800,
      min_height: 40,
      max_width: 400,
      min_width: 40,
      max_dark_ratio: 0.2,
      remove_noise_iterations: 1,
      blur_size: 5,
      gaus_blur_kernel_size: 5,
      min_offset: 3,
      combined_median_blur_size: 3,
      canny_min_thresh: 100,
      canny_max_thresh: 200,
      canny_aperture_size: 5,
      large_morph_size: 20,
    },
  }

  buttonRef = React.createRef<HTMLElement>()
  arrowRef = React.createRef<HTMLElement>()

  componentDidMount(): void {
    ;(async () => {
      const models: string[] = await API.getMlModels()
      this.setState({ models })
    })()
  }

  updateOverlayOverride = (name: string, value: string | boolean | number) => {
    this.setState({
      extractionOverrides: {
        ...this.state.extractionOverrides,
        [name]: value,
      },
    })
  }

  getOverlays = async (model: any) => {
    if (!model) {
      this.setState({ error: true, open: false })
      return
    }

    const { project, materialType } = this.props
    // @ts-ignore
    window.analytics.track('Overlay Extract Tool Used', {
      tool: 'MLExtract',
      model,
      platform: 'dataloader',
      project: {
        id: project ? project.projectId : '',
        name: project ? project.projectName : '',
      },
      materialType: {
        id: materialType ? materialType.id : '',
        name: materialType ? materialType.name : '',
      },
      drawing: this.props.selectedDrawing,
    })

    this.setState({ loading: true, error: false })
    const {
      drawings,
      selectedDrawing,
      selectedProject,
      selectedMaterialType,
      dispatchAddMaterials,
      descriptions,
    } = this.props

    const currentDrawing = drawings.find(drawing => drawing.displayName === selectedDrawing)

    if (!currentDrawing) {
      this.setState({ loading: false, error: true })
      return
    }

    let extractionResults: any
    try {
      extractionResults = await API.getMLExtract(
        selectedProject,
        currentDrawing.displayName,
        model,
        { ...this.state.extractionOverrides }
      )
    } catch (e) {
      console.error(e)
      this.setState({ loading: false, error: true, open: false })
      return
    }

    if (extractionResults && extractionResults.predictions && extractionResults.predictions[0]) {
      // If mldebug is on, open results in new tabs.
      this.setState({ extractionResults: extractionResults.predictions[0] })
    }

    if (
      !extractionResults ||
      !extractionResults.predictions ||
      !extractionResults.predictions[0].overlays.length
    ) {
      this.setState({ loading: false, error: false })
      return
    }

    const matchShapeDataList = extractionResults.predictions[0].overlays.map((overlay: any) => {
      const materialShape = 'rectangle'
      const newId = randomId()
      const points = [
        overlay[1][0], // top left
        overlay[1][1],
        overlay[0][0], // top right
        overlay[1][1],
        overlay[0][0], // bottom right
        overlay[0][1],
        overlay[1][0], // bottom left
        overlay[0][1],
      ]
      const stringShapeData = '{"points":' + JSON.stringify(points) + '}'
      const value = descriptions.reduce((acc: { [key: string]: string }, name) => {
        if (name === 'Drawing Name' && selectedDrawing) {
          acc[name] = selectedDrawing
        } else {
          // Check if there is a preset set for this.
          const preset = this.props.presets.find(preset => preset.description === name)
          if (preset) {
            acc[name] = preset.value
          } else {
            acc[name] = ''
          }
        }
        return acc
      }, {})
      let matchShape = 'matchRectangle'
      if (materialShape === 'rectangle') {
        matchShape = 'matchRectangle'
      }

      const mockData: ReduxStore.Materials.Data.IMaterial = {
        overlay_id: newId,
        material_id: newId,
        drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
        display_name: selectedDrawing as string,
        shape: matchShape,
        shape_data: stringShapeData,
        company_project_id: selectedProject as number,
        material_type_id: selectedMaterialType as number,
        value,
        isDeleted: false,
        matchSource: 'ML',
      }
      return mockData
    })
    dispatchAddMaterials(matchShapeDataList)
    this.setState({ loading: false, error: false, open: false })
  }

  deleteAllMatches = () => {
    this.props.deleteMatchedMaterials()
    this.setState({ open: false })
  }

  confirmAllMatches = () => {
    this.props.confirmAllMatches()
    // We delete the remainder of the matches after keeping the ones we care about.
    this.deleteAllMatches()
  }

  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    this.setState({ error: false })
  }

  handleShow = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    this.setState({ open: true })
  }

  render() {
    const { classes, selectedMaterials, matchedMaterials } = this.props
    const { loading, error, models, open, extractionResults, extractionOverrides } = this.state

    return (
      <div className={classes.toolWrapper}>
        <Button
          variant="outlined"
          className={classes.tool}
          buttonRef={this.buttonRef}
          onClick={this.handleShow}
        >
          {loading && <CircularProgress size={24} />}
          {!loading && <PictureInPictureIcon />}
        </Button>
        <Popper
          open={!!extractionResults}
          anchorEl={this.buttonRef.current}
          className={classes.popper}
          placement="bottom"
          modifiers={{
            arrow: {
              enabled: true,
              element: this.arrowRef.current,
            },
          }}
        >
          <Paper className={classes.popperPaper}>
            <MLDebugViewer data={extractionResults} />
            <Button
              onClick={() => this.setState({ extractionResults: null })}
              variant="outlined"
              className={classes.popperButton}
            >
              Close
            </Button>
          </Paper>
        </Popper>
        <Popper
          open={open}
          anchorEl={this.buttonRef.current}
          className={classes.popper}
          placement="right"
          modifiers={{
            arrow: {
              enabled: true,
              element: this.arrowRef.current,
            },
          }}
        >
          <span className={classes.arrow} ref={this.arrowRef} />
          <Paper className={classes.popperPaper}>
            <div>
              <Typography id="discrete-slider" gutterBottom={true}>
                Select Model
                <Select
                  labelId="select-model"
                  id="select-model"
                  value={''}
                  onChange={event => {
                    this.setState({ open: false })
                    this.getOverlays(event.target.value)
                  }}
                >
                  {models.map((model: string) => (
                    <MenuItem key={model} value={model}>
                      {model.replace('.tar.gz', '')}
                    </MenuItem>
                  ))}
                </Select>
              </Typography>
            </div>
            <MLOptions
              updateOverride={this.updateOverlayOverride}
              overrides={extractionOverrides}
            />
            <div>
              <Button
                onClick={this.deleteAllMatches}
                variant="outlined"
                className={classes.popperButton}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </Popper>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={<span id="message-id">Failed to find overlays!</span>}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>,
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  state: ReduxStore.State,
  {
    selectedDrawing,
    selectedMaterialType,
    projectId,
  }: { selectedDrawing: string; selectedMaterialType: string; projectId: number }
) => ({
  currentTool: getTool(state),
  selectedMaterials: getSelectedMaterials(state),
  materials: getSelectedMaterialsShape(state),
  drawings: getDrawings(state),
  descriptions: getDescriptions(state),
  materialShapeData: getMaterialsShapeData(state),
  matchedMaterials: getMatchedMaterials(state),
  selectedDrawing,
  presets: getMaterialTypePresets(state, selectedMaterialType),
  project: getSelectedProject(state, projectId),
  materialType: getSelectedMaterialType(state, parseInt(selectedMaterialType, 10)),
})

const mapDispatchToProps = {
  dispatchSelectTool: action.selectTool,
  dispatchAddMaterials: action.addMaterials,
  deleteMaterials: action.deleteMaterials,
  updateMatchShape: action.updataMatchedMaterial,
  deleteMatchedMaterials: action.deleteMatchedMaterials,
  confirmAllMatches: action.confirmMatchedMaterials,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(withStyles(styles)(MLExtract))
)
