import { CardContent } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Card from '@material-ui/core/Card'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import TabContext from '@material-ui/lab/TabContext'
import TabPanel from '@material-ui/lab/TabPanel'
import React from 'react'

// @ts-ignore
const OverlayValidationOptions = ({ updateOverride, overrides }) => (
  <div>
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={overrides.apply_filter}
          onChange={e => updateOverride('apply_filter', e.target.checked)}
          name="apply_filter"
        />
      }
      label={<Typography variant="caption">Apply Filter</Typography>}
    />
    {overrides.apply_filter && (
      <Card>
        <CardContent>
          <Typography color="textSecondary">Overlay Validation Settings</Typography>
          <Typography variant="caption">Max height</Typography>
          <Slider
            value={overrides.max_height}
            step={10}
            valueLabelDisplay="auto"
            max={1500}
            min={0}
            onChange={(e, value) => updateOverride('max_height', value)}
          />
          <Typography variant="caption">Min height</Typography>
          <Slider
            value={overrides.min_height}
            step={5}
            valueLabelDisplay="auto"
            max={100}
            min={0}
            onChange={(e, value) => updateOverride('min_height', value)}
          />
          <Typography variant="caption">Max width</Typography>
          <Slider
            value={overrides.max_width}
            step={10}
            valueLabelDisplay="auto"
            max={1500}
            min={0}
            onChange={(e, value) => updateOverride('max_width', value)}
          />
          <Typography variant="caption">Min width</Typography>
          <Slider
            value={overrides.min_width}
            step={5}
            valueLabelDisplay="auto"
            max={100}
            min={0}
            onChange={(e, value) => updateOverride('min_width', value)}
          />
          <Typography variant="caption">Max black pixel ratio</Typography>
          <Slider
            value={overrides.max_dark_ratio}
            step={0.05}
            valueLabelDisplay="auto"
            max={1.05}
            min={0}
            onChange={(e, value) => updateOverride('max_dark_ratio', value)}
          />
        </CardContent>
      </Card>
    )}
  </div>
)

// @ts-ignore
const DotOptions = ({ updateOverride, overrides }) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={overrides.remove_noise}
          onChange={e => updateOverride('remove_noise', e.target.checked)}
          name="remove_noise"
        />
      }
      label={<Typography variant="caption">Remove Noise</Typography>}
    />
    <Typography variant="caption">Remove Noise iterations</Typography>
    <Slider
      value={overrides.remove_noise_iterations}
      step={1}
      valueLabelDisplay="auto"
      max={10}
      min={0}
      onChange={(e, value) => updateOverride('remove_noise_iterations', value)}
    />

    <Typography variant="caption">Blur Size</Typography>
    <Slider
      value={overrides.blur_size}
      aria-labelledby="discrete-slider-restrict"
      step={null}
      valueLabelDisplay="auto"
      max={9}
      min={3}
      onChange={(e, value) => updateOverride('blur_size', value)}
      marks={[
        { value: 3, label: 3 },
        { value: 5, label: 5 },
        { value: 7, label: 7 },
        { value: 9, label: 9 },
      ]}
    />
    <Typography variant="caption">Minimum Offset</Typography>
    <Slider
      value={overrides.min_offset}
      step={1}
      valueLabelDisplay="auto"
      max={200}
      min={0}
      onChange={(e, value) => updateOverride('min_offset', value)}
    />
    <OverlayValidationOptions updateOverride={updateOverride} overrides={overrides} />
  </FormGroup>
)

// @ts-ignore
const ContourOptions = ({ updateOverride, overrides }) => (
  <FormGroup>
    <Typography variant="caption">Initial blur size</Typography>
    <Slider
      value={overrides.gaus_blur_kernel_size}
      step={null}
      valueLabelDisplay="auto"
      max={15}
      min={0}
      onChange={(e, value) => updateOverride('gaus_blur_kernel_size', value)}
      marks={[
        { value: 0, label: 0 },
        { value: 3, label: 3 },
        { value: 5, label: 5 },
        { value: 7, label: 7 },
        { value: 9, label: 9 },
        { value: 11, label: 11 },
        { value: 13, label: 13 },
        { value: 15, label: 15 },
      ]}
    />
    <Typography variant="caption">Post border extract blur size</Typography>
    <Slider
      value={overrides.combined_median_blur_size}
      step={null}
      valueLabelDisplay="auto"
      max={15}
      min={0}
      onChange={(e, value) => updateOverride('combined_median_blur_size', value)}
      marks={[
        { value: 0, label: 0 },
        { value: 3, label: 3 },
        { value: 5, label: 5 },
        { value: 7, label: 7 },
        { value: 9, label: 9 },
        { value: 11, label: 11 },
        { value: 13, label: 13 },
        { value: 15, label: 15 },
      ]}
    />

    <Typography variant="caption">Edge detection minimum threshold</Typography>
    <Slider
      value={overrides.canny_min_thresh}
      step={5}
      valueLabelDisplay="auto"
      max={255}
      min={0}
      onChange={(e, value) => updateOverride('canny_min_thresh', value)}
    />
    <Typography variant="caption">Edge detection maximum threshold</Typography>
    <Slider
      value={overrides.canny_max_thresh}
      step={5}
      valueLabelDisplay="auto"
      max={255}
      min={0}
      onChange={(e, value) => updateOverride('canny_max_thresh', value)}
    />
    <Typography variant="caption">Edge detection aperture size</Typography>
    <Slider
      value={overrides.canny_aperture_size}
      step={null}
      valueLabelDisplay="auto"
      max={7}
      min={3}
      onChange={(e, value) => updateOverride('canny_aperture_size', value)}
      marks={[{ value: 3, label: 3 }, { value: 5, label: 5 }, { value: 7, label: 7 }]}
    />
    <Typography variant="caption">Morph Size</Typography>
    <Slider
      value={overrides.large_morph_size}
      step={1}
      valueLabelDisplay="auto"
      max={80}
      min={0}
      onChange={(e, value) => updateOverride('large_morph_size', value)}
    />
    <OverlayValidationOptions updateOverride={updateOverride} overrides={overrides} />
  </FormGroup>
)

interface MLOptionsProps {
  children?: React.ReactNode
  updateOverride: (name: string, value: string | number | boolean) => void
  overrides: any
}

export default function MLOptions(props: MLOptionsProps) {
  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(`${newValue}`)
    if (newValue === `2`) {
      props.updateOverride('extract_from_counters', true)
    } else {
      props.updateOverride('extract_from_counters', false)
    }
  }

  // @ts-ignore
  return (
    <Paper elevation={3}>
      <TabContext value={value}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Dots" value="1" />
            <Tab label="Contours" value="2" />
          </Tabs>
        </AppBar>
        <TabPanel value="1">
          <DotOptions {...props} />
        </TabPanel>
        <TabPanel value="2">
          <ContourOptions {...props} />
        </TabPanel>
      </TabContext>
    </Paper>
  )
}
