const Sentry = require('@sentry/browser')
const { BrowserTracing } = require('@sentry/tracing')

// @ts-ignore
const enabled = !!['staging', 'prod'].includes(process.env.REACT_APP_STAGE)
const environment = process.env.REACT_APP_STAGE || 'staging'

// Ratelimit
const eventLimiter: any = {}

Sentry.init({
  dsn: 'https://f70328409c0d4d6f860715d5644e865c@o299470.ingest.sentry.io/6653754',
  integrations: [new BrowserTracing()],
  enabled,
  environment,
  beforeSend: (event: any, hint: any) => {
    const msg = hint && hint.originalException.message

    // Error rate limit
    if (msg && msg in eventLimiter) {
      return null
    }

    eventLimiter[msg] = true

    setTimeout(() => {
      delete eventLimiter[msg]
    }, 1 * 60 * 10000)

    return event
  },
})

export default Sentry
