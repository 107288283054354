import { createStyles, WithStyles } from '@material-ui/core'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { ActionCreators } from 'redux-undo'
import CenteredSpinner from 'shared/CenteredSpinner'
import action from 'store/actions'
import { getMaterialTypes, getMaterialTypesFetchingStatus } from 'store/selectors'
import modifyQueryString from 'utils/modifyQueryString'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import SelectorInput from './selectorMenu/SelectorInput'

const styles = createStyles({
  link: {
    textDecoration: 'none',
    color: 'black',
  },
})

interface StateProps {
  materialTypes: ReduxStore.MaterialTypes.Data.MaterialType[]
  isFetching: boolean
}

interface DispatchProps {
  fetchMaterialTypes: (projectId: number) => void
  fetchMaterials: (cpid: number, mtid: number) => void
  dispatchClearHistory: () => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & DataloaderInfo

const MaterialTypeSelector: React.FC<Props> = props => {
  const [searchText, setSearchText] = useState('')
  const [dynamicSearch, setDynamicSearch] = useState(false)
  const {
    fetchMaterials,
    fetchMaterialTypes,
    selectedProject,
    selectedMaterialType,
    materialTypes,
  } = props

  useEffect(() => {
    if (!!selectedProject) {
      fetchMaterialTypes(selectedProject)
    }
  }, [selectedProject])

  useEffect(() => {
    props.dispatchClearHistory()
    if (!!selectedProject && !!selectedMaterialType) {
      fetchMaterials(selectedProject, selectedMaterialType)
    }
  }, [selectedMaterialType])

  const getSearchDisplayText = () => {
    if (dynamicSearch) {
      return searchText
    }

    if (materialTypes && selectedMaterialType) {
      const selectedMaterialTypeObject = materialTypes.filter(
        mt => mt.id === selectedMaterialType.toString()
      )
      if (selectedMaterialTypeObject[0]) {
        return selectedMaterialTypeObject[0].name
      }
    }

    return ''
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
    setDynamicSearch(true)
  }

  const filterMaterialTypes = (materialTypes: ReduxStore.MaterialTypes.Data.MaterialType[]) => {
    const lowerSearchText = searchText.toLowerCase()
    return materialTypes.filter(({ name }) => name.toLowerCase().includes(lowerSearchText))
  }

  const materialTypesList = () => {
    const { materialTypes, isFetching } = props
    if (isFetching) {
      return <CenteredSpinner />
    }

    if (!materialTypes) {
      return null
    }

    if (materialTypes.length === 0) {
      return 'No material types to display'
    }

    const filteredMaterialTypes = dynamicSearch ? filterMaterialTypes(materialTypes) : materialTypes
    if (filteredMaterialTypes.length === 0) {
      return 'No material types match your search'
    }

    const { selectedMaterialType, classes } = props
    return (
      <List>
        {filteredMaterialTypes.map(({ id, name }) => (
          <Link
            to={{
              search: modifyQueryString({
                whitelist: ['drawing'],
                assign: { materialType: id },
              }),
            }}
            className={classes.link}
            key={id}
          >
            <ListItem
              button={true}
              selected={!!selectedMaterialType && id === selectedMaterialType.toString()}
              onClick={() => {
                setSearchText(name)
                setDynamicSearch(false)
              }}
            >
              <ListItemText primary={name} />
            </ListItem>
          </Link>
        ))}
      </List>
    )
  }
  if (
    materialTypes &&
    materialTypes.length &&
    !selectedMaterialType &&
    !!selectedProject &&
    selectedProject === materialTypes[0].cpid
  ) {
    return (
      <Redirect
        to={{
          search: modifyQueryString({
            whitelist: ['drawing'],
            assign: { materialType: materialTypes[0].id },
          }),
        }}
      />
    )
  }
  return (
    <SelectorInput label="Material type" onChange={handleChange} value={getSearchDisplayText()}>
      {materialTypesList()}
    </SelectorInput>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  materialTypes: getMaterialTypes(state),
  isFetching: getMaterialTypesFetchingStatus(state),
})

const mapDispatchToProps = {
  fetchMaterialTypes: action.fetchMaterialTypes,
  fetchMaterials: action.fetchMaterials,
  dispatchClearHistory: ActionCreators.clearHistory,
}

export default withStyles(styles)(
  withSelectedValues(
    // @ts-ignore
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MaterialTypeSelector)
  )
)
