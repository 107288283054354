import { createStyles, WithStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider/Divider'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import ToolList from './ToolList'

const styles = createStyles({
  root: {
    paddingTop: '10px',
  },
})

type Props = WithStyles<typeof styles> & DataloaderInfo

const LowerSidebar: React.FunctionComponent<Props> = ({
  selectedProject,
  selectedMaterialType,
  selectedDrawing,
  classes,
}) => {
  if (!selectedMaterialType || !selectedProject || !selectedDrawing) {
    return null
  }

  return (
    <div className={classes.root}>
      <ToolList />
      <Divider />
    </div>
  )
}

export default withSelectedValues(withStyles(styles)(LowerSidebar))
