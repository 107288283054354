import { Session } from 'inspector'
import { get } from 'lodash'

export function getProjectsFetchingStatus(state: ReduxStore.State): boolean {
  return get(state, 'projects.isFetching')
}

export function getDrawingsFetchingStatus(state: ReduxStore.State): boolean {
  return get(state, 'drawings.isFetching')
}

export function getMaterialTypesFetchingStatus(state: ReduxStore.State): boolean {
  return get(state, 'materialTypes.isFetching')
}

export function getMaterialsFetchingStatus(state: ReduxStore.State): boolean {
  return get(state, 'materials.present.isFetching')
}

export function getMaterials(state: ReduxStore.State) {
  return get(state, 'materials.present.list')
}

export function getMaterialsOnCurrentDrawing(state: ReduxStore.State, selectedDrawing: string) {
  const materials: ReduxStore.Materials.Data.IMaterial[] = get(state, 'materials.present.list')
  // @ts-ignore
  return Object.values(materials).filter(
    material => material.display_name === selectedDrawing && !material.isDeleted
  )
}

export function getMaterialsWithoutOverlays(state: ReduxStore.State) {
  return get(state, 'materials.present.listWithoutOverlays')
}

export function getDescriptions(state: ReduxStore.State): string[] {
  return get(state, 'materials.present.descriptions')
}

export function getDescriptionTypes(state: ReduxStore.State): string[] {
  return get(state, 'materials.present.descriptionTypes')
}

export function getListIds(state: ReduxStore.State): number[] {
  return get(state, 'materials.present.listIds')
}

export function getListIdValueMap(state: ReduxStore.State): { [key in string]: string[] } {
  return get(state, 'materials.present.listIdValueMap')
}

export function getDescriptionsData(
  state: ReduxStore.State
): ReduxStore.Materials.Data.DescriptionData[] {
  const descriptions = get(state, 'materials.present.descriptions')
  const types = get(state, 'materials.present.descriptionTypes')
  const listIds = get(state, 'materials.present.listIds')
  const listValues = get(state, 'materials.present.listIdValueMap')

  const descData = descriptions.map((description: string, i: number) => {
    const type: string = types[i]
    let choices: [] = []
    if (type === 'list' || type === 'button-list') {
      choices = listValues[listIds[i]]
    }
    return {
      description,
      type,
      choices,
    }
  })
  return descData
}

export function getTool(state: ReduxStore.State): string {
  return get(state, 'tool.activeTool')
}

export function getSnappingEnabled(state: ReduxStore.State): boolean {
  return get(state, 'tool.snappingEnabled')
}

export function getHotTableRef(state: ReduxStore.State): boolean {
  return get(state, 'tool.hotTableRef')
}

export function getToolOptions(
  state: ReduxStore.State,
  toolName: string
): ReduxStore.Tool.OutlinerToolOptions {
  return get(state, `tool.options[${toolName}]`)
}

export function getLoginStatus(state: ReduxStore.State) {
  return get(state, 'auth.loginStatus')
}

export function getSession(state: ReduxStore.State): Session {
  return get(state, 'auth.session')
}

export function getAccess(state: ReduxStore.State): ReduxStore.Auth.Data.Access[] {
  return get(state, 'auth.userDetails.access')
}

export function getProjects(state: ReduxStore.State): ReduxStore.Projects.Data.Project[] {
  return get(state, 'projects.list')
}

export function getSourceProjects(state: ReduxStore.State): ReduxStore.Projects.Data.Project[] {
  // Filter draft projects
  const projects = get(state, 'projects.list')
  const draftProjectIds = projects
    .filter((project: ReduxStore.Projects.Data.Project) => project.draftProjectId !== null)
    .map((project: ReduxStore.Projects.Data.Project) => project.draftProjectId)
  const result = projects.filter(
    (project: ReduxStore.Projects.Data.Project) => !draftProjectIds.includes(project.projectId)
  )
  return result
}

export function getSelectedProject(
  state: ReduxStore.State,
  projectId: number | null,
  cpId: number | null = null
): ReduxStore.Projects.Data.Project | undefined {
  const projects: ReduxStore.Projects.Data.Project[] = getProjects(state)
  if (projectId) {
    return projects.find(proj => proj.projectId === parseInt(projectId.toString(), 10))
  } else if (cpId) {
    return projects.find(proj => proj.companyProjectId === parseInt(cpId.toString(), 10))
  }
  return undefined
}

export function getMaterialTypes(
  state: ReduxStore.State
): ReduxStore.MaterialTypes.Data.MaterialType[] {
  return get(state, 'materialTypes.list')
}

export function getSelectedMaterialType(
  state: ReduxStore.State,
  materialTypeId: number | null
): ReduxStore.MaterialTypes.Data.MaterialType | undefined {
  if (!materialTypeId) {
    return
  }
  const materialTypes = getMaterialTypes(state)
  return materialTypes.find(mt => mt.id === materialTypeId.toString())
}

export function getDrawings(state: ReduxStore.State): ReduxStore.Drawings.Data.Drawing[] {
  return get(state, 'drawings.list')
}

export function getSelectFrom(state: ReduxStore.State): number {
  return get(state, 'materials.present.selectFrom')
}

export function getSelectedMaterials(state: ReduxStore.State): Array<number | string> {
  return get(state, 'materials.present.selection')
}

export function getUpdateFloorplanAndTableCanvas(state: ReduxStore.State): number {
  return get(state, 'drawings.updateFloorplanAndTableCanvas')
}

export function getSelectedMaterialsShape(state: ReduxStore.State) {
  const materials: ReduxStore.Materials.Data.IMaterial[] = get(state, 'materials.present.list')
  return Object.values(materials)
}

export function getMatchedMaterials(state: ReduxStore.State) {
  const materials: { [index: string]: ReduxStore.Materials.Data.IMaterial } = get(
    state,
    'materials.present.list'
  )
  return Object.values(materials).filter(
    mat => mat.shape === 'matchRectangle' || mat.shape === 'matchPolygon'
  )
}

export function isToolBusy(state: ReduxStore.State) {
  // check if the template match tool is busy
  return getMatchedMaterials(state).length > 0
}

export function getMaterialsShapeData(state: ReduxStore.State) {
  const materials: ReduxStore.Materials.Data.IMaterial[] = get(state, 'materials.present.list')
  const existMaterials = Object.values(materials).filter(material => !material.isDeleted)
  const shapeDataList: string[] = []
  Object.values(existMaterials).forEach(material => {
    shapeDataList.push(material.shape_data)
  })
  return shapeDataList
}

export function getMaterialTypePresets(state: ReduxStore.State, materialTypeId: string) {
  const materialPresets: ReduxStore.MaterialPresets.MaterialPreset[] = state.materialPresets.list
  return materialPresets.filter(preset => preset.materialTypeId === materialTypeId)
}

export function getAutoIncrementPresetsEnabled(state: ReduxStore.State): boolean {
  return state.materialPresets.autoIncrement
}

export function getDraft(state: ReduxStore.State): ReduxStore.Draft.State {
  return get(state, 'draft')
}

export function getDraftEnabled(state: ReduxStore.State): boolean {
  return get(state, 'draft.isEnabled')
}

export function getRecordTypeIdMap(state: ReduxStore.State) {
  return get(state, 'draft.draftData.recordTypeIdMap')
}

export function getSourceProjectByDraftId(state: ReduxStore.State, draftProjectId: number) {
  const projectList: ReduxStore.Projects.Data.Project[] = get(state, 'projects.list')
  const sourceProject = projectList.find(
    project => project.draftProjectId === parseInt(draftProjectId.toString(), 10)
  )
  return sourceProject
}

export function getNotifications(
  state: ReduxStore.State
): ReduxStore.Notifications.KeyedNotification[] {
  return state.notifications.notifications
}

export function getSnappingDistance(state: ReduxStore.State): number {
  return get(state, 'tool.snappingDistance')
}

export function getAlignDistance(state: ReduxStore.State): number {
  return get(state, 'tool.alignDistance')
}

export function getImage(state: ReduxStore.State): HTMLImageElement | undefined {
  return get(state, 'tool.image')
}
