import CONST from 'const'
import { includes, max, min, round } from 'lodash'

export const getRowsToBeSelected = (function temp() {
  return (
    hotData: ReduxStore.Materials.Data.RowInHOT[],
    selectedMaterials: Array<number | string>
  ) => {
    // get the array of shapes id
    const rowsTobeSelected = new Set<number>()
    selectedMaterials.forEach(overlay_id => {
      // @ts-ignore
      hotData.forEach((value, index) => {
        const overlay_id_array = value.overlay_id
        if (includes(overlay_id_array, overlay_id)) {
          rowsTobeSelected.add(index)
        }
      })
    })
    return Array.from(rowsTobeSelected)
  }
})()

export function scalePoints(
  scaleX: number,
  scaleY: number,
  direct: string,
  points: number[],
  benchmarkPoint?: Array<number | undefined> | null
) {
  if (!benchmarkPoint) {
    // Select one overlay and scale
    let x = points[0]
    let y = points[1]
    let width = points[4] - points[0]
    let height = points[5] - points[1]
    if (includes(direct, 'left')) {
      x = round(x - width * (scaleX - 1), CONST.PRECISION)
    }
    if (includes(direct, 'top')) {
      y = round(y - height * (scaleY - 1), CONST.PRECISION)
    }
    width = round(width * scaleX, CONST.PRECISION)
    height = round(height * scaleY, CONST.PRECISION)
    return [
      x,
      y,
      round(x + width, 2),
      y,
      round(x + width, 2),
      round(y + height, 2),
      x,
      round(y + height, 2),
    ]
  } else {
    // Select multiple overlays and bulk scale
    let x = includes(direct, 'left') ? points[2] : points[0]
    let y = includes(direct, 'top') ? points[5] : points[1]
    const benchmarkX = benchmarkPoint[0]
    const benchmarkY = benchmarkPoint[1]
    let width = points[2] - points[0]
    let height = points[5] - points[1]
    width = round(width * scaleX, CONST.PRECISION)
    height = round(height * scaleY, CONST.PRECISION)
    if (includes(direct, 'left')) {
      if (benchmarkX && x < benchmarkX) {
        x = round(x - (benchmarkX - x) * (scaleX - 1), CONST.PRECISION)
      }
      if (includes(direct, 'top')) {
        // Drag top-left
        if (benchmarkY && y < benchmarkY) {
          y = round(y - (benchmarkY - y) * (scaleY - 1), CONST.PRECISION)
        }
        return [
          round(x - width, 2),
          round(y - height, 2),
          x,
          round(y - height, 2),
          x,
          y,
          round(x - width, 2),
          y,
        ]
      } else {
        if (includes(direct, 'bottom') && benchmarkY && y > benchmarkY) {
          // Drag bottom-left
          y = round(y + (y - benchmarkY) * (scaleY - 1), CONST.PRECISION)
        }
        return [
          round(x - width, 2),
          y,
          x,
          y,
          x,
          round(y + height, 2),
          round(x - width, 2),
          round(y + height, 2),
        ]
      }
    } else {
      // if (includes(direct, 'right')) {
      if (includes(direct, 'right') && benchmarkX && x > benchmarkX) {
        x = round(x + (x - benchmarkX) * (scaleX - 1), CONST.PRECISION)
      }
      if (includes(direct, 'top')) {
        // Drag top-right
        if (benchmarkY && y < benchmarkY) {
          y = round(y - (benchmarkY - y) * (scaleY - 1), CONST.PRECISION)
        }
        return [
          x,
          round(y - height, 2),
          round(x + width, 2),
          round(y - height, 2),
          round(x + width, 2),
          y,
          x,
          y,
        ]
      } else {
        if (includes(direct, 'bottom') && benchmarkY && y > benchmarkY) {
          // Drag bottom-right
          y = round(y + (y - benchmarkY) * (scaleY - 1), CONST.PRECISION)
        }
        return [
          x,
          y,
          round(x + width, 2),
          y,
          round(x + width, 2),
          round(y + height, 2),
          x,
          round(y + height, 2),
        ]
      }
    }
  }
}

export const pointsToDimensions = (
  points: ReduxStore.Materials.Data.IPoints
): ReduxStore.Materials.Data.IDimensions => {
  const width = round(points[4] - points[0], 2)
  const height = round(points[5] - points[1], 2)
  return {
    x: round(points[0], 2),
    y: round(points[1], 2),
    width,
    height,
  }
}

export const dimensionsToPoints = (
  dimensions: ReduxStore.Materials.Data.IDimensions
): ReduxStore.Materials.Data.IPoints => {
  const { x, y, width, height } = dimensions
  return [
    x,
    y,
    round(x + width, 2),
    y,
    round(x + width, 2),
    round(y + height, 2),
    x,
    round(y + height, 2),
  ]
}

export const minMaxToPoints = (
  dimensions: ReduxStore.Materials.Data.IDimensionsMinMax
): ReduxStore.Materials.Data.IPoints => {
  const { minX, minY, maxX, maxY } = dimensions
  return [minX, minY, maxX, minY, maxX, maxY, minX, maxY]
}

export const getBenchmarkPoint = (
  selection: ReduxStore.Materials.Data.IMaterial[],
  direct: string
): Array<number | undefined> => {
  const overlayPoints: number[][] = []
  selection.forEach(overly => {
    overlayPoints.push(JSON.parse(overly.shape_data).points)
  })
  let benchmarkX: number | undefined
  let benchmarkY: number | undefined
  if (includes(direct, 'left')) {
    const maxX: number[] = []
    overlayPoints.forEach(points => maxX.push(points[2]))
    benchmarkX = max(maxX)
  }
  if (includes(direct, 'right')) {
    const minX: number[] = []
    overlayPoints.forEach(points => minX.push(points[0]))
    benchmarkX = min(minX)
  }
  if (includes(direct, 'top')) {
    const maxY: number[] = []
    overlayPoints.forEach(points => maxY.push(points[5]))
    benchmarkY = max(maxY)
  }
  if (includes(direct, 'bottom')) {
    const minY: number[] = []
    overlayPoints.forEach(points => minY.push(points[1]))
    benchmarkY = min(minY)
  }
  return [benchmarkX, benchmarkY]
}
