import { createStyles, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css'
import HydrateSession from './auth/HydrateSession'
import Login from './auth/Login'
import Dataloader from './dataloader/Dataloader'
import AuthenticatedRoute from './shared/AuthenticatedRoute'
import UnauthenticatedRoute from './shared/UnauthenticatedRoute'
import store from './store'
import './utils/sentry'

const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  },
})

type Props = WithStyles<typeof styles>

const App: React.FunctionComponent<Props> = ({ classes }) => {
  // @ts-ignore
  return (
    <SnackbarProvider>
      <ReduxProvider store={store}>
        <HydrateSession>
          <div className={classes.root}>
            <Router>
              <Switch>
                <UnauthenticatedRoute path="/login" component={Login} />
                <AuthenticatedRoute path="/" component={Dataloader} />
              </Switch>
            </Router>
          </div>
        </HydrateSession>
      </ReduxProvider>
    </SnackbarProvider>
  )
}

export default withStyles(styles)(App)
