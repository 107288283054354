import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { getAccess, getSession } from 'store/selectors'

interface StateProps {
  session: ReduxStore.Auth.Data.Session
  access: [ReduxStore.Auth.Data.Access]
}

interface Props extends StateProps {
  component: React.ComponentType<any>
}

const UnauthenticatedRoute: React.FC<Props> = ({
  component: Component,
  access,
  session,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        session && access ? (
          <Redirect to={{ pathname: `/${access[0].company_project_id}/${access[0].project_id}` }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  access: getAccess(state),
  session: getSession(state),
})

// @ts-ignore
export default connect(mapStateToProps)(UnauthenticatedRoute)
