import { Button, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { Pagination } from '@material-ui/lab'
import React from 'react'

// The extraction results looks something like:
// intermediate_results: [{title: "Threshold", color: "red",…}, {title: "median Blur", color: "red",…},…]
// 0: {title: "Threshold", color: "red",…}
// color: "red"
// image: "iVBORw0KGgoAAAANSUhEUgAACAAAAAgACAAAAACXzIzsAAAwh0"
// title: "Threshold"

export default class MLDebugViewer extends React.Component<any, any> {
  // @ts-ignore
  static getDerivedStateFromProps(props, state) {
    const { data } = props

    if (!data) {
      return
    }

    const stepData = data.intermediate_results.map(
      ({ color, image, title }: { title: string; color: string; image: string }) => {
        return {
          title: `${title} - ${color}`,
          image: MLDebugViewer.processImage(image),
        }
      }
    )

    return {
      totalSteps: data.intermediate_results.length,
      stepData,
    }
  }

  static processImage = (img: string) => {
    const imgBase64 = img
      .replace(/_/g, '/')
      .replace(/-/g, '+')
      .replace(/=+$/, '')
    const buffer = Uint8Array.from(atob(imgBase64), c => c.charCodeAt(0))
    const blob = new Blob([buffer], { type: 'image/png' })
    return URL.createObjectURL(blob)
  }

  state = {
    page: 1,
    viewSteps: false,
    totalSteps: 0,
    stepData: [],
    viewSize: 400,
  }

  render() {
    const { data } = this.props
    const { page, viewSteps, totalSteps, viewSize } = this.state

    const stepData: any = this.state.stepData // Prevent typescript isse

    const url = MLDebugViewer.processImage(data.generated_img)

    return (
      <div>
        <div>
          <Button
            size={'small'}
            onClick={() => this.setState({ viewSize: viewSize === 400 ? 800 : 400 })}
          >
            {viewSize === 400 ? 'big' : 'small'}
          </Button>
        </div>
        {!viewSteps && <img src={url} width={viewSize} height={viewSize} />}
        {!viewSteps && totalSteps > 0 && (
          <div>
            <Button onClick={() => this.setState({ viewSteps: true, page: 1 })} variant="outlined">
              View Steps
            </Button>
          </div>
        )}
        {viewSteps && (
          <>
            <Paper elevation={3}>
              <Container>
                <Pagination
                  count={totalSteps}
                  page={page}
                  onChange={(e, page) => this.setState({ page })}
                  color="primary"
                />
              </Container>
              <Typography align={'center'}>{stepData[page - 1].title}</Typography>
              <div>
                <img src={stepData[page - 1].image} width={viewSize} height={viewSize} />
              </div>
              <Container>
                <Button
                  onClick={() => this.setState({ viewSteps: false, page: 1 })}
                  variant="outlined"
                >
                  Close Steps
                </Button>
              </Container>
            </Paper>
          </>
        )}
      </div>
    )
  }
}
