import React from 'react'

const versionNumber = () => {
  const versionNumberOne = 1
  const versionNumberTwo = 7
  const versionNumberThree = 0
  const versionNumber = versionNumberOne + '.' + versionNumberTwo + '.' + versionNumberThree
  return (
    <div style={{ position: 'absolute', right: '20px', top: '0', color: 'gray' }}>
      {versionNumber}
    </div>
  )
}

export default versionNumber
