import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import logo from 'logo.svg'
import React from 'react'

const styles = createStyles({
  root: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})

type Props = WithStyles<typeof styles>

const SplashScreen: React.FC<Props> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <img src={logo} alt="Matrak logo" />
    </div>
  )
}

export default withStyles(styles)(SplashScreen)
