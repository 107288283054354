import { createStyles, WithStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Route } from 'react-router-dom'
import classNames from 'utils/classNames'
import ToolOptions from '../tools/ToolOptions'
import LowerSidebar from './lowerSidebar/LowerSidebar'
import Buttons from './OtherButtons/Buttons'
import SelectorMenuMount from './upperSidebar/selectorMenu/SelectorMenuMount'
import UpperSidebar from './upperSidebar/UpperSidebar'
import VersionNumber from './versionNumber/versionNumber'

const styles = createStyles({
  root: {
    display: 'flex',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
    width: '250px',
    boxSizing: 'border-box',
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
  width: string
}

const Sidebar: React.FunctionComponent<Props> = ({ className, classes, width }) => {
  return (
    <div className={classes.root} style={{ width }}>
      <Paper className={classNames(className, classes.paper)} elevation={16}>
        <Route
          path="/:selectedProject?/:projectId?"
          render={() => (
            <div>
              <UpperSidebar />
              <LowerSidebar />
              <Buttons />
              <Divider />
              <ToolOptions />
              <VersionNumber />
            </div>
          )}
        />
      </Paper>
      <SelectorMenuMount style={{ left: width }} />
    </div>
  )
}

export default withStyles(styles)(Sidebar)
