import URLSearchParams from '@ungap/url-search-params'
import { features } from './featureFlag'

function getURLSearchParams() {
  return new URLSearchParams(window.location.search)
}

/**
 * Replaces the given parameters in the current query.
 * @param {URLSearchParams} urlSearchParams Parameters object.
 * @param {object} parameters Query parameters to override in the current query.
 * @returns {URLSearchParams} The new query string
 */
function replaceParameters(urlSearchParams: any, parameters: any) {
  Object.keys(parameters).forEach(key => {
    // Add each of the requested search params to the params object
    urlSearchParams.set(key, parameters[key])
  })
  return urlSearchParams
}

/**
 * Filters a query string and only keeps the parameters in the given list.
 * @param {URLSearchParams} urlSearchParams Parameters object.
 * @param {string[]} keys List of parameter keys to keep.
 * @returns {URLSearchParams} The new query string.
 */
function whitelistParameters(urlSearchParams: any, keys: any) {
  Array.from(urlSearchParams.keys())
    .filter(key => !keys.includes(key))
    .forEach(key => urlSearchParams.delete(key))
  return urlSearchParams
}

/**
 * modifies the current query string.
 * @param {object} options dictionary of operations to perform.
 * @param {object} [options.assign] dictionary of new parameters to assign to the query.
 * @param {string[]} [options.whitelist] if set, discards all parameters not in this list. this is
 * done before adding new parameters to the query so there is no need to list them in this list.
 * @returns {string}
 */
export default function modifyQueryString(options: any) {
  let parameters = getURLSearchParams()

  parameters = options.whitelist
    ? whitelistParameters(parameters, [...options.whitelist, ...features])
    : (parameters = whitelistParameters(parameters, features))

  if (options.assign) {
    parameters = replaceParameters(parameters, options.assign)
  }

  // Sort the params if available
  if ('sort' in parameters) {
    parameters.sort()
  }
  return parameters.toString()
}
