import Konva from 'konva'
import React, { useEffect } from 'react'
import { Transformer } from 'react-konva'

interface Props {
  selectedMaterialName: number | string
  selectedMaterials: Array<number | string>
  selectedDrawing: string
  selectedMaterialType: string
  snappingEnabled: boolean
  snappingDistance: number
  dispatchUpdateScale: (newScale: ReduxStore.Materials.Data.INewScale) => void
}

const TransformerComponent: React.FC<Props> = props => {
  const {
    selectedMaterialName,
    selectedMaterials,
    selectedDrawing,
    selectedMaterialType,
    dispatchUpdateScale,
  } = props
  let transformer: Konva.Transformer
  let newX: number
  let newY: number
  let newWidth: number
  let newHeight: number
  let newScaleX: number
  let newScaleY: number
  let originWidth: number
  let originHeight: number

  // Clean bulk select bounding box
  useEffect(() => {
    transformer.nodes([])
    // @ts-ignore
    transformer.getLayer().draw()
  }, [selectedDrawing, selectedMaterialType])

  useEffect(() => {
    checkNode()
  }, [selectedMaterialName, selectedMaterials])

  const checkNode = () => {
    const stage = transformer.getStage()
    const selectedNodes: Konva.Node[] = []
    if (stage) {
      selectedMaterials.forEach(selectedMaterialName => {
        const selectedNode = stage.findOne(`.${selectedMaterialName}`)
        if (
          selectedNode &&
          selectedNode.getAttr('shape') &&
          selectedNode.getAttr('shape') === 'rect'
        ) {
          selectedNodes.push(selectedNode)
        }
      })
      transformer.nodes(selectedNodes)
      // @ts-ignore
      transformer.getLayer().draw()
      // }
    } else {
      transformer.nodes([])
      // @ts-ignore
      transformer.getLayer().draw()
    }
  }

  const getLineGuideStops = (skipShape: Konva.Shape) => {
    // we can snap to stage borders and the center of the stage
    const vertical: any[] = []
    const horizontal: any[] = []

    // and we snap over edges and center of each object on the canvas
    skipShape
      .findAncestor('Layer')
      // @ts-ignore
      .getChildren(node => {
        return node.getClassName() === 'Rect'
      })
      // @ts-ignore
      .each(guideItem => {
        if (guideItem === skipShape) {
          return
        }
        const box = guideItem.getAttrs()
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width])
        horizontal.push([box.y, box.y + box.height])
      })

    skipShape
      .findAncestor('Layer')
      // @ts-ignore
      .getChildren(node => node.getClassName() === 'Group')
      .each((group: Konva.Node) => {
        // @ts-ignore
        const lines: Konva.Line[] = group.getChildren(node => node.getClassName() === 'Line')
        lines.forEach((line: Konva.Line, i: number) => {
          const points = line.points()
          for (let i = 0; i < points.length; i += 2) {
            const x = points[i]
            const y = points[i + 1]
            vertical.push(x)
            horizontal.push(y)
          }
        })
      })

    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    }
  }

  // @ts-ignore
  const getObjectSnappingEdges = (node: Konva.Shape) => {
    const box = node.getAttrs()
    originWidth = node.width()
    originHeight = node.height()
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    // @ts-ignore
    const direct = transformer._movingAnchorName
    if (box.x && box.y) {
      newX = box.x
      newY = box.y
    }
    newWidth = originWidth
    newHeight = originHeight

    if (originWidth && originHeight) {
      if (direct.includes('left')) {
        newWidth = originWidth * scaleX
      }
      if (direct.includes('right')) {
        newWidth = scaleX * originWidth
      }
      if (direct.includes('top')) {
        newHeight = scaleY * originHeight
      }
      if (direct.includes('bottom')) {
        newHeight = scaleY * originHeight
      }
      return {
        vertical: [
          {
            guide: Math.round(newX),
            offset: Math.round(node.x() - newX),
            snap: 'start',
          },
          {
            guide: Math.round(newX + newWidth),
            // @ts-ignore
            offset: Math.round(node.x() - newX - newWidth),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(newY),
            offset: Math.round(node.y() - newY),
            snap: 'start',
          },
          {
            guide: Math.round(newY + newHeight),
            // @ts-ignore
            offset: Math.round(node.y() - newY - newHeight),
            snap: 'end',
          },
        ],
      }
    }
    return null
  }

  const getGuides = (lineGuideStops: any, itemBounds: any) => {
    const resultV: Array<null | any> = []
    const resultH: Array<null | any> = []

    // @ts-ignore
    lineGuideStops.vertical.forEach(lineGuide => {
      // @ts-ignore
      itemBounds.vertical.forEach(itemBound => {
        const diff = Math.abs(lineGuide - itemBound.guide)
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < 5) {
          resultV.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          })
        }
      })
    })

    // @ts-ignore
    lineGuideStops.horizontal.forEach(lineGuide => {
      // @ts-ignore
      itemBounds.horizontal.forEach(itemBound => {
        const diff = Math.abs(lineGuide - itemBound.guide)
        if (diff < 5) {
          resultH.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          })
        }
      })
    })

    const guides: Array<null | any> = []

    // find closest snap, for resize, display both snapping line
    const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
    const minV2 = resultV.sort((a, b) => a.diff - b.diff)[1]
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0]
    const minH2 = resultH.sort((a, b) => a.diff - b.diff)[1]
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      })
    }
    if (minV2) {
      guides.push({
        lineGuide: minV2.lineGuide,
        offset: minV2.offset,
        orientation: 'V',
        snap: minV2.snap,
      })
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      })
    }
    if (minH2) {
      guides.push({
        lineGuide: minH2.lineGuide,
        offset: minH2.offset,
        orientation: 'H',
        snap: minH2.snap,
      })
    }
    return guides
  }

  const drawGuides = (guides: Array<null | any>, node: Konva.Shape | Konva.Node) => {
    const layer = node.findAncestor('Layer')

    guides.forEach(lg => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
          stroke: 'rgb(255, 74, 234)',
          strokeWidth: 2,
          name: 'guid-line',
          dash: [4, 6],
          listening: false,
        })
        // @ts-ignore
        layer.add(line)
        // @ts-ignore
        layer.batchDraw()
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [lg.lineGuide, -6000, lg.lineGuide, 6000],
          stroke: 'rgb(255, 74, 234)',
          strokeWidth: 2,
          name: 'guid-line',
          dash: [4, 6],
          listening: false,
        })
        // @ts-ignore
        layer.add(line)
        // @ts-ignore
        layer.batchDraw()
      }
    })
  }

  let guides
  const onTransform = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.currentTarget
      .findAncestor('Layer')
      // @ts-ignore
      .find('.guid-line')
      // @ts-ignore
      .destroy()
    // @ts-ignore
    const lineGuideStops = getLineGuideStops(transformer.getNode())
    // @ts-ignore
    const itemBounds = getObjectSnappingEdges(transformer.getNode())
    guides = getGuides(lineGuideStops, itemBounds)
    // @ts-ignore

    let presetX: number | undefined
    let presetX2: number | undefined
    let presetY: number | undefined
    let presetY2: number | undefined
    // @ts-ignore
    if (props.snappingEnabled) {
      drawGuides(guides, transformer.getNode())
      guides.forEach(lg => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                presetX = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                presetY = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }

          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                presetX2 = lg.lineGuide
                break
              }
              case 'H': {
                presetY2 = lg.lineGuide
                break
              }
            }
            break
          }
        }
      })
      if (presetX) {
        newX = presetX
      }
      if (presetX2) {
        newWidth = presetX2 - newX
      }
      if (presetY) {
        newY = presetY
      }
      if (presetY2) {
        newHeight = presetY2 - newY
      }
    }
  }
  const onTransformEnd = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    // @ts-ignore
    transformer
      .getLayer()
      .find('.guid-line')
      // @ts-ignore
      .destroy()
    // @ts-ignore
    transformer.getLayer().batchDraw()
    newScaleX = newWidth / originWidth
    newScaleY = newHeight / originHeight
    dispatchUpdateScale({
      // @ts-ignore
      direct: transformer._movingAnchorName,
      scaleX: newScaleX,
      scaleY: newScaleY,
    })
  }

  return (
    <Transformer
      ref={node => {
        if (node) {
          transformer = node
        }
      }}
      anchorStroke={selectedMaterials[0] === selectedMaterialName ? 'red' : '#1976D2'}
      borderStroke={selectedMaterials[0] === selectedMaterialName ? 'red' : '#1976D2'}
      anchorSize={8}
      rotateEnabled={false}
      onMouseDown={({ evt }) => evt.stopPropagation()}
      onTransform={onTransform}
      onTransformEnd={onTransformEnd}
    />
  )
}

export default TransformerComponent
