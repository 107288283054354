import { isEmpty } from 'lodash'
import { ThunkAction } from 'redux-thunk'
import * as selectors from 'store/selectors'
import { API } from '../../api'
import { addError } from '../errors/actions'
import { fetchProjects } from '../projects/actions'

type Action = ReduxStore.Draft.Action | ReduxStore.Error.Action

const mapDraftResultToProject = (result: any, isDraft: boolean, sourceProjectId: number) => ({
  isDraft,
  sourceProjectId,
  id: result.company_project_id,
  companyProjectId: result.company_project_id,
  companyName: result.company_name,
  projectId: result.project_id,
  projectName: result.project_name,
  draftProjectId: result.fk_draft_project_id,
})

export const getDraftRecordTypeMap = async (
  sourceProject: ReduxStore.Projects.Data.Project,
  draftProject: ReduxStore.Projects.Data.Project
) => {
  const sourceRecordTypes = await API.getMaterialTypes(sourceProject.companyProjectId)
  const draftRecordTypes = await API.getMaterialTypes(draftProject.companyProjectId)
  const recordTypeIdMap = draftRecordTypes.map(draft => {
    const source = sourceRecordTypes.find(source => source.name === draft.name)
    if (!source) {
      throw Error('Generate record type map error!')
    }
    return {
      sourceId: source.id,
      draftId: draft.id,
    }
  })
  return recordTypeIdMap
}

export const fetchDraftBySourceProjectId = async (projectId: number) => {
  const result = await API.getDraftByProjectId(projectId)
  const draftProject: ReduxStore.Projects.Data.Project | undefined = isEmpty(result)
    ? undefined
    : mapDraftResultToProject(result, true, projectId)

  return draftProject
}

export const createDraftByProjectId = async (projectId: number) => {
  const result = await API.createDraftByProjectId({ sourceProjectId: projectId })
  const draftProject: ReduxStore.Projects.Data.Project = mapDraftResultToProject(
    result,
    true,
    projectId
  )
  return draftProject
}

export const createDraft = (
  projectId: number
): ThunkAction<void, ReduxStore.State, null, Action> => async (dispatch, getState) => {
  try {
    dispatch({ type: 'CREATE_DRAFT' })
    const sourceProject = selectors.getSelectedProject(getState(), projectId)
    if (!sourceProject) {
      throw Error('Find project failed!')
    }
    const draftProject = await createDraftByProjectId(projectId)
    const recordTypeIdMap = await getDraftRecordTypeMap(sourceProject, draftProject)
    // refresh project list

    dispatch({
      type: 'CREATE_DRAFT_SUCCESS',
      payload: { draftData: { sourceProject, draftProject, recordTypeIdMap } },
    })
  } catch (e) {
    dispatch({
      type: 'CREATE_DRAFT_ERROR',
    })
    dispatch(addError({ error: e.message, actionType: 'FETCH_DRAFT_ERROR' }))
  }
}

export const fetchDraft = (
  cpId: number
): ThunkAction<void, ReduxStore.State, null, Action> => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FETCH_DRAFT' })
    const sourceProject = selectors.getSelectedProject(getState(), null, cpId)
    if (!sourceProject) {
      throw Error('Project not exist!')
    }
    const draftProject = await fetchDraftBySourceProjectId(sourceProject.projectId)
    const recordTypeIdMap =
      draftProject && (await getDraftRecordTypeMap(sourceProject, draftProject))
    dispatch({
      type: 'FETCH_DRAFT_SUCCESS',
      payload: { draftData: { sourceProject, draftProject, recordTypeIdMap } },
    })
  } catch (e) {
    dispatch({
      type: 'FETCH_DRAFT_ERROR',
    })
    dispatch(addError({ error: e.message, actionType: 'FETCH_DRAFT_ERROR' }))
  }
}

export const initDraftData = (
  projectId: number,
  cpid?: number
): ThunkAction<void, ReduxStore.State, null, Action> => async (dispatch, getState) => {
  try {
    const state = getState()
    dispatch({ type: 'INIT_DRAFT' })

    // Find project
    let project
    if (cpid) {
      project = selectors.getSelectedProject(state, null, cpid)
    } else {
      project = selectors.getSelectedProject(state, projectId)
    }

    if (!project) {
      throw Error('Find project failed!')
    }

    // Find source project
    const sourceProject = selectors.getSourceProjectByDraftId(state, projectId)
    // If it is live project
    if (!sourceProject) {
      dispatch({
        type: 'INIT_DRAFT_SUCCESS',
        payload: { draftData: { sourceProject: project }, isEnabled: false },
      })
      return
    }

    // If it is draft, generate id map
    const recordTypeIdMap = await getDraftRecordTypeMap(sourceProject, project)

    dispatch({
      type: 'INIT_DRAFT_SUCCESS',
      payload: {
        draftData: { sourceProject, draftProject: project, recordTypeIdMap },
        isEnabled: true,
      },
    })
  } catch (e) {
    dispatch({
      type: 'INIT_DRAFT_ERROR',
      payload: { error: e.message },
    })

    dispatch(addError({ error: e.message, actionType: 'INIT_DRAFT_ERROR' }))
  }
}

export const toggleAndInitDraftMode = (
  isEnabled: boolean,
  projectId: number
): ThunkAction<void, ReduxStore.State, null, Action> => async (dispatch, getState) => {
  try {
    if (isEnabled) {
      dispatch({
        type: 'TOGGLE_DRAFT_MODE',
        payload: {
          isEnabled,
        },
      })
      // Project Id is source
      const sourceProject = selectors.getSelectedProject(getState(), projectId)

      if (!sourceProject || sourceProject.isDraft) {
        throw Error('Source project not exist!')
      }

      let draftProject = await fetchDraftBySourceProjectId(projectId)
      if (!draftProject) {
        dispatch({ type: 'CREATE_DRAFT' })
        draftProject = await createDraftByProjectId(projectId)
      }
      const recordTypeIdMap = await getDraftRecordTypeMap(sourceProject, draftProject)
      dispatch({
        type: 'CREATE_DRAFT_SUCCESS',
        payload: { draftData: { sourceProject, draftProject, recordTypeIdMap } },
      })
      fetchProjects()(dispatch, getState, null)
    } else {
      // Project Id is draft
      dispatch({
        type: 'TOGGLE_DRAFT_MODE',
        payload: {
          isEnabled,
        },
      })
    }
  } catch (e) {
    dispatch({
      type: 'TOGGLE_DRAFT_MODE',
      payload: {
        isEnabled: false,
      },
    })
    dispatch(addError({ error: e.message, actionType: 'TOGGLE_DRAFT_MODE' }))
  }
}

export const publishDraft = (
  sourceProjectId: number,
  draftProjectId: number
): ThunkAction<void, ReduxStore.State, null, Action> => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PUBLISH_DRAFT' })
    const draftData = getState().draft.draftData
    if (!draftData) {
      throw Error('Draft is not exist!')
    }
    const { sourceProject } = draftData
    await API.publishDraft({ sourceProjectId, draftProjectId })
    await API.cleanUpDraftByDraftProjectId({ draftProjectId })
    dispatch({
      type: 'PUBLISH_DRAFT_SUCCESS',
      payload: { draftData: { sourceProject }, isEnabled: false },
    })
  } catch (e) {
    dispatch({ type: 'PUBLISH_DRAFT_ERROR' })
    dispatch(addError({ error: e.message, actionType: 'PUBLISH_DRAFT_ERROR' }))
  }
}

export const cleanUpDraft = (
  draftProjectId: number
): ThunkAction<void, ReduxStore.State, null, Action> => async (dispatch, getState) => {
  dispatch({ type: 'CLEAN_UP_DRAFT' })
  try {
    const state = getState()

    if (!state.draft.draftData) {
      throw Error('Draft is not exist when clean up draft!')
    }
    await API.cleanUpDraftByDraftProjectId({ draftProjectId })
    const { sourceProject } = state.draft.draftData
    dispatch({
      type: 'CLEAN_UP_DRAFT_SUCCESS',
      payload: { draftData: { sourceProject }, isEnabled: false },
    })
  } catch (e) {
    dispatch({ type: 'CLEAN_UP_DRAFT_ERROR' })
    dispatch(addError({ error: e.message, actionType: 'CLEAN_UP_DRAFT_ERROR' }))
  }
}

export const setStatus = (
  status: ReduxStore.Draft.DraftStatus
): ThunkAction<void, ReduxStore.State, null, ReduxStore.Draft.Action> => async (
  dispatch,
  getState
) => {
  dispatch({
    type: 'SET_STATUS',
    payload: {
      status,
    },
  })
}
