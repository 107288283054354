import Konva from 'konva'
// @ts-ignore
import MagicWand from 'magic-wand-tool/dist/magic-wand'
import React, { useEffect, useRef, useState } from 'react'
import { Group, Line, Rect } from 'react-konva'
import { connect } from 'react-redux'
import { incrementMaterialPresets } from 'store/materialPresets/actions'
import {
  addMaterial,
  deleteSelection,
  resetSelection,
  updateShapeData,
} from 'store/materials/actions'
import { enqueueSnackbar } from 'store/notifier/actions'
import {
  getAutoIncrementPresetsEnabled,
  getDescriptions,
  getDescriptionsData,
  getDrawings,
  getMaterialTypePresets,
  getSelectedMaterials,
  getToolOptions,
} from 'store/selectors'
import randomId from 'utils/randomId'
import { minMaxToPoints } from 'utils/shape'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

interface OwnProps {
  width: number
  height: number
  scale: { x: number; y: number }
  image: HTMLImageElement
  imageRef: Konva.Image
}

interface PointsPos {
  x: number
  y: number
}

interface StateProps {
  toolOptions: ReduxStore.Tool.OutlinerToolOptions
  drawings: ReduxStore.Drawings.Data.Drawing[]
  descriptions: string[]
  descriptionsData: ReduxStore.Materials.Data.DescriptionData[]
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  selectedMaterials: Array<string | number>
  autoIncrementEnabled: boolean
}

interface DispatchProps {
  addMaterials: (m: ReduxStore.Materials.Data.IMaterial) => void
  incrementPresets: (materialTypeId: number) => void
  updateShapeData: (shapeData: ReduxStore.Materials.Data.IOverlay) => void
  resetMaterialSelections: () => void
  removeOverlay: (overlayId: number | string) => void
  enqueueSnackbar: (notification: ReduxStore.Notifications.Notification) => void
}

interface ImageInfo {
  width: number
  height: number
  data: ImageData
}

type Props = OwnProps & StateProps & DispatchProps & DataloaderInfo

export const ToolName = 'outliner'

// Based on Magic Wand tool implementation https://github.com/Tamersoul/magic-wand-js
const OutlinerTool: React.FunctionComponent<Props> = props => {
  const [imageInfo, setImageInfo] = useState<ImageInfo | undefined>(undefined)
  const [flatContourPoints, setFlatContourPoints] = useState<number[]>([])
  const [clickPoint, setClickPoint] = useState<PointsPos | undefined>(undefined)
  const [currentMaterialId, _setCurrentMaterialId] = useState<string | undefined>(undefined)

  // need this to make currentMaterialId state available in the keyPressHandler
  const currentMaterialIdRef = useRef(currentMaterialId)
  const setCurrentMaterialId = (materialId: string | undefined) => {
    currentMaterialIdRef.current = materialId
    _setCurrentMaterialId(materialId)
  }

  const {
    image,
    width,
    height,
    toolOptions,
    addMaterials,
    incrementPresets,
    updateShapeData,
    resetMaterialSelections,
    removeOverlay,
    selectedDrawing,
    selectedProject,
    selectedMaterialType,
    drawings,
    descriptions,
    selectedMaterials,
    autoIncrementEnabled,
    enqueueSnackbar,
  } = props
  useEffect(() => {
    const tempCtx = document.createElement('canvas').getContext('2d')
    if (tempCtx) {
      tempCtx.canvas.width = image.width
      tempCtx.canvas.height = image.height
      tempCtx.drawImage(image, 0, 0)
      setImageInfo({
        width: image.width,
        height: image.height,
        data: tempCtx.getImageData(0, 0, image.width, image.height),
      })
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', keyPressHandler, false)
    return () => {
      document.removeEventListener('keydown', keyPressHandler, false)
    }
  }, [])

  useEffect(() => {
    generateContourPoints()
  }, [clickPoint])

  useEffect(() => {
    generateContourPoints()
  }, [toolOptions])

  useEffect(() => {
    if (flatContourPoints.length) {
      if (currentMaterialId) {
        updateShape()
      } else {
        createNewMaterial()
      }
    }
  }, [flatContourPoints])

  useEffect(() => {
    if (currentMaterialId && autoIncrementEnabled) {
      incrementPresets(selectedMaterialType as number)
    }
  }, [currentMaterialId])

  useEffect(() => {
    clearContourHighlight()
  }, [selectedMaterials])

  const keyPressHandler = (evt: any) => {
    switch (evt.keyCode) {
      case 8: // Backspace
        if (evt.ctrlKey) {
          deleteCurrentContour()
        }
        break
    }
  }

  const clearContourHighlight = () => {
    setFlatContourPoints([])
    setCurrentMaterialId(undefined)
    setClickPoint(undefined)
  }

  const deleteCurrentContour = () => {
    if (currentMaterialIdRef.current) {
      removeOverlay(currentMaterialIdRef.current)
    }
  }

  function generateContourPoints() {
    if (!imageInfo || !clickPoint) {
      return
    }

    const mask = generateAreaMask()
    let contours = MagicWand.traceContours(mask)
    if (contours && contours.length) {
      contours = MagicWand.simplifyContours(
        [contours[0]],
        toolOptions.contourTolerance,
        toolOptions.minVertices
      )
    } else {
      enqueueSnackbar({
        message:
          'Cannot create outline here with current settings. Try increasing Colour Threshold.',
        options: {
          key: `${clickPoint.x}${clickPoint.y}`,
          preventDuplicate: true,
          autoHideDuration: 5000,
          resumeHideDuration: 100,
        },
      })
      return
    }

    if (contours && contours.length) {
      if (toolOptions.useBoundingBox) {
        const bBox = findMinMaxDimensions(contours[0].points)
        setFlatContourPoints(minMaxToPoints(bBox))
      } else {
        setFlatContourPoints(
          contours[0].points.map((point: { x: number; y: number }) => [point.x, point.y]).flat()
        )
      }
    }
  }

  const findMinMaxDimensions = (points: any) => {
    const MAX_COORD = 65535

    return points.reduce(
      (acc: ReduxStore.Materials.Data.IDimensionsMinMax, point: { x: number; y: number }) => {
        acc.minX = Math.min(point.x, acc.minX)
        acc.minY = Math.min(point.y, acc.minY)
        acc.maxX = Math.max(point.x, acc.maxX)
        acc.maxY = Math.max(point.y, acc.maxY)
        return acc
      },
      { minX: MAX_COORD, minY: MAX_COORD, maxX: -1, maxY: -1 }
    )
  }

  const generateAreaMask = () => {
    if (!imageInfo || !clickPoint) {
      return
    }

    const img = {
      data: imageInfo.data.data,
      width: imageInfo.width,
      height: imageInfo.height,
      bytes: 4,
    }

    const msk = MagicWand.floodFill(
      img,
      clickPoint.x,
      clickPoint.y,
      toolOptions.colourThreshold,
      null,
      true
    )
    return MagicWand.gaussBlurOnlyBorder(msk, toolOptions.blurRadius)
  }

  const handleClick = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    clearContourHighlight()
    resetMaterialSelections()
  }

  const handleDoubleClick = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.target.preventDefault()
    if (evt.evt.button === 2) {
      // ignore right click
      return
    }
    const pos = { ...eventToCoordinates(evt) }
    setCurrentMaterialId(undefined)
    setClickPoint({ x: Math.round(pos.x), y: Math.round(pos.y) })
    // setClickPoint({ x: 3314, y: 2467 })
  }

  const eventToCoordinates = (
    evt: Konva.KonvaEventObject<MouseEvent>
  ): { x: number; y: number } => {
    const transform = evt.target.getAbsoluteTransform().copy()
    transform.invert()
    // @ts-ignore
    return transform.point(evt.target.findAncestor('Stage').getPointerPosition())
  }

  const createNewMaterial = () => {
    const overlay_id = randomId()
    setCurrentMaterialId(overlay_id)
    addMaterials({
      overlay_id,
      material_id: overlay_id,
      drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
      display_name: selectedDrawing as string,
      shape: toolOptions.useBoundingBox ? 'rectangle' : 'polygon',
      shape_data: JSON.stringify({ points: flatContourPoints }),
      company_project_id: selectedProject as number,
      material_type_id: selectedMaterialType as number,
      value: descriptions.reduce((acc: { [key: string]: string }, name: string) => {
        if (selectedDrawing && name === 'Drawing Name') {
          acc[name] = selectedDrawing
        } else {
          // Check if there is a preset set for this.
          const preset = props.presets.find(preset => preset.description === name)
          if (preset) {
            acc[name] = preset.value
          } else {
            const descriptionData = props.descriptionsData.find(
              descData => descData.description === name
            )
            if (
              descriptionData &&
              (descriptionData.type === 'list' || descriptionData.type === 'button-list') &&
              descriptionData.choices.length > 0
            ) {
              acc[name] = descriptionData.choices[0]
            } else {
              acc[name] = ''
            }
          }
        }
        return acc
      }, {}),
    })
  }

  const updateShape = () => {
    if (currentMaterialId) {
      const overlay_id = currentMaterialId
      updateShapeData({
        overlay_id,
        material_id: overlay_id,
        drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
        shape: toolOptions.useBoundingBox ? 'rectangle' : 'polygon',
        shape_data: JSON.stringify({ points: flatContourPoints }),
      })
    }
  }

  const renderOutline = () => {
    // @ts-ignore
    return (
      <Line
        points={flatContourPoints}
        stroke="red"
        fill="rgba(255, 137, 0, 0.5)"
        dash={[4, 2]}
        closed={true}
        strokeWidth={0}
      />
    )
  }
  return (
    <Group onDblClick={handleDoubleClick} onClick={handleClick}>
      <Rect x={0} y={0} width={width} height={height} fill="rgba(0, 0, 0, 0)" />
      {renderOutline()}
    </Group>
  )
}

const mapStateToProps = (
  state: ReduxStore.State,
  { selectedMaterialType }: { selectedMaterialType: string }
) => ({
  toolOptions: getToolOptions(state, ToolName),
  drawings: getDrawings(state),
  descriptions: getDescriptions(state),
  descriptionsData: getDescriptionsData(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  selectedMaterials: getSelectedMaterials(state),
  autoIncrementEnabled: getAutoIncrementPresetsEnabled(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  addMaterials: (m: ReduxStore.Materials.Data.IMaterial) => dispatch(addMaterial(m)),
  incrementPresets: (materialTypeId: number) => dispatch(incrementMaterialPresets(materialTypeId)),
  updateShapeData: (shapeData: ReduxStore.Materials.Data.IOverlay) =>
    dispatch(updateShapeData(shapeData)),
  resetMaterialSelections: () => dispatch(resetSelection()),
  removeOverlay: (overlayId: number | string) => dispatch(deleteSelection([overlayId])),
  enqueueSnackbar: (notification: ReduxStore.Notifications.Notification) =>
    dispatch(enqueueSnackbar(notification)),
})

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OutlinerTool)
)
