// Prevent break the whole function
export const jsonParse = (value: string) => {
  try {
    const result = JSON.parse(value)
    return result
  } catch (e) {
    console.error(e.message)
    return undefined
  }
}
