import { createStyles, WithStyles } from '@material-ui/core'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import action from 'store/actions'
import {
  getDraft,
  getProjects,
  getProjectsFetchingStatus,
  getSourceProjects,
} from 'store/selectors'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import CenteredSpinner from '../../../shared/CenteredSpinner'
import SelectorInput from './selectorMenu/SelectorInput'

const styles = createStyles({
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  draftStatus: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    fontSize: '11px',
  },
})

interface StateProps {
  projects: ReduxStore.Projects.Data.Project[]
  projectsStatus: boolean
  draft: ReduxStore.Draft.State
}

interface DispatchProps {
  fetchProjects: () => void
  fetchDraft: (cpId: number) => void
  toggleAndInitDraftMode: (isEnabled: boolean, projectId: number) => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & DataloaderInfo

const ProjectSelector: React.FC<Props> = props => {
  const [searchText, setSearchText] = useState('')
  const [dynamicSearch, setDynamicSearch] = useState(false)

  useEffect(() => {
    const { fetchProjects } = props
    fetchProjects()
  }, [])

  const getSearchDisplayText = () => {
    const {
      selectedProject,
      projects,
      draft: { isEnabled, draftData },
    } = props
    if (dynamicSearch) {
      return searchText
    }

    const displayProject =
      projects && selectedProject && projects.find(pro => pro.id === +selectedProject)

    const project =
      displayProject &&
      (displayProject.isDraft
        ? projects.find(pro => pro.projectId === displayProject.sourceProjectId)
        : displayProject)
    return project ? project.projectName : ''
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
    setDynamicSearch(true)
  }

  const filterProjects = (projects: ReduxStore.Projects.Data.Project[]) => {
    const lowerSearchText = searchText.toLowerCase()
    return projects.filter(
      ({ projectName, companyName }) =>
        (projectName && projectName.toLowerCase().includes(lowerSearchText)) ||
        (companyName && companyName.toLowerCase().includes(lowerSearchText))
    )
  }

  const projectsList = () => {
    const { projectsStatus, projects, fetchDraft, toggleAndInitDraftMode } = props

    if (projectsStatus === true) {
      return <CenteredSpinner />
    }

    if (!projects) {
      return null
    }

    if (projects.length === 0) {
      return 'No projects to display'
    }

    const sourceProjects = projects.filter(
      (project: ReduxStore.Projects.Data.Project) => !project.isDraft
    )

    const filteredProjects = dynamicSearch ? filterProjects(sourceProjects) : sourceProjects
    if (filteredProjects.length === 0) {
      return 'No projects match your search'
    }

    const { selectedProject, classes } = props
    // TODO remove slice 30
    return (
      <List>
        {filteredProjects.map(({ id, projectName, companyName, projectId, companyProjectId }) => (
          <Link
            to={`/${id}/${projectId}`}
            className={classes.link}
            key={`${id}-${projectName}-${companyName}`}
            onClick={() => {
              // @ts-ignore
              window.analytics.track('Project Selected', {
                project: {
                  id: projectId,
                  name: projectName,
                },
                platform: 'dataloader',
              })
            }}
          >
            <ListItem
              button={true}
              selected={!!selectedProject && id === selectedProject}
              onClick={() => {
                setSearchText(projectName)
                setDynamicSearch(false)
                if (projectId && companyProjectId) {
                  fetchDraft(companyProjectId)
                  toggleAndInitDraftMode(false, projectId)
                }
              }}
            >
              <ListItemText primary={projectName} secondary={companyName} />
            </ListItem>
          </Link>
        ))}
      </List>
    )
  }

  return (
    <SelectorInput label="Project" onChange={handleChange} value={getSearchDisplayText()}>
      {projectsList()}
    </SelectorInput>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  projects: getProjects(state),
  projectsStatus: getProjectsFetchingStatus(state),
  draft: getDraft(state),
})

const mapDispatchToProps = {
  fetchProjects: action.fetchProjects,
  fetchDraft: action.fetchDraft,
  toggleAndInitDraftMode: action.toggleAndInitDraftMode,
}

export default withStyles(styles)(
  withSelectedValues(
    // @ts-ignore
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ProjectSelector)
  )
)
