const initState: ReduxStore.MaterialTypes.State = {
  isFetching: false,
  list: [],
}

export default function MaterialTypesReducer(
  state: ReduxStore.MaterialTypes.State = initState,
  action: ReduxStore.MaterialTypes.Action
) {
  switch (action.type) {
    case 'REQUEST_MATERIAL_TYPES':
      return {
        ...state,
        isFetching: true,
      }

    case 'RECEIVE_MATERIAL_TYPES': {
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      }
    }

    default:
      return state
  }
}
