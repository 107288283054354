import { round } from 'lodash'

export function vectorSubtract(...vectors: any) {
  return vectors.reduce((a: any, b: any) =>
    a.map((value: any, index: any) => value - (b[index] || 0))
  )
}

export function vectorAdd(...vectors: any) {
  return vectors.reduce((a: any, b: any) =>
    a.map((value: any, index: any) => value + (b[index] || 0))
  )
}

export function vectorToObject(vector: any) {
  return { x: vector[0], y: vector[1] }
}

export function getTranslate(matrix: any) {
  return [matrix.e, matrix.f]
}

export function getScale(matrix: any) {
  return [matrix.a, matrix.d]
}

export function opposingPointsToDimensions(a: any, b: any) {
  return {
    x: round(Math.min(a[0], b[0]), 2),
    y: round(Math.min(a[1], b[1]), 2),
    width: round(Math.abs(b[0] - a[0]), 2),
    height: round(Math.abs(b[1] - a[1]), 2),
  }
}
