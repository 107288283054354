import { createStyles, WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { StyleRules } from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { API } from 'api'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import {
  getDescriptions,
  getDrawings,
  getMatchedMaterials,
  getMaterialsShapeData,
  getMaterialTypePresets,
  getSelectedMaterials,
  getSelectedMaterialsShape,
  getSelectedMaterialType,
  getSelectedProject,
  getTool,
} from 'store/selectors'
import randomId from 'utils/randomId'
import { pointsToDimensions } from 'utils/shape'
import withSelectedValues from 'utils/withSelectedValues'

interface TemplateMatch {
  x: number
  y: number
  width: number
  height: number
  weight: number
  points: number[]
  shape: 'rectangle' | 'polygon'
}

const styles = (theme: Theme) => ({
  toolWrapper: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '8px',
  },
  tool: {
    width: '100%',
  },
  popperPaper: {
    padding: '10px',
  },
  popperButton: {
    margin: '5px',
  },
  popper: {
    zIndex: 2001,
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
})

interface StateProps {
  currentTool: string
  selectedMaterials: Array<number | string>
  materials: ReduxStore.Materials.Data.IMaterial[]
  drawings: ReduxStore.Drawings.Data.Drawing[]
  selectedProject: number | null
  selectedMaterialType: number | null
  descriptions: string[]
  selectedDrawing: string | null
  materialShapeData: string[]
  matchedMaterials: ReduxStore.Materials.Data.IMaterial[]
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  project: ReduxStore.Projects.Data.Project | undefined
  materialType: ReduxStore.MaterialTypes.Data.MaterialType | undefined
}

interface ComponentState {
  loading: boolean
  error: boolean
  certainty: number
  shapeData: Array<{ points: number[]; shape: string; weight: number } | null>
}

interface DispatchProps {
  dispatchSelectTool: (tool: string) => void
  dispatchAddMaterials: (materia: ReduxStore.Materials.Data.IMaterial[]) => void
  updateMatchShape: (weight: number) => null
  deleteMatchedMaterials: () => void
  confirmAllMatches: () => void
}

type Props = WithStyles<StyleRules> & StateProps & DispatchProps

class MagicTool extends React.Component<Props & ComponentState, ComponentState> {
  state = {
    loading: false,
    error: false,
    certainty: 0.8,
    shapeData: [],
  }

  buttonRef = React.createRef<HTMLElement>()
  arrowRef = React.createRef<HTMLElement>()

  componentDidUpdate(prevProps: StateProps, prevState: ComponentState, snapshot: any) {
    if (prevState.certainty !== this.state.certainty) {
      this.getMaterialAboveCertainty()
    }
  }

  getMaterialAboveCertainty = () => {
    const { updateMatchShape } = this.props
    updateMatchShape(this.state.certainty)
  }

  getMaterial = async () => {
    const { project, materialType } = this.props
    // @ts-ignore
    window.analytics.track('Overlay Extract Tool Used', {
      tool: 'TemplateMatch',
      platform: 'dataloader',
      project: {
        id: project ? project.projectId : '',
        name: project ? project.projectName : '',
      },
      materialType: {
        id: materialType ? materialType.id : '',
        name: materialType ? materialType.name : '',
      },
      drawing: this.props.selectedDrawing,
    })

    this.setState({ loading: true, error: false })
    const {
      selectedMaterials,
      materials,
      selectedProject,
      drawings,
      selectedMaterialType,
      dispatchAddMaterials,
      descriptions,
      selectedDrawing,
    } = this.props

    const selectedMaterialString: string | number = selectedMaterials[0]
    const materialDetail = materials.filter(
      material => material.overlay_id === selectedMaterialString
    )

    if (materialDetail[0].shape !== 'rectangle') {
      console.warn('Non rectangle is not supported')
      this.setState({ loading: false, error: true })
      return
    }

    const currentDrawingId = drawings.filter(drawing => drawing.displayName === selectedDrawing)[0]
      .id
    const selectShapeData = pointsToDimensions(JSON.parse(materialDetail[0].shape_data).points)

    let templateMatches: TemplateMatch[]
    try {
      templateMatches = await API.getTemplateMatchData(
        selectShapeData,
        currentDrawingId,
        selectedProject
      )
    } catch (e) {
      this.setState({ loading: false, error: true })
      return
    }

    templateMatches = templateMatches.filter(match => {
      let isOverlapping = false

      this.props.materials.forEach(material => {
        const points = JSON.parse(material.shape_data).points
        if (
          match.points[0] < points[2] &&
          match.points[2] > points[0] &&
          match.points[1] < points[5] &&
          match.points[5] > points[1]
        ) {
          isOverlapping = true
        }
      })
      return !isOverlapping
    })

    const weights = templateMatches.map((mat: any) => mat.weight).filter(weight => weight != null)
    const certainty = (Math.max(...weights, 0) + Math.min(...weights, 0)) / 2

    const matchShapeDataList = templateMatches.map(match => {
      const materialShape = match.shape
      const newId = randomId()
      const stringShapeData = '{"points":' + JSON.stringify(match.points) + '}'
      const value = descriptions.reduce((acc: { [key: string]: string }, name) => {
        if (name === 'Drawing Name' && selectedDrawing) {
          acc[name] = selectedDrawing
        } else {
          // Check if there is a preset set for this.
          const preset = this.props.presets.find(preset => preset.description === name)
          if (preset) {
            acc[name] = preset.value
          } else {
            acc[name] = ''
          }
        }
        return acc
      }, {})
      let matchShape = 'matchRectangle'
      if (materialShape === 'polygon') {
        matchShape = 'matchPolygon'
      } else if (materialShape === 'rectangle') {
        matchShape = 'matchRectangle'
      }
      let shapeIsDelete = true
      if (match.weight >= certainty) {
        shapeIsDelete = false
      }
      const mockData: ReduxStore.Materials.Data.IMaterial = {
        overlay_id: newId,
        material_id: newId,
        drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
        display_name: selectedDrawing as string,
        shape: matchShape,
        shape_data: stringShapeData,
        company_project_id: selectedProject as number,
        material_type_id: selectedMaterialType as number,
        value,
        isDeleted: shapeIsDelete,
        weight: match.weight,
        matchSource: 'magicTool',
      }
      return mockData
    })
    this.setState({ certainty }, () => {
      dispatchAddMaterials(matchShapeDataList)
      this.getMaterialAboveCertainty()
      this.setState({ loading: false })
    })
  }

  onSliderChange = (value: any) => {
    this.setState({
      certainty: value,
    })
  }

  deleteAllMatches = () => {
    this.props.deleteMatchedMaterials()
  }

  confirmAllMatches = () => {
    this.props.confirmAllMatches()
    // We delete the remainder of the matches after keeping the ones we care about.
    this.deleteAllMatches()
  }

  getMinMaxWeights = () => {
    const weights = this.props.matchedMaterials
      .map(mat => mat.weight)
      .filter(weight => weight != null)
    // @ts-ignore
    return [Math.min(...weights), Math.max(...weights)]
  }

  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    this.setState({ error: false })
  }

  render() {
    const { classes, selectedMaterials, matchedMaterials } = this.props
    const { loading, error } = this.state

    const magicToolMatches = matchedMaterials.filter(match => match.matchSource === 'magicTool')

    const minMax = this.getMinMaxWeights()

    return (
      <div className={classes.toolWrapper}>
        <Button
          variant="outlined"
          className={classes.tool}
          disabled={
            selectedMaterials.length === 0 || magicToolMatches.length > 0 || loading || error
          }
          buttonRef={this.buttonRef}
          onClick={this.getMaterial}
        >
          {loading && <CircularProgress size={24} />}
          {!loading && <Icon>brush</Icon>}
        </Button>
        <Popper
          open={magicToolMatches.length > 0}
          anchorEl={this.buttonRef.current}
          className={classes.popper}
          placement="right"
          modifiers={{
            arrow: {
              enabled: true,
              element: this.arrowRef.current,
            },
          }}
        >
          <span className={classes.arrow} ref={this.arrowRef} />
          <Paper className={classes.popperPaper}>
            <div>
              <Typography id="discrete-slider" gutterBottom={true}>
                Adjust Certainty.{' '}
                <i>
                  Total:{' '}
                  {
                    magicToolMatches.filter(mat => mat.weight && mat.weight >= this.state.certainty)
                      .length
                  }
                  /{magicToolMatches.length}
                </i>
              </Typography>
              <Slider
                defaultValue={0.8}
                value={this.state.certainty}
                dots={true}
                step={(minMax[1] - minMax[0]) / 10}
                min={minMax[0]}
                max={minMax[1]}
                onChange={this.onSliderChange}
              />
            </div>
            <div>
              <Button
                onClick={this.deleteAllMatches}
                variant="outlined"
                className={classes.popperButton}
              >
                Cancel
              </Button>
              <Button
                onClick={this.confirmAllMatches}
                variant="outlined"
                className={classes.popperButton}
              >
                Confirm
              </Button>
            </div>
          </Paper>
        </Popper>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={<span id="message-id">Error loading Template Matches!</span>}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>,
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  state: ReduxStore.State,
  {
    selectedDrawing,
    selectedMaterialType,
    projectId,
  }: { selectedDrawing: string; selectedMaterialType: string; projectId: number }
) => ({
  currentTool: getTool(state),
  selectedMaterials: getSelectedMaterials(state),
  materials: getSelectedMaterialsShape(state),
  drawings: getDrawings(state),
  descriptions: getDescriptions(state),
  materialShapeData: getMaterialsShapeData(state),
  matchedMaterials: getMatchedMaterials(state),
  selectedDrawing,
  presets: getMaterialTypePresets(state, selectedMaterialType),
  project: getSelectedProject(state, projectId),
  materialType: getSelectedMaterialType(state, parseInt(selectedMaterialType, 10)),
})

const mapDispatchToProps = {
  dispatchSelectTool: action.selectTool,
  dispatchAddMaterials: action.addMaterials,
  deleteMaterials: action.deleteMaterials,
  updateMatchShape: action.updataMatchedMaterial,
  deleteMatchedMaterials: action.deleteMatchedMaterials,
  confirmAllMatches: action.confirmMatchedMaterials,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(withStyles(styles)(MagicTool))
)
