import Konva from 'konva'
import { chunk, cloneDeep, findIndex, round } from 'lodash'
import React, { useState } from 'react'
import { Circle, Group, Line, Rect } from 'react-konva'
import { connect } from 'react-redux'
import actions from 'store/actions'
import { throttleRaf } from 'utils/throttleRaf'

interface Props {
  selectedMaterials: Array<number | string>
  material: ReduxStore.Materials.Data.IMaterial
  scale: { x: number; y: number }
  onMouseDown: (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragStart: (overlayId: string | number) => void
  onDragMove: (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragEnd: (
    evt: Konva.KonvaEventObject<MouseEvent>,
    material: ReduxStore.Materials.Data.IMaterial
  ) => void
  dispatchUpdateCirclePos: (newPos: ReduxStore.Materials.Data.INewCirclePos) => void
}

interface DispatchProps {
  dispatchDeleteSelectedShapes: (selection: Array<string | number>) => void
  dispatchKeepMatchShapes: (desTobeUpdated: ReduxStore.Materials.Data.IMatchTobeUpdated) => void
}

const Polygon: React.FC<Props & DispatchProps> = props => {
  const lineRef = React.createRef<any>()
  const [highlightedCircleIndex, setHighlightedCircleIndex] = useState<number>(-1)

  const {
    selectedMaterials,
    material,
    scale,
    onMouseDown,
    onDragStart,
    onDragMove,
    onDragEnd,
    dispatchUpdateCirclePos,
    dispatchDeleteSelectedShapes,
    dispatchKeepMatchShapes,
  } = props
  const { overlay_id, shape_data } = material
  const points = JSON.parse(shape_data).points
  const chunkedPoints = chunk(points, 2) as Array<[number, number]>

  const handleCircleMouseOver = throttleRaf((evt: Konva.KonvaEventObject<MouseEvent>) => {
    const pointIndex = findIndex(
      chunkedPoints,
      point => point[0] === evt.target.x() && point[1] === evt.target.y()
    )
    setHighlightedCircleIndex(pointIndex)
  })

  const handleCircleDragMove = throttleRaf(
    (evt: Konva.KonvaEventObject<MouseEvent>, pointIndex: number) => {
      const posX = round(evt.target.x(), 2)
      const posY = round(evt.target.y(), 2)
      const lineShape = lineRef.current
      if (lineShape) {
        lineShape.attrs.points[pointIndex * 2] = posX
        lineShape.attrs.points[pointIndex * 2 + 1] = posY
      }
    }
  )

  const handleCircleMouseLeave = () => {
    setHighlightedCircleIndex(-1)
  }

  const handleCircleDragEnd = (evt: Konva.KonvaEventObject<MouseEvent>, pointIndex: number) => {
    const posX = round(evt.target.x(), 2)
    const posY = round(evt.target.y(), 2)
    dispatchUpdateCirclePos({ pos: [posX, posY], pointIndex, material: cloneDeep(material) })
  }
  const crossSize = 20
  const crossPosition1 = [points[0] - crossSize, points[1] - crossSize, points[0], points[1]]
  const crossPosition2 = [points[0] - crossSize, points[1], points[0], points[1] - crossSize]
  const deletedOverlay = [overlay_id as number]
  const deleteMatchedShape = () => {
    dispatchDeleteSelectedShapes(deletedOverlay)
  }
  const keepMatchedShape = () => {
    const overlayId = overlay_id as number
    dispatchKeepMatchShapes({
      overlayIdTobeUpdated: overlayId,
      overlayShape: 'polygon',
    })
  }
  return (
    // @ts-ignore
    <Group>
      <Group onClick={deleteMatchedShape}>
        <Rect
          x={points[0] - crossSize}
          y={points[1] - crossSize}
          width={crossSize}
          height={crossSize}
        />

        <Line x={0} y={0} points={crossPosition1} tension={1.5} closed={true} stroke="red" />
        <Line x={0} y={0} points={crossPosition2} tension={1.5} closed={true} stroke="red" />
      </Group>
      <Group onClick={keepMatchedShape}>
        <Rect
          x={points[0]}
          y={points[1] - crossSize}
          width={crossSize}
          height={crossSize}
          fill="red"
        />
        <Line x={0} y={0} points={crossPosition1} tension={1.5} closed={true} stroke="red" />
        <Line x={0} y={0} points={crossPosition2} tension={1.5} closed={true} stroke="red" />
      </Group>
      {
        // @ts-ignore
        <Group
          ref={instance => instance && window.shapeRefs.push(instance)}
          key={`${overlay_id}-${JSON.stringify(points)}`} // must force to change the key so that the polygon can be rendered again
          shapeId={overlay_id}
          materialBelongsTo={overlay_id}
          shape="polygon"
          draggable={true}
          onMouseDown={evt => onMouseDown(evt, material)}
          onDragStart={() => onDragStart(overlay_id)}
          onDragMove={evt => onDragMove(evt, material)}
          onDragEnd={evt => onDragEnd(evt, material)}
        >
          {
            <Line
              ref={lineRef}
              points={cloneDeep(points)}
              scaleX={1}
              scaleY={1}
              closed={true}
              fill="rgba(255,127,80,0.5)"
            />
          }
          {selectedMaterials &&
            selectedMaterials.some(id => id === overlay_id) &&
            chunkedPoints.map((point, pointIndex) => (
              <Circle
                draggable={true}
                key={point[0] + point[1]}
                x={point[0]}
                y={point[1]}
                radius={highlightedCircleIndex === pointIndex ? 10 / scale.x : 4 / scale.x}
                stroke="red"
                strokeWidth={Math.round(2 / scale.x)}
                fill="rgba(17,80,135,1)"
                onMouseDown={evt => evt.evt.stopPropagation()}
                onMouseEnter={handleCircleMouseOver}
                onMouseLeave={handleCircleMouseLeave}
                onDragMove={evt => {
                  handleCircleDragMove(evt, pointIndex)
                }}
                onDragEnd={evt => {
                  handleCircleDragEnd(evt, pointIndex)
                }}
              />
            ))}
        </Group>
      }
    </Group>
  )
}

const mapDispatchToProps = {
  dispatchDeleteSelectedShapes: actions.deleteSelection,
  dispatchKeepMatchShapes: actions.updateMatchShape,
}

export default connect(
  null,
  mapDispatchToProps
  // @ts-ignore
)(Polygon)
