import { createStyles, WithStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import CONST from 'const'
import React from 'react'
import classNames from 'utils/classNames'

const selectorMountId = 'selector-menu-mount'

const styles = createStyles({
  root: {
    position: 'fixed',
    height: '100%',
    zIndex: CONST.Z_INDEX_SELECT_MENU,
    backgroundColor: 'white',
    overflow: 'scroll',
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
  style?: React.CSSProperties
}

const SelectorMenuMount: React.FC<Props> = ({ classes, className, style }) => {
  return (
    <Paper id={selectorMountId} className={classNames(className, classes.root)} style={style} />
  )
}

export default withStyles(styles)(SelectorMenuMount)
