import { HotTable } from '@handsontable/react'
import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import CONST from 'const'
import Handsontable from 'handsontable'
// @ts-ignore
import 'handsontable/dist/handsontable.full.min.css'
import { cloneDeep, isEmpty, isEqual, zipObject } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import CenteredSpinner from 'shared/CenteredSpinner'
import action from 'store/actions'
import {
  getDescriptions,
  getDescriptionsData,
  getDescriptionTypes,
  getDrawings,
  getListIds,
  getListIdValueMap,
  getMaterials,
  getMaterialsFetchingStatus,
  getMaterialsOnCurrentDrawing,
  getMaterialTypePresets,
  getSelectedMaterials,
  getSelectFrom,
  getUpdateFloorplanAndTableCanvas,
} from 'store/selectors'
import classNames from 'utils/classNames'
import {
  getCellsTobeSelected,
  getHotColumns,
  getHotData,
  getHOTSelectedColumns,
  getHotSelectedRows,
} from 'utils/hotTable'
import randomId from 'utils/randomId'
import { dimensionsToPoints, getRowsToBeSelected } from 'utils/shape'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

const styles = createStyles({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
})

interface StateProps {
  isFetchingMaterials: boolean
  selectedMaterials: Array<number | string>
  selectFrom: number
  descriptions: string[]
  descriptionTypes: string[]
  listIds: number[]
  listIdValueMap: { [key in string]: string[] }
  materials: ReduxStore.Materials.Data.IMaterial[]
  allMaterials: { [key: string]: ReduxStore.Materials.Data.IMaterial }
  drawings: ReduxStore.Drawings.Data.Drawing[]
  descriptionsData: ReduxStore.Materials.Data.DescriptionData[]
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  updateFloorplanAndTableCanvas: number
}

interface DispatchProps {
  dispatchDeleteSelectedShapes: (selection: Array<string | number>) => void
  dispatchDeleteSelectedRecordsByIds: (selection: Array<string | number>) => void
  dispatchSetSelection: (selection: Array<number | string>, selectFrom: number) => void
  dispatchSetSelectFrom: (selectFrom: number) => void
  mergeOverlays: (overlaysTobeMerged: ReduxStore.Materials.Data.IOverlaysTobeMerged) => void
  updateMaterialDes: (desTobeUpdated: ReduxStore.Materials.Data.IDesTobeUpdated) => void
  dispatchAddMaterials: (materia: ReduxStore.Materials.Data.IMaterial) => void
  dispatchBulkAddMaterials: (materials: ReduxStore.Materials.Data.IMaterial[]) => void
  dispatchHotTableRef: (hotTableRef: any) => void
}

interface OwnProps {
  hotTableComponent: React.RefObject<HotTable>
  className?: string
}

type Props = WithStyles<typeof styles> & StateProps & DispatchProps & OwnProps & DataloaderInfo

interface ComponentState {
  selectedRecordIds: string[]
  destinationSortConfigs: Array<{
    column: number
    sortOrder: 'desc' | 'asc'
  }>
}

function emptyIdRenderer(
  this: Handsontable,
  instance: any,
  td: any,
  row: any,
  col: any,
  prop: any,
  value: any,
  cellProperties: any
) {
  if (col === 0 && value === '') {
    td.style.background = 'salmon'
  }
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ])
}

class MaterialTable extends React.Component<Props, ComponentState> {
  hotTableComponent = React.createRef<HotTable>()
  selectedCells: Array<[number, number, number, number]> | undefined = []

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedRecordIds: [],
      destinationSortConfigs: [],
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: ComponentState) {
    const {
      isFetchingMaterials,
      selectedMaterials,
      selectFrom,
      materials,
      updateFloorplanAndTableCanvas,
    } = this.props
    const { destinationSortConfigs } = this.state
    return (
      !isEqual(nextProps.isFetchingMaterials, isFetchingMaterials) ||
      !isEqual(nextProps.materials, materials) ||
      (!isEqual(nextProps.selectedMaterials, selectedMaterials) &&
        nextProps.selectFrom === CONST.SELECT_FROM_VIEWER) ||
      !isEqual(nextProps.selectFrom, selectFrom) ||
      !isEqual(nextState.destinationSortConfigs, destinationSortConfigs) ||
      !isEqual(nextProps.updateFloorplanAndTableCanvas, updateFloorplanAndTableCanvas)
    )
  }

  componentDidUpdate() {
    const {
      selectedMaterials,
      selectFrom,
      materials,
      descriptions,
      dispatchHotTableRef,
    } = this.props
    const { destinationSortConfigs } = this.state
    // hightliht cells in HOT when user clicks on shapes on the viewer
    if (this.hotTableComponent.current) {
      dispatchHotTableRef(this.hotTableComponent.current)
    }
    if (this.hotTableComponent.current !== null && selectFrom === CONST.SELECT_FROM_VIEWER) {
      this.selectedCells = this.hotTableComponent.current.hotInstance.getSelected()
      // user closure to avoid infinit rendering error
      const columnHeaders = [...descriptions, 'shape_data', 'overlay_id']
      const data = getHotData(materials)
      if (destinationSortConfigs.length > 0) {
        data.sort(this.dynamicSort(columnHeaders, destinationSortConfigs[0]))
      }
      const rowsTobeSelected = getRowsToBeSelected(data, selectedMaterials)
      if (rowsTobeSelected.length !== 0) {
        if (this.selectedCells) {
          const hotSelectedColumns = getHOTSelectedColumns(this.selectedCells)
          const cellsTobeSelectedInHot = getCellsTobeSelected(rowsTobeSelected, hotSelectedColumns)
          setTimeout(() => {
            if (this.hotTableComponent.current) {
              // @ts-ignore
              this.hotTableComponent.current.hotInstance.selectCells(cellsTobeSelectedInHot)
            }
          }, 0)
        }
      } else {
        setTimeout(() => {
          if (this.hotTableComponent.current) {
            // @ts-ignore
            this.hotTableComponent.current.hotInstance.selectCells([[]])
          }
        }, 0)
      }
    }
  }

  removeEmptyRowsFromHOT = () => {
    // Trim empty rows
    const sourceData = this.getSortedSourceData()
    if (!sourceData || !this.hotTableComponent.current) {
      return
    }
    // Look for completely empty rows
    const rowsToDelete: number[] = []
    sourceData.forEach((row, index) => {
      if (row.overlay_id.length === 0 && !row.shape_data) {
        rowsToDelete.push(index)
      }
    })
    this.hotTableComponent.current.hotInstance.alter(
      'remove_row',
      rowsToDelete.map(idx => [idx, 1])
    )
  }

  deleteOverlaysFromHOT = () => {
    // this.deleteOverlays()
    const { selectedMaterials, dispatchDeleteSelectedShapes } = this.props
    if (selectedMaterials && selectedMaterials.length > 0) {
      dispatchDeleteSelectedShapes(selectedMaterials)
    }
    this.removeEmptyRowsFromHOT()
  }

  deleteRecordsFromHOT = () => {
    const {
      selectedMaterials,
      dispatchDeleteSelectedShapes,
      dispatchDeleteSelectedRecordsByIds,
    } = this.props
    if (selectedMaterials && selectedMaterials.length > 0) {
      let realMaterialIds: string[] = []
      if (this.state.selectedRecordIds && this.state.selectedRecordIds.length) {
        realMaterialIds = this.state.selectedRecordIds.filter(id => /^\d+$/.test(id))
      }
      dispatchDeleteSelectedShapes(selectedMaterials)
      dispatchDeleteSelectedRecordsByIds(realMaterialIds)
    }
    this.removeEmptyRowsFromHOT()
  }

  afterSelectionEnd = (overlayIDColumnIndex: number, data: any[]) => {
    const { dispatchSetSelection } = this.props
    if (this.hotTableComponent.current) {
      const selectedRows = getHotSelectedRows(
        this.hotTableComponent.current.hotInstance.getSelected() as Array<
          [number, number, number, number]
        >
      )
      const selectedRecordIds = selectedRows.map(rowIndex => data[rowIndex].id)
      this.setState({ selectedRecordIds })
      const overlay_id_array = this.hotTableComponent.current.hotInstance.getDataAtCol(
        overlayIDColumnIndex
      )
      const selection = selectedRows.map(rowIndex => overlay_id_array[rowIndex]).flat()
      dispatchSetSelection(selection, CONST.SELECT_FROM_HOT)
    }
  }

  instanceOfDimension(points: any): points is ReduxStore.Materials.Data.IDimensions {
    return 'width' in points && 'height' in points && 'x' in points && 'y' in points
  }

  getSortedSourceData = (): any[] | undefined => {
    if (this.hotTableComponent.current) {
      const { getData, getColHeader } = this.hotTableComponent.current.hotInstance
      const colHeader = getColHeader()
      const hotData = getData()
      return hotData.map(dataInRow => {
        return zipObject(colHeader, dataInRow)
      })
    }
    return undefined
  }

  handleBeforeChange = (changes: ReduxStore.Materials.Data.HOTChanges) => {
    // Changes: [[row, prop, oldVal, newVal], ...]
    if (!changes || changes.length === 0) {
      return
    }
    const sourceData = this.getSortedSourceData()
    if (!sourceData) {
      return
    }
    changes.forEach(change => {
      // Ensure the row has shape data
      const [rowIndex, propName, oldValue, newValue] = change
      const rowData = sourceData[rowIndex]
      const shapeData = changes.find(c => c[0] === rowIndex && c[1] === 'shape_data' && c[3])
      // Don't allow editing cells for which we don't have an overlay.
      if (!(rowData && rowData.shape_data) && !shapeData) {
        change[3] = ''
      } else if (typeof change[3] === 'string') {
        // Trim string inputs.
        change[3] = change[3].trim()
      } else if (change[3] === null) {
        change[3] = ''
      }
    })
  }

  handleCellChange(changes: ReduxStore.Materials.Data.HOTChanges | null) {
    // Changes: [[row, prop, oldVal, newVal], ...]
    if (!changes || changes.length === 0) {
      return
    }

    // Filter out changes where the old value is the same as the new value
    const filteredChanges = changes.filter(change => change[2] !== change[3])

    const {
      mergeOverlays,
      updateMaterialDes,
      dispatchAddMaterials,
      selectedDrawing,
      selectedProject,
      selectedMaterialType,
      drawings,
      descriptions,
      allMaterials,
      dispatchBulkAddMaterials,
    } = this.props
    const rowsTobeUpdate = new Set<number>()
    const overlaysTobeUpdate: ReduxStore.Materials.Data.IOverlaysTobeMerged = {}
    const sortedSourceData = this.getSortedSourceData()
    const createdRows = new Set<number>()

    const materialsToBeCreated: ReduxStore.Materials.Data.IMaterial[] = []

    // List of IDs we have to check for merging overlays
    filteredChanges
      .filter(change => change[1] === 'ID')
      .forEach(change => {
        const [rowIndex, propName, oldValue, newValue] = change
        if (oldValue != null && sortedSourceData) {
          createdRows.add(rowIndex)
          const overlay_id = sortedSourceData[rowIndex].overlay_id[0]
          overlaysTobeUpdate[overlay_id] = {
            oldValue,
            newValue,
          }
        }
      })

    // List of materials we should create
    filteredChanges
      .filter(change => change[1] === 'shape_data' && (change[2] == null || change[2] === ''))
      .forEach(change => {
        const [rowIndex, propName, oldValue, newValue] = change
        // Check if we have to add a new material
        createdRows.add(rowIndex)
        try {
          const shapeDataJSON: {
            [key: number]: {
              points: ReduxStore.Materials.Data.IDimensions | ReduxStore.Materials.Data.IPoints
            }
          } = JSON.parse(newValue)

          let shapeType = ''
          let newShapeData = ''
          Object.values(shapeDataJSON).forEach(shapeData => {
            const id = randomId()
            const { points } = shapeData
            if (this.instanceOfDimension(points)) {
              shapeType = 'rectangle'
              newShapeData = JSON.stringify({ points: dimensionsToPoints(points) })
            } else {
              shapeType = 'polygon'
              newShapeData = JSON.stringify({ points })
            }

            // Verify if this material already exists, either in new materials, or materials we've already added.
            let existingMaterial: ReduxStore.Materials.Data.IMaterial | undefined
            // @ts-ignore Known not to be null with checks above.
            const IDChange = filteredChanges.find(
              change =>
                change[0] === rowIndex && change[1] === 'ID' && change[3] && change[3] !== ''
            )
            const ID = IDChange && IDChange[3]
            let material_id: string | number = id
            if (ID) {
              // Check if an overlay with the same ID exists within this project & material type.
              const existingOverlayId = Object.keys(allMaterials).find(
                overlayId => allMaterials[overlayId].value.ID === ID
              )
              if (existingOverlayId) {
                existingMaterial = allMaterials[existingOverlayId]
              }

              if (!existingMaterial) {
                existingMaterial = materialsToBeCreated.find(mat => mat.value.ID === ID)
              }

              if (existingMaterial) {
                material_id = existingMaterial.material_id
              }
            }

            const materialValues = descriptions.reduce(
              (acc: { [key: string]: string }, name: string) => {
                if (selectedDrawing && name === 'Drawing Name') {
                  acc[name] = selectedDrawing
                } else {
                  // Search changes for description value.
                  const newValue = filteredChanges.find(
                    chng => rowIndex === chng[0] && name === chng[1]
                  )
                  if (newValue && newValue[3] !== '' && newValue[3] != null) {
                    acc[name] = newValue[3]
                  } else {
                    // Check if there is a preset set for this.
                    const preset = this.props.presets.find(preset => preset.description === name)
                    if (preset) {
                      acc[name] = preset.value
                    } else {
                      const descriptionData = this.props.descriptionsData.find(
                        descData => descData.description === name
                      )
                      if (
                        descriptionData &&
                        (descriptionData.type === 'list' ||
                          descriptionData.type === 'button-list') &&
                        descriptionData.choices.length > 0
                      ) {
                        acc[name] = descriptionData.choices[0]
                      } else {
                        acc[name] = ''
                      }
                    }
                  }
                }
                return acc
              },
              {}
            )

            materialsToBeCreated.push({
              overlay_id: id,
              material_id,
              drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
              display_name: selectedDrawing as string,
              // @ts-ignore
              shape: shapeType,
              shape_data: newShapeData,
              company_project_id: selectedProject as number,
              material_type_id: selectedMaterialType as number,
              value: materialValues,
            })
          })
        } catch (err) {
          // do nothing
          // TODO: Do something.
        }
      })

    // We have not created/or 'merged' these row.
    filteredChanges
      .filter(change => !createdRows.has(change[0]))
      .forEach(change => {
        rowsTobeUpdate.add(change[0])
      })

    dispatchBulkAddMaterials(materialsToBeCreated)

    if (sortedSourceData && rowsTobeUpdate.size > 0) {
      updateMaterialDes({
        hotData: cloneDeep(sortedSourceData),
        rowsTobeUpdated: Array.from(rowsTobeUpdate),
      })
    }

    if (Object.keys(overlaysTobeUpdate).length > 0) {
      mergeOverlays(overlaysTobeUpdate)
    }
  }

  dynamicSort = (
    columnHeader: string[],
    destinationSortConfigs: { column: number; sortOrder: 'asc' | 'desc' } | undefined
  ) => {
    if (destinationSortConfigs) {
      const { column, sortOrder } = destinationSortConfigs
      const propertyName = columnHeader[column]
      let sort = 1
      if (sortOrder === 'desc') {
        sort = -1
      }
      return (a: any, b: any) => {
        const aString = a[propertyName].toString()
        const bString = b[propertyName].toString()
        const result = aString < bString ? -1 : aString > bString ? 1 : 0
        return result * sort
      }
    }
  }

  render() {
    const {
      dispatchSetSelectFrom,
      className,
      classes,
      isFetchingMaterials,
      materials,
      descriptions,
      descriptionTypes,
      listIds,
      listIdValueMap,
      selectFrom,
    } = this.props
    const { destinationSortConfigs } = this.state
    if (isFetchingMaterials) {
      return <CenteredSpinner />
    }

    const columns = getHotColumns(descriptions, descriptionTypes, listIds, listIdValueMap)
    const columnHeaders = [...descriptions, 'overlay_id', 'shape_data']
    const data = getHotData(materials)
    if (destinationSortConfigs.length > 0) {
      data.sort(this.dynamicSort(columnHeaders, destinationSortConfigs[0]))
    }
    const overlayIDColumnIndex = columnHeaders.indexOf('overlay_id')

    return (
      <div className={classNames(className, classes.root)}>
        <HotTable
          licenseKey="bc642-c159b-ce583-14136-ac809"
          renderer={emptyIdRenderer}
          ref={this.hotTableComponent}
          data={data}
          columns={columns}
          colHeaders={columnHeaders}
          outsideClickDeselects={true}
          fixedColumnsLeft={1}
          afterSelectionEnd={() => {
            if (!isEmpty(data) && selectFrom === CONST.SELECT_FROM_HOT) {
              this.afterSelectionEnd(overlayIDColumnIndex, data)
            }
          }}
          afterOnCellMouseDown={() =>
            selectFrom === CONST.SELECT_FROM_VIEWER && dispatchSetSelectFrom(CONST.SELECT_FROM_HOT)
          }
          selectionMode="multiple"
          rowHeaders={true}
          columnSorting={true}
          afterColumnSort={(currentSortConfig: any, destinationSortConfigs: any) => {
            this.setState({ destinationSortConfigs })
          }}
          manualRowResize={true}
          manualColumnResize={true}
          beforeChange={this.handleBeforeChange}
          afterChange={changes => {
            if (changes) {
              this.handleCellChange(changes)
            }
          }}
          readOnly={selectFrom === CONST.SELECT_FROM_VIEWER}
          minSpareRows={1}
          autoColumnSize={true}
          // @ts-ignore
          contextMenu={{
            items: {
              remove_row: {
                name: 'Remove from drawing',
                callback: this.deleteOverlaysFromHOT,
              },
              delete_record: {
                name: 'Delete Records',
                callback: this.deleteRecordsFromHOT,
              },
            },
          }}
          copyPaste={{ rowsLimit: 4000 }}
        />
      </div>
    )
  }
}

const mapStateToProps = (
  state: ReduxStore.State,
  {
    selectedDrawing,
    selectedMaterialType,
  }: { selectedDrawing: string; selectedMaterialType: string }
) => ({
  isFetchingMaterials: getMaterialsFetchingStatus(state),
  selectedMaterials: getSelectedMaterials(state),
  selectFrom: getSelectFrom(state),
  descriptions: getDescriptions(state),
  descriptionTypes: getDescriptionTypes(state),
  listIds: getListIds(state),
  listIdValueMap: getListIdValueMap(state),
  drawings: getDrawings(state),
  materials: getMaterialsOnCurrentDrawing(state, selectedDrawing),
  allMaterials: getMaterials(state),
  descriptionsData: getDescriptionsData(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  updateFloorplanAndTableCanvas: getUpdateFloorplanAndTableCanvas(state),
})

const mapDispatchToProps = {
  dispatchDeleteSelectedShapes: action.deleteSelection,
  dispatchDeleteSelectedRecordsByIds: action.deleteSelectedRecordsByIds,
  dispatchSetSelection: action.setSelection,
  dispatchSetSelectFrom: action.setSelectFrom,
  mergeOverlays: action.mergeOverlays,
  updateMaterialDes: action.updateMaterialDes,
  dispatchAddMaterials: action.addMaterial,
  dispatchBulkAddMaterials: action.addMaterials,
  dispatchHotTableRef: action.setHotTableRef,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MaterialTable))
)
