import Konva from 'konva'
import { off } from 'process'
import { pointsToDimensions } from 'src/utils/shape'

export const getGuides = (lineGuideStops: any, itemBounds: any) => {
  const resultV: Array<null | any> = []
  const resultH: Array<null | any> = []
  // @ts-ignore
  lineGuideStops.vertical.forEach(lineGuide => {
    // @ts-ignore
    itemBounds.vertical.forEach(itemBound => {
      const diff = Math.abs(lineGuide - itemBound.guide)
      // if the distance between guild line and object snap point is close we can consider this for snapping
      if (diff < 5) {
        resultV.push({
          lineGuide,
          diff,
          change: lineGuide - itemBound.guide,
          snap: itemBound.snap,
          offset: itemBound.offset,
        })
      }
    })
  })

  // @ts-ignore
  lineGuideStops.horizontal.forEach(lineGuide => {
    // @ts-ignore
    itemBounds.horizontal.forEach(itemBound => {
      const diff = Math.abs(lineGuide - itemBound.guide)
      if (diff < 5) {
        resultH.push({
          lineGuide,
          diff,
          snap: itemBound.snap,
          change: lineGuide - itemBound.guide,
          offset: itemBound.offset,
        })
      }
    })
  })

  const guides: Array<null | any> = []

  // find closest snap
  const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0]
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      change: minV.change,
      orientation: 'V',
      snap: minV.snap,
    })
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      change: minH.change,
      orientation: 'H',
      snap: minH.snap,
    })
  }
  return guides
}

const getClosePoints = (point: any, points: any[], snappingDistance: number) => {
  if (snappingDistance === 1000) {
    return points
  }
  const distanceLimit = snappingDistance
  const filteredpoints = points.filter(p => {
    const distance = Math.sqrt(Math.abs(p.x - point.x) ** 2 + Math.abs(p.y - point.y) ** 2)
    if (distance <= distanceLimit) {
      return true
    }
  })
  return filteredpoints
}

export const getLineGuideStops = (
  skipShape: Konva.Shape | Konva.Stage,
  currentPosition: any,
  snappingDistance: number
) => {
  // we can snap to stage borders and the center of the stage
  const vertical: Array<number[] | number> = [0, skipShape.getParent().width()]
  const horizontal: Array<number[] | number> = [0, skipShape.getParent().height()]

  // and we snap over edges and center of each object on the canvas
  // @ts-ignore
  skipShape
    .getLayer()
    .getChildren((node: Konva.Node) => node.getClassName() === 'Rect')
    .each((guideItem: Konva.Shape | Konva.Stage) => {
      if (guideItem === skipShape) {
        return
      }
      const box = guideItem.getAttrs()
      // and we can snap to all edges of shapes
      if (box.x && box.width) {
        vertical.push([box.x, box.x + box.width])
      }
      if (box.y && box.height) {
        horizontal.push([box.y, box.y + box.height])
      }
    })

  // @ts-ignore
  skipShape
    .getLayer()
    .getChildren((node: Konva.Node) => node.getClassName() === 'Group')
    .each((group: Konva.Node) => {
      // @ts-ignore
      const lines: Konva.Line[] = group.getChildren(node => node.getClassName() === 'Line')
      lines.forEach((line: Konva.Line, i: number) => {
        const points = line.points()
        for (let i = 0; i < points.length; i += 2) {
          const x = points[i]
          const y = points[i + 1]
          vertical.push(x)
          horizontal.push(y)
        }
      })
    })

  const points: any[] = []
  vertical.flat().forEach((x, i) => {
    points.push({})
    points[i].x = x
  })
  horizontal.flat().forEach((y, i) => {
    if (points[i]) {
      points[i].y = y
    } else {
      points.push({})
      points[i].y = y
    }
  })
  const filteredPoints = getClosePoints(currentPosition, points, snappingDistance)
  const finalVertical: any[] = []
  const finalHorizontal: any[] = []
  filteredPoints.forEach(point => {
    finalVertical.push(point.x)
    finalHorizontal.push(point.y)
  })
  return {
    vertical: finalVertical.flat(),
    horizontal: finalHorizontal.flat(),
  }
}

export const drawGuides = (guides: Array<null | any>, node: Konva.Shape | Konva.Stage) => {
  const layer = node.getLayer()

  if (layer) {
    guides.forEach(lg => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
          stroke: 'rgb(255,74,234)',
          strokeWidth: 2,
          name: 'guid-line',
          dash: [4, 6],
          listening: false,
        })
        layer.add(line)
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [lg.lineGuide, -6000, lg.lineGuide, 6000],
          stroke: 'rgb(255,74,234)',
          strokeWidth: 2,
          name: 'guid-line',
          dash: [4, 6],
          listening: false,
        })
        layer.add(line)
      }
    })
    layer.batchDraw()
  }
}

export function getAnchorPoints(guides: any[]) {
  let presetX: number | undefined
  let presetY: number | undefined
  guides.forEach(lg => {
    switch (lg.snap) {
      case 'start': {
        switch (lg.orientation) {
          case 'V': {
            presetX = lg.lineGuide + lg.offset
            break
          }
          case 'H': {
            presetY = lg.lineGuide + lg.offset
            break
          }
        }
        break
      }

      case 'end': {
        switch (lg.orientation) {
          case 'V': {
            presetX = lg.lineGuide + lg.offset
            break
          }
          case 'H': {
            presetY = lg.lineGuide + lg.offset
            break
          }
        }
        break
      }
    }
  })
  return { presetX, presetY }
}

export function getChangeToGuide(
  guides: any[]
): { presetX: number | undefined; presetY: number | undefined } {
  let presetX: number | undefined
  let presetY: number | undefined
  guides.forEach(lg => {
    switch (lg.orientation) {
      case 'V': {
        presetX = lg.change
        break
      }
      case 'H': {
        presetY = lg.change
        break
      }
    }
  })
  return { presetX, presetY }
}
