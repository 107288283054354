import {
  drawGuides,
  getAnchorPoints,
  getGuides,
  getLineGuideStops,
} from 'dataloader/utils/guideLines'
import Konva from 'konva'
import { findIndex, round } from 'lodash'
import React, { Dispatch, useState } from 'react'
import { Circle, Group, Line, Rect } from 'react-konva'
import { connect } from 'react-redux'
import action from 'store/actions'
import { incrementMaterialPresets } from 'store/materialPresets/actions'
import {
  getAutoIncrementPresetsEnabled,
  getDescriptions,
  getDescriptionsData,
  getDrawings,
  getMaterialTypePresets,
  getSnappingDistance,
  getSnappingEnabled,
} from 'store/selectors'
import randomId from 'utils/randomId'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

interface PointsPos {
  x: number
  y: number
}

interface StateProps {
  descriptions: string[]
  descriptionsData: ReduxStore.Materials.Data.DescriptionData[]
  drawings: ReduxStore.Drawings.Data.Drawing[]
  snappingEnabled: boolean
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  autoIncrementEnabled: boolean
  snappingDistance: number
}

interface DispatchProps {
  dispatchAddMaterials: (m: ReduxStore.Materials.Data.IMaterial) => void
  incrementPresets: (materialTypeId: number) => void
}

interface OwnProps extends DataloaderInfo {
  width: number
  height: number
  scale: { x: number; y: number }
}

type Props = OwnProps & StateProps & DispatchProps

const PolygonTool: React.FunctionComponent<Props> = props => {
  const {
    width,
    height,
    descriptions,
    dispatchAddMaterials,
    incrementPresets,
    selectedDrawing,
    selectedProject,
    selectedMaterialType,
    drawings,
    scale,
    autoIncrementEnabled,
  } = props

  const [pointsArray, setPointsArray] = useState<PointsPos[]>([])
  const [highlightIndex, setHighlightIndex] = useState<number | undefined>(undefined)

  const getFilteredGuides = (evt: Konva.KonvaEventObject<MouseEvent>): any[] => {
    const pos = { ...eventToCoordinates(evt) }
    const lineGuideStops = getLineGuideStops(evt.target, pos, props.snappingDistance)
    const itemBounds = getObjectSnappingEdges(evt)
    if (!itemBounds) {
      return []
    }
    const guides = getGuides(lineGuideStops, itemBounds)
    if (pointsArray.length === 0) {
      return guides
    }
    const { presetX, presetY } = getAnchorPoints(guides)
    if (presetX === pointsArray[0].x && presetY === pointsArray[0].y) {
      return []
    }
    return guides
  }

  const handleMouseDown = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const guides = getFilteredGuides(evt)
    const { presetX, presetY } = getAnchorPoints(guides)
    evt.evt.stopPropagation()
    const pos: PointsPos = { ...eventToCoordinates(evt) }
    if (props.snappingEnabled) {
      pos.x = presetX || pos.x
      pos.y = presetY || pos.y
    }
    setPointsArray([...pointsArray, pos])
  }

  const getObjectSnappingEdges = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const pos = eventToCoordinates(evt)
    if (pos.x && pos.y) {
      return {
        vertical: [
          {
            guide: Math.round(pos.x),
            offset: 0,
            snap: 'start',
          },
        ],
        horizontal: [
          {
            guide: Math.round(pos.y),
            offset: 0,
            snap: 'start',
          },
        ],
      }
    }
  }

  const handleMouseMove = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    // @ts-ignore
    if (
      evt.target.findAncestor('Stage') !== undefined &&
      evt.target.getStage()!.getPointerPosition() !== undefined
    ) {
      evt.target
        .getLayer()
        .find('.guid-line')
        .each((node: Konva.Node) => node.destroy())

      if (props.snappingEnabled) {
        const guides = getFilteredGuides(evt)
        drawGuides(guides, evt.target)
      }
    }
  }

  const handleCircleMouseOver = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const index = findIndex(pointsArray, { x: evt.target.x(), y: evt.target.y() })
    setHighlightIndex(index)
  }

  const handleCircleMouseLeave = () => {
    setHighlightIndex(undefined)
  }

  const handleCircleMouseDown = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.evt.stopPropagation()
    const points: PointsPos[] = JSON.parse(JSON.stringify(pointsArray)).slice(highlightIndex)
    if (autoIncrementEnabled) {
      incrementPresets(selectedMaterialType as number)
    }
    createNewMaterial(randomId(), points)
    setPointsArray([])
    setHighlightIndex(undefined)
  }

  const eventToCoordinates = (evt: Konva.KonvaEventObject<MouseEvent>): PointsPos => {
    const transform = evt.target.getAbsoluteTransform().copy()
    transform.invert()
    const stage = evt.target.findAncestor('Stage')
    // @ts-ignore
    const pos = transform.point(stage.getPointerPosition())
    return { x: round(pos.x, 2), y: round(pos.y, 2) }
  }

  const createNewMaterial = (overlay_id: string, points: PointsPos[]) => {
    const newPoints = points
      .map(point => {
        return [point.x, point.y]
      })
      .flat()
    dispatchAddMaterials({
      overlay_id,
      material_id: overlay_id,
      drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
      display_name: selectedDrawing as string,
      shape: 'polygon',
      shape_data: JSON.stringify({ points: newPoints }),
      company_project_id: selectedProject as number,
      material_type_id: selectedMaterialType as number,
      value: descriptions.reduce((acc: { [key: string]: string }, name: string) => {
        if (selectedDrawing && name === 'Drawing Name') {
          acc[name] = selectedDrawing
        } else {
          // Check if there is a preset set for this.
          const preset = props.presets.find(preset => preset.description === name)
          if (preset) {
            acc[name] = preset.value
          } else {
            const descriptionData = props.descriptionsData.find(
              descData => descData.description === name
            )
            if (
              descriptionData &&
              (descriptionData.type === 'list' || descriptionData.type === 'button-list') &&
              descriptionData.choices.length > 0
            ) {
              acc[name] = descriptionData.choices[0]
            } else {
              acc[name] = ''
            }
          }
        }
        return acc
      }, {}),
    })
  }

  const renderPoints = () => {
    return pointsArray.map((point, index) => (
      <Circle
        key={point.x + point.y}
        x={point.x}
        y={point.y}
        stroke={index === 0 ? 'red' : undefined}
        scaleX={1}
        scaleY={1}
        radius={highlightIndex === index ? 10 / scale.x : 4 / scale.x}
        fill="rgba(17,80,135,0.5)"
        onMouseEnter={handleCircleMouseOver}
        onMouseLeave={handleCircleMouseLeave}
        onMouseDown={handleCircleMouseDown}
      />
    ))
  }

  const renderLines = () => {
    const points = pointsArray.map(point => [point.x, point.y]).flat()
    return <Line points={points} stroke="black" fill="rgba(255, 137, 0, 0.5)" />
  }

  return (
    <Group onMouseMove={handleMouseMove}>
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="rgba(0, 0, 0, 0)"
        onMouseDown={handleMouseDown}
      />
      {renderPoints()}
      {renderLines()}
    </Group>
  )
}

const mapStateToProps = (
  state: ReduxStore.State,
  { selectedMaterialType }: { selectedMaterialType: string }
) => ({
  descriptions: getDescriptions(state),
  descriptionsData: getDescriptionsData(state),
  drawings: getDrawings(state),
  snappingEnabled: getSnappingEnabled(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  autoIncrementEnabled: getAutoIncrementPresetsEnabled(state),
  snappingDistance: getSnappingDistance(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchAddMaterials: (m: ReduxStore.Materials.Data.IMaterial) => dispatch(action.addMaterial(m)),
  incrementPresets: (materialTypeId: number) => dispatch(incrementMaterialPresets(materialTypeId)),
})

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PolygonTool)
)
