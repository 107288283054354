import { ThunkAction } from 'redux-thunk'
import { API } from '../../api'
import { getMaterialTypesFetchingStatus } from '../selectors'

export const requestMaterialTypes = (): ReduxStore.MaterialTypes.RequestMaterialTypes => {
  return {
    type: 'REQUEST_MATERIAL_TYPES',
  }
}

export const receiveMaterialTypes = (
  materialTypes: ReduxStore.MaterialTypes.Data.MaterialType[]
): ReduxStore.MaterialTypes.ReceiveMaterialTypes => {
  return {
    type: 'RECEIVE_MATERIAL_TYPES',
    payload: materialTypes,
  }
}

export const fetchMaterialTypes = (
  projectId: number
): ThunkAction<void, ReduxStore.State, null, ReduxStore.MaterialTypes.Action> => {
  return async (dispatch, getState) => {
    const state = getState()
    const isFetching = getMaterialTypesFetchingStatus(state)
    if (isFetching) {
      return
    }
    dispatch(requestMaterialTypes)
    const materialTypes = await API.getMaterialTypes(projectId)
    dispatch(receiveMaterialTypes(materialTypes))
    return materialTypes
  }
}
