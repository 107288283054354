const initState: ReduxStore.Error.State = {
  errors: [],
}

export default function DraftReducer(
  state: ReduxStore.Error.State = initState,
  action: ReduxStore.Error.Action
): ReduxStore.Error.State {
  switch (action.type) {
    case 'ADD_ERROR':
      return { ...state, errors: [...state.errors, action.payload] }
    case 'REMOVE_ERROR':
      return { errors: state.errors.filter((error, i) => i !== action.payload.index) }
    default:
      return state
  }
}
