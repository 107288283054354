// tslint:disable: prefer-for-of
import Button from '@material-ui/core/Button/Button'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import DialpadIcon from '@material-ui/icons/Dialpad'
import Konva from 'konva'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { updateShapeDatas } from 'store/materials/actions'
import {
  getAlignDistance,
  getDrawings,
  getMaterialsOnCurrentDrawing,
  getSelectedMaterials,
} from 'store/selectors'
import { updateAlignDistance } from 'store/tool/actions'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

interface StateProps {
  drawings: ReduxStore.Drawings.Data.Drawing[]
  selectedMaterials: Array<string | number>
  allMaterialsOnCurrentDrawing: ReduxStore.Materials.Data.IMaterial[]
  classes: any
  alignDistance: number
}

interface DispatchProps {
  updateShapeDatas: (shapeData: ReduxStore.Materials.Data.IOverlay[]) => void
  dispatchUpdateAlignDistance: (alignDistance: number) => void
}

interface ShapeData {
  overlay_id: number | string
  shape_data_points: Array<[number, number]>
}

interface ClosePoint {
  [key: string]: [number, number]
}

type Props = StateProps & DispatchProps & DataloaderInfo

const styles = (theme: Theme) => ({
  toolWrapper: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '8px',
  },
  tool: {
    width: '100%',
  },
  popperPaper: {
    padding: '10px',
  },
  popperButton: {
    margin: '5px',
  },
  popper: {
    zIndex: 2001,
    width: '300px',
    height: '50px',
    background: '#FFFFFF',
    padding: '5px 10px',
  },
  popperSlider: {
    height: 30,
  },
})

export const ToolName = 'outliner'

// Based on Magic Wand tool implementation https://github.com/Tamersoul/magic-wand-js
const AutoAlignTool: React.FunctionComponent<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const {
    classes,
    updateShapeDatas,
    drawings,
    selectedDrawing,
    alignDistance,
    dispatchUpdateAlignDistance,
  } = props
  const showSnappingBar = Boolean(anchorEl)
  const closePoints: ClosePoint[] = []
  const updateShapeDataList: ReduxStore.Materials.Data.IOverlay[] = []
  const marks = {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
  }
  const { allMaterialsOnCurrentDrawing, selectedMaterials } = props
  const onSliderChange = (value: number) => {
    dispatchUpdateAlignDistance(value)
    alignPoints(value)
  }
  let shapeData: Array<{
    overlay_id: string | number
    shape_data_points: Array<[number, number]>
  }> = []
  if (selectedMaterials.length > 0) {
    shapeData = allMaterialsOnCurrentDrawing
      .filter(
        material =>
          (material.shape === 'rectangle' || material.shape === 'matchRectangle') &&
          selectedMaterials.includes(material.material_id)
      )
      .map(material => {
        const shape_data: number[] = JSON.parse(material.shape_data).points
        const shape_data_points: Array<[number, number]> = [
          [shape_data[0], shape_data[1]],
          [shape_data[2], shape_data[3]],
          [shape_data[4], shape_data[5]],
          [shape_data[6], shape_data[7]],
        ]
        const overlay_data = {
          overlay_id: material.overlay_id,
          shape_data_points,
        }
        return overlay_data
      })
  } else {
    shapeData = allMaterialsOnCurrentDrawing
      .filter(material => material.shape === 'rectangle' || material.shape === 'matchRectangle')
      .map(material => {
        const shape_data: number[] = JSON.parse(material.shape_data).points
        const shape_data_points: Array<[number, number]> = [
          [shape_data[0], shape_data[1]],
          [shape_data[2], shape_data[3]],
          [shape_data[4], shape_data[5]],
          [shape_data[6], shape_data[7]],
        ]
        const overlay_data = {
          overlay_id: material.overlay_id,
          shape_data_points,
        }
        return overlay_data
      })
  }

  const sortShapes = (shapeData: ShapeData[]) => {
    const resultX = shapeData.sort((firstShape, secondShape) => {
      const firstMinX = Math.min(
        firstShape.shape_data_points[0][0],
        firstShape.shape_data_points[1][0]
      )
      const secondMinX = Math.min(
        secondShape.shape_data_points[0][0],
        secondShape.shape_data_points[1][0]
      )
      return firstMinX - secondMinX
    })

    const resultY = shapeData.sort((firstShape, secondShape) => {
      const firstMinY = Math.min(
        firstShape.shape_data_points[0][1],
        firstShape.shape_data_points[2][1]
      )
      const secondMinY = Math.min(
        secondShape.shape_data_points[0][1],
        secondShape.shape_data_points[2][1]
      )
      return firstMinY - secondMinY
    })
    return [resultX, resultY]
  }

  const handleClick = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget)
    alignPoints(alignDistance)
  }

  const alignPoints = (alignDistance: number) => {
    for (let i = 0; i < shapeData.length; i++) {
      const otherShapes = shapeData.slice(i + 1)
      for (let j = 0; j < otherShapes.length; j++) {
        getCloseShapes(shapeData[i], otherShapes[j], alignDistance)
      }
    }
    closePoints.forEach(closePoint => getNewPoint(closePoint))
    updateShapeDatas(updateShapeDataList)
  }

  const getNewPoint = (points: ClosePoint) => {
    const point1 = Object.values(points)[0]
    const point2 = Object.values(points)[1]
    const newPoint: [number, number] = [
      Math.floor((point1[0] + point2[0]) / 2),
      Math.floor((point1[1] + point2[1]) / 2),
    ]
    const point1Id = Object.keys(points)[0]
    const point2Id = Object.keys(points)[1]
    const point1OriginalShapeData = shapeData.filter(
      shapeData => `${shapeData.overlay_id}` === point1Id
    )[0].shape_data_points

    const point2OriginalShapeData = shapeData.filter(
      shapeData => `${shapeData.overlay_id}` === point2Id
    )[0].shape_data_points

    updateRectangleShape(point1Id, point1OriginalShapeData, point1, newPoint)
    updateRectangleShape(point2Id, point2OriginalShapeData, point2, newPoint)
  }

  const updateRectangleShape = (
    overlay_id: string,
    rectangleOriginShapeData: Array<[number, number]>,
    oldPoint: [number, number],
    newPoint: [number, number]
  ) => {
    const index = rectangleOriginShapeData.indexOf(oldPoint)
    const newShapeData = [...rectangleOriginShapeData]
    if (index === 0) {
      newShapeData[0] = newPoint
      newShapeData[1][1] = newPoint[1]
      newShapeData[3][0] = newPoint[0]
    }
    if (index === 1) {
      newShapeData[0][1] = newPoint[1]
      newShapeData[1] = newPoint
      newShapeData[2][0] = newPoint[0]
    }
    if (index === 2) {
      newShapeData[1][0] = newPoint[0]
      newShapeData[2] = newPoint
      newShapeData[3][1] = newPoint[1]
    }
    if (index === 3) {
      newShapeData[0][0] = newPoint[0]
      newShapeData[2][1] = newPoint[1]
      newShapeData[3] = newPoint
    }
    updateShapeDataList.push({
      overlay_id,
      material_id: overlay_id,
      drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
      shape: 'rectangle',
      shape_data: JSON.stringify({ points: newShapeData.flat() }),
    })
  }

  const getPointDistance = (point1: [number, number], point2: [number, number]) => {
    const distance = Math.sqrt(
      Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2)
    )
    return distance
  }

  const getCloseShapes = (shape1: ShapeData, shape2: ShapeData, alignDistance: number) => {
    for (let i = 0; i < shape1.shape_data_points.length; i++) {
      for (let j = 0; j < shape2.shape_data_points.length; j++) {
        const distance = getPointDistance(shape1.shape_data_points[i], shape2.shape_data_points[j])
        if (distance < alignDistance && distance !== 0) {
          closePoints.push({
            [shape1.overlay_id]: shape1.shape_data_points[i],
            [shape2.overlay_id]: shape2.shape_data_points[j],
          })
        }
      }
    }
  }

  return (
    <div className={classes.toolWrapper}>
      <Button className={classes.tool} variant="outlined" onClick={e => handleClick(e)}>
        <DialpadIcon />
      </Button>
      <Popper
        open={showSnappingBar}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom-start"
      >
        <Paper>
          <div className={classes.popperSlider}>
            <Slider
              value={alignDistance}
              dots={true}
              step={10}
              min={0}
              max={100}
              onChange={v => onSliderChange(v)}
              marks={marks}
            />
          </div>
          <div>
            <Button
              onClick={() => {
                setAnchorEl(null)
              }}
              variant="outlined"
              className={classes.popperButton}
            >
              Close
            </Button>
          </div>
        </Paper>
      </Popper>
    </div>
  )
}

const mapStateToProps = (
  state: ReduxStore.State,
  {
    selectedDrawing,
    selectedMaterialType,
  }: { selectedDrawing: string; selectedMaterialType: string }
) => ({
  drawings: getDrawings(state),
  allMaterialsOnCurrentDrawing: getMaterialsOnCurrentDrawing(state, selectedDrawing),
  alignDistance: getAlignDistance(state),
  selectedMaterials: getSelectedMaterials(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  updateShapeDatas: (shapeDatas: ReduxStore.Materials.Data.IOverlay[]) =>
    dispatch(updateShapeDatas(shapeDatas)),
  dispatchUpdateAlignDistance: (alignDistance: number) =>
    dispatch(updateAlignDistance(alignDistance)),
})

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(withStyles(styles)(AutoAlignTool))
)
