import Button from '@material-ui/core/Button/Button'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus'
import Konva from 'konva'
// @ts-ignore
import MagicWand from 'magic-wand-tool/dist/magic-wand'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  addMaterials,
  confirmMatchedMaterials,
  deleteMatchedMaterials,
  deleteSelection,
} from 'store/materials/actions'
import { enqueueSnackbar } from 'store/notifier/actions'
import {
  getDescriptions,
  getDrawings,
  getImage,
  getMaterials,
  getMaterialTypePresets,
  getSelectedMaterials,
} from 'store/selectors'
import randomId from 'utils/randomId'
import { minMaxToPoints } from 'utils/shape'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

interface OwnProps {
  width: number
  height: number
  scale: { x: number; y: number }
  image: HTMLImageElement
  imageRef: Konva.Image
}

interface StateProps {
  drawings: ReduxStore.Drawings.Data.Drawing[]
  descriptions: string[]
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  selectedMaterials: Array<string | number>
  allMaterials: { [key: string]: ReduxStore.Materials.Data.IMaterial }
  classes: any
}

interface DispatchProps {
  addMaterials: (m: ReduxStore.Materials.Data.IMaterial[]) => void
  enqueueSnackbar: (notification: ReduxStore.Notifications.Notification) => void
  confirmMatchedMaterials: () => void
  deleteMatchedMaterials: () => void
  dispatchDeleteSelectedShapes: (selection: number[]) => void
}

interface ImageInfo {
  width: number
  height: number
  data: ImageData
}

type Props = OwnProps & StateProps & DispatchProps & DataloaderInfo

const styles = (theme: Theme) => ({
  toolWrapper: {
    width: '50%',
    boxSizing: 'border-box',
    padding: '8px',
  },
  popper: {
    zIndex: 10000,
  },
  tool: {
    width: '100%',
  },
})

export const ToolName = 'outliner'

// Based on Magic Wand tool implementation https://github.com/Tamersoul/magic-wand-js
const AutoFillCenterPoint: React.FunctionComponent<Props> = props => {
  const [imageInfo, setImageInfo] = useState<ImageInfo | undefined>(undefined)
  const [originalShapes, setOriginalShapes] = useState<number[]>([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const {
    image,
    addMaterials,
    selectedDrawing,
    selectedProject,
    selectedMaterialType,
    drawings,
    descriptions,
    selectedMaterials,
    enqueueSnackbar,
    allMaterials,
    classes,
    presets,
  } = props

  useEffect(() => {
    if (image) {
      const tempCtx = document.createElement('canvas').getContext('2d')
      if (tempCtx) {
        tempCtx.canvas.width = image.width
        tempCtx.canvas.height = image.height
        tempCtx.drawImage(image, 0, 0)
        setImageInfo({
          width: image.width,
          height: image.height,
          data: tempCtx.getImageData(0, 0, image.width, image.height),
        })
      }
    }
  }, [image])

  const handleClick = (evt: any) => {
    evt.preventDefault()
    if (selectedMaterials) {
      const selectedMaterialsOverlay = Object.values(allMaterials).filter(material => {
        return selectedMaterials.includes(material.overlay_id)
      })
      const allOverlays = selectedMaterialsOverlay.map(material => {
        return JSON.parse(material.shape_data).points
      })

      const centeralPoints = allOverlays.map((overlay: any) => {
        const centerPointX = (overlay[2] + overlay[0]) / 2
        const centerPointY = (overlay[1] + overlay[5]) / 2
        return { x: Math.round(centerPointX), y: Math.round(centerPointY) }
      })
      const extractionOverrides = {
        max_width: 800,
        min_width: 30,
        max_height: 800,
        min_height: 30,
      }
      const results: ReduxStore.Materials.Data.IPoints[] = []
      const matchedShape: Array<number | string> = []
      centeralPoints.forEach((point: any, i: number) => {
        const result = generateContourPoints(point)
        if (result) {
          // @ts-ignore
          const isResultOverSize =
            Math.abs(result[2] - result[0]) > extractionOverrides.max_width ||
            Math.abs(result[2] - result[0]) < extractionOverrides.min_width ||
            Math.abs(result[5] - result[1]) > extractionOverrides.max_height ||
            Math.abs(result[5] - result[1]) < extractionOverrides.min_width

          if (!isResultOverSize) {
            matchedShape.push(selectedMaterialsOverlay[i].material_id)
            results.push(result)
          }
        }
      })
      if (results.length !== 0) {
        // @ts-ignore
        setOriginalShapes(matchedShape)
        const matchShapeDataList = results.map((overlay: any) => {
          const materialShape = 'rectangle'
          const newId = randomId()
          const points = overlay
          const stringShapeData = '{"points":' + JSON.stringify(points) + '}'
          const value = descriptions.reduce((acc: { [key: string]: string }, name) => {
            if (name === 'Drawing Name' && selectedDrawing) {
              acc[name] = selectedDrawing
            } else {
              // Check if there is a preset set for this.
              const preset = presets.find(preset => preset.description === name)
              if (preset) {
                acc[name] = preset.value
              } else {
                acc[name] = ''
              }
            }
            return acc
          }, {})
          let matchShape = 'matchRectangle'
          if (materialShape === 'rectangle') {
            matchShape = 'matchRectangle'
          }

          const mockData: ReduxStore.Materials.Data.IMaterial = {
            overlay_id: newId,
            material_id: newId,
            drawing_id: drawings.filter(drawing => drawing.displayName === selectedDrawing)[0].id,
            display_name: selectedDrawing as string,
            shape: matchShape,
            shape_data: stringShapeData,
            company_project_id: selectedProject as number,
            material_type_id: selectedMaterialType as number,
            value,
            isDeleted: false,
            matchSource: 'ML',
          }
          return mockData
        })
        addMaterials(matchShapeDataList)
        setAnchorEl(evt.currentTarget)
      } else {
        enqueueSnackbar({
          message: 'Cannot create outline here with current shape.',
          options: {
            preventDuplicate: true,
            autoHideDuration: 5000,
            resumeHideDuration: 100,
          },
        })
      }
    } else {
      enqueueSnackbar({
        message: 'Please select materials to outline.',
        options: {
          preventDuplicate: true,
          autoHideDuration: 5000,
          resumeHideDuration: 100,
        },
      })
    }
  }

  const findMinMaxDimensions = (points: any) => {
    const MAX_COORD = 65535

    return points.reduce(
      (acc: ReduxStore.Materials.Data.IDimensionsMinMax, point: { x: number; y: number }) => {
        acc.minX = Math.min(point.x, acc.minX)
        acc.minY = Math.min(point.y, acc.minY)
        acc.maxX = Math.max(point.x, acc.maxX)
        acc.maxY = Math.max(point.y, acc.maxY)
        return acc
      },
      { minX: MAX_COORD, minY: MAX_COORD, maxX: -1, maxY: -1 }
    )
  }

  const generateContourPoints = (point: any) => {
    if (!imageInfo) {
      return
    }
    const img = {
      // @ts-ignore
      data: imageInfo.data.data,
      // @ts-ignore
      width: imageInfo.width,
      // @ts-ignore
      height: imageInfo.height,
      bytes: 4,
    }
    const COLOUR_THRESHOLD = 27
    const BLUR_RADIUS = 5
    const CONTOUR_TOLERANCE = 0
    const MIN_VERTICAL = 3

    const msk = MagicWand.floodFill(img, point.x, point.y, COLOUR_THRESHOLD, null, true)
    const mask = MagicWand.gaussBlurOnlyBorder(msk, BLUR_RADIUS)
    let contours = MagicWand.traceContours(mask)
    if (contours && contours.length) {
      contours = MagicWand.simplifyContours([contours[0]], CONTOUR_TOLERANCE, MIN_VERTICAL)
      const bBox = findMinMaxDimensions(contours[0].points)
      return minMaxToPoints(bBox)
    }
  }

  const confirmAllMatches = () => {
    const { confirmMatchedMaterials, dispatchDeleteSelectedShapes } = props
    confirmMatchedMaterials()
    dispatchDeleteSelectedShapes(originalShapes)
    setAnchorEl(null)
  }

  const deleteAllMatches = () => {
    const { deleteMatchedMaterials } = props
    deleteMatchedMaterials()
    setAnchorEl(null)
  }

  return (
    <div className={classes.toolWrapper}>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom"
      >
        <Paper className={classes.popperPaper}>
          <div>
            <Button onClick={deleteAllMatches} variant="outlined" className={classes.popperButton}>
              Cancel
            </Button>
            <Button onClick={confirmAllMatches} variant="outlined" className={classes.popperButton}>
              Confirm
            </Button>
          </div>
        </Paper>
      </Popper>
      <Button className={classes.tool} variant="outlined" onClick={e => handleClick(e)}>
        <FilterCenterFocusIcon />
      </Button>
    </div>
  )
}

const mapStateToProps = (
  state: ReduxStore.State,
  { selectedMaterialType }: { selectedMaterialType: string }
) => ({
  drawings: getDrawings(state),
  descriptions: getDescriptions(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  selectedMaterials: getSelectedMaterials(state),
  allMaterials: getMaterials(state),
  image: getImage(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  addMaterials: (m: ReduxStore.Materials.Data.IMaterial[]) => dispatch(addMaterials(m)),
  enqueueSnackbar: (notification: ReduxStore.Notifications.Notification) =>
    dispatch(enqueueSnackbar(notification)),
  confirmMatchedMaterials: (m: ReduxStore.Materials.ConfirmMatchedMaterials) =>
    dispatch(confirmMatchedMaterials()),
  dispatchDeleteSelectedShapes: (selection: number[]) => dispatch(deleteSelection(selection)),
  deleteMatchedMaterials: () => dispatch(deleteMatchedMaterials()),
})

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(withStyles(styles)(AutoFillCenterPoint))
)
