import CONST from 'const'

export function failedStatus(error: any) {
  return {
    status: CONST.STATUS_FAILED,
    error,
  }
}

export function completeStatus() {
  const status = CONST.STATUS_COMPLETED
  return { status }
}

export function isFailedStatus(status: any) {
  return status && status.status === CONST.STATUS_FAILED
}
