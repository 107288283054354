import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import { getToolOptions } from 'store/selectors'
import { ToolName as OutlinerToolName } from './OutlinerTool'

interface StateProps {
  toolOptions: ReduxStore.Tool.OutlinerToolOptions
}

interface DispatchProps {
  updateOptions: (options: ReduxStore.Tool.OutlinerToolOptions) => void
}

type Props = StateProps & DispatchProps

const OutlinerToolOptions: React.FC<Props> = props => {
  const { toolOptions, updateOptions } = props

  const handleChangeThreshold = (e: any, value: any) => {
    updateOptions({ ...toolOptions, colourThreshold: value })
  }

  const handleChangeBlurRadius = (e: any, value: any) => {
    updateOptions({ ...toolOptions, blurRadius: value })
  }

  const handleChangeContourTolerance = (e: any, value: any) => {
    updateOptions({ ...toolOptions, contourTolerance: value })
  }

  const handleChangeBoundingBox = (e: any, value: any) => {
    updateOptions({ ...toolOptions, useBoundingBox: value })
  }

  return (
    <div>
      <Typography variant="caption">Colour threshold</Typography>
      <Slider
        value={toolOptions.colourThreshold}
        step={1}
        valueLabelDisplay="auto"
        max={70}
        min={0}
        onChange={handleChangeThreshold}
      />
      <Typography variant="caption">Blur radius</Typography>
      <Slider
        value={toolOptions.blurRadius}
        step={1}
        valueLabelDisplay="auto"
        max={20}
        min={1}
        onChange={handleChangeBlurRadius}
      />
      <Typography variant="caption">Contour tolerance</Typography>
      <Slider
        value={toolOptions.contourTolerance}
        step={1}
        valueLabelDisplay="auto"
        max={20}
        min={0}
        onChange={handleChangeContourTolerance}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={toolOptions.useBoundingBox}
            onChange={handleChangeBoundingBox}
            name="useBbox"
          />
        }
        label="Use bounding box"
      />
    </div>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  toolOptions: getToolOptions(state, OutlinerToolName),
})

const mapDispatchToProps = (dispatch: any) => ({
  updateOptions: (options: ReduxStore.Tool.OutlinerToolOptions) => {
    dispatch(action.setToolOptions(OutlinerToolName, options))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutlinerToolOptions)
