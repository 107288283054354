import { merge, omit } from 'lodash'

const initState: ReduxStore.Auth.State = {}

function auth(state: ReduxStore.Auth.State = initState, action: ReduxStore.Auth.Action) {
  switch (action.type) {
    case 'SET_SESSION':
      window.localStorage.setItem('session', JSON.stringify(action.payload))
      return merge({}, state, {
        session: action.payload,
      })

    case 'CLEAR_SESSION':
      window.localStorage.removeItem('session')
      return omit(state, 'session')

    case 'SET_LOGIN_STATUS':
      return merge({}, state, {
        loginStatus: action.payload,
      })

    case 'SET_USER_DETAILS':
      return { ...state, userDetails: action.payload }

    default:
      return state
  }
}

export default auth
