import CONST from 'const'
import Konva from 'konva'
import { cloneDeep } from 'lodash'
import React from 'react'
import { Rect } from 'react-konva'

interface OwnProps {
  selectedMaterials: Array<number | string>
  material: ReduxStore.Materials.Data.IMaterial
  onMouseDown: (
    e: Konva.KonvaEventObject<MouseEvent>,
    m: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragStart: (id: number | string) => void
  onDragMove: (
    e: Konva.KonvaEventObject<MouseEvent>,
    m: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragEnd: (e: Konva.KonvaEventObject<MouseEvent>, m: ReduxStore.Materials.Data.IMaterial) => void
}

const Rectangle: React.FunctionComponent<OwnProps> = props => {
  const { selectedMaterials, material, onMouseDown, onDragStart, onDragMove, onDragEnd } = props
  const { overlay_id, shape_data } = material
  const points: ReduxStore.Materials.Data.IPoints = JSON.parse(shape_data).points
  const dimensions: ReduxStore.Materials.Data.IDimensions = {
    x: points[0],
    y: points[1],
    width: points[4] - points[0],
    height: points[5] - points[1],
  }
  const selected = selectedMaterials.some(id => id === overlay_id)
  const color = selected ? CONST.OVERLAY_YELLOW : CONST.OVERLAY_BLUE

  return (
    // @ts-ignore
    <Rect
      ref={instance => {
        if (instance) {
          window.shapeRefs.push(instance)
          instance.scaleX(1)
          instance.scaleY(1)
        }
      }}
      draggable={selected}
      shapeId={overlay_id}
      name={overlay_id.toString()}
      materialBelongsTo={overlay_id}
      shape="rect"
      fill={color}
      onMouseDown={evt => onMouseDown(evt, material)}
      onDragStart={() => onDragStart(overlay_id)}
      onDragMove={evt => onDragMove(evt, material)}
      onDragEnd={evt => onDragEnd(evt, cloneDeep(material))}
      {...dimensions}
    />
  )
}

export default Rectangle
