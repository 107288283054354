import { cloneDeep } from 'lodash'
import incrementSuffix from 'utils/incrementor'

const initState: ReduxStore.MaterialPresets.State = {
  autoIncrement: false,
  list: [],
}

export default function MaterialPresetsReducer(
  state: ReduxStore.MaterialPresets.State = initState,
  action: ReduxStore.MaterialPresets.Action
) {
  switch (action.type) {
    case 'ADD_MATERIAL_PRESETS':
      const list = cloneDeep<ReduxStore.MaterialPresets.MaterialPreset[]>(state.list)
      for (const preset of action.payload) {
        const existingPreset = list.find(
          existingPreset =>
            existingPreset.materialTypeId === preset.materialTypeId &&
            existingPreset.description === preset.description
        )
        if (existingPreset) {
          existingPreset.value = preset.value
          existingPreset.increment = preset.increment
        } else {
          // Add new material preset
          list.push({ ...preset })
        }
      }
      return {
        ...state,
        list,
      }

    case 'DELETE_MATERIAL_PRESETS': {
      let list = cloneDeep<ReduxStore.MaterialPresets.MaterialPreset[]>(state.list)
      for (const preset of action.payload) {
        list = list.filter(
          existingPreset =>
            !(
              existingPreset.materialTypeId === preset.materialTypeId &&
              existingPreset.description === preset.description
            )
        )
      }
      return {
        ...state,
        list,
      }
    }

    case 'INCREMENT_MATERIAL_PRESETS': {
      const list = state.list.map(preset => {
        return preset.materialTypeId === action.materialTypeId && preset.increment
          ? { ...preset, value: incrementSuffix(preset.value, action.increment) }
          : { ...preset }
      })
      return {
        ...state,
        list,
      }
    }

    case 'SET_AUTO_INCREMENT': {
      return {
        ...state,
        autoIncrement: action.autoIncrement,
      }
    }

    case 'RESET_MATERIAL_PRESETS': {
      return initState
    }

    default:
      return state
  }
}
