import { createStyles, WithStyles } from '@material-ui/core'
import Icon from '@material-ui/core/Icon/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField/TextField'
import React, { useState } from 'react'
import classNames from 'utils/classNames'
import SelectorMenu from './SelectorMenu'

const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  arrow: {
    width: '0px',
    marginRight: '-4px',
    marginLeft: '4px',
  },
})

interface Props extends WithStyles<typeof styles> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  label: string
  value: string
  children: React.ReactNode
}

const SelectorInput: React.FC<Props> = props => {
  // True when the input is focussed and therefore the menu should be open
  const [focussed, setFocussed] = useState<boolean>(false)
  // True when the user is clicking on the open menu. This is to ensure that the menu remains
  // open for the duration of the click so all the correct events are fired.
  const [clickingMenu, setClickingMenu] = useState<boolean>(false)

  const { children, onChange, className, classes, label, value } = props

  const getMenuShouldDisplay = () => {
    return children && (focussed || clickingMenu)
  }

  const handleFocus = () => {
    setFocussed(true)
  }

  const handleBlur = () => {
    setFocussed(false)
  }

  const handleMenuInteraction = (inProgress: boolean) => {
    setClickingMenu(inProgress)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }
  }

  const selectorMenu = () => {
    return getMenuShouldDisplay() ? (
      <SelectorMenu
        id={`${label.toLowerCase()}-selector-menu`}
        onMenuInteraction={handleMenuInteraction}
      >
        {children}
      </SelectorMenu>
    ) : null
  }

  return (
    <React.Fragment>
      <div className={classNames(className, classes.root)}>
        <TextField
          className={classes.input}
          label={label}
          value={value}
          margin="normal"
          variant="outlined"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {getMenuShouldDisplay() && <Icon>chevron_right</Icon>}
      </div>
      {selectorMenu()}
    </React.Fragment>
  )
}

export default withStyles(styles)(SelectorInput)
