import Icon from '@material-ui/core/Icon/Icon'
import React from 'react'
import OutlinerTool from './OutlinerTool'
import { ToolName as OutlinerToolname } from './OutlinerTool'
import OutlinerToolOptions from './OutlinerToolOptions'
import PolygonTool from './PolygonTool'
import RectangleTool from './RectangleTool'

const tools = {
  rectangle: {
    icon: <Icon>check_box_outline_blank</Icon>,
    component: RectangleTool,
    canvasStyles: {
      cursor: 'crosshair',
    },
    tooltip: 'Rectangle',
  },
  polygon: {
    icon: <Icon>change_history</Icon>,
    component: PolygonTool,
    canvasStyles: {
      cursor: 'crosshair',
    },
    tooltip: 'Polygon',
  },
  [OutlinerToolname]: {
    icon: <Icon>rounded_corner_outlined</Icon>,
    component: OutlinerTool,
    canvasStyles: {
      cursor: 'crosshair',
    },
    tooltip: 'Auto outliner',
    options: OutlinerToolOptions,
  },
}

export default tools
