import { cloneDeep, isEmpty } from 'lodash'
import { jsonParse } from 'utils/json'
import { pointsToDimensions } from 'utils/shape'

function range(start: number, end: number): number[] {
  const result = []
  for (let i = 0; i < end - start + 1; i++) {
    result.push(start + i)
  }
  return result
}

export function getHotColumns(
  descriptions: string[],
  descriptionTypes: string[],
  listIds: number[],
  listIdValueMap: { [key in string]: string[] }
) {
  const descriptionsForHOT = descriptions.map((des, index) => {
    const type = descriptionTypes[index]
    const listId = listIds[index]
    const listValue = listIdValueMap[listId.toString()]
    switch (type) {
      case 'date':
        return {
          data: des,
          type: 'date',
          dateFormat: 'DD-MM-YYYY',
          correctFormat: true,
        }

      case 'time':
        return {
          data: des,
          type: 'time',
          timeFormat: 'h:mm:ss a',
          correctFormat: true,
        }

      case 'list':
      case 'button-list':
        return {
          data: des,
          type: 'dropdown',
          source: listValue,
        }

      default:
        return {
          data: des,
        }
    }
  })
  descriptionsForHOT.push({
    data: 'overlay_id',
  })
  descriptionsForHOT.push({
    data: 'shape_data',
  })
  return descriptionsForHOT
}

export function getHotData(materials: ReduxStore.Materials.Data.IMaterial[]) {
  if (materials.length === 0) {
    return []
  }

  const hotData: { [key in number | string]: any } = {}

  cloneDeep(materials).forEach(material => {
    const { material_id, overlay_id, value, shape, shape_data } = material
    const idDes = value.ID
    value.overlay_id = [overlay_id]
    value.id = material_id.toString()

    let hot_shape_data: {
      points: ReduxStore.Materials.Data.IPoints | ReduxStore.Materials.Data.IDimensions
    }

    const shapeData = jsonParse(shape_data)

    if (!shapeData) {
      return
    }

    if (shape === 'rectangle' || shape === 'matchRectangle') {
      hot_shape_data = { points: pointsToDimensions(shapeData.points) }
    } else {
      hot_shape_data = shapeData
    }
    value.shape_data = {
      [overlay_id]: hot_shape_data,
    }

    if (hotData[material_id] !== undefined) {
      const existingOverlayId = hotData[material_id].overlay_id
      hotData[material_id].overlay_id = [...existingOverlayId, overlay_id]
      hotData[material_id].shape_data[overlay_id] = hot_shape_data
      return
    }

    if (idDes !== '' && hotData[idDes] !== undefined) {
      const existingOverlayId = cloneDeep(hotData[idDes].overlay_id)
      hotData[idDes].overlay_id = [...existingOverlayId, overlay_id]
      hotData[idDes].shape_data[overlay_id] = hot_shape_data
      return
    }

    if (idDes === '') {
      hotData[material_id] = cloneDeep(value)
      return
    }

    if (idDes !== '' && hotData[idDes] === undefined) {
      hotData[idDes] = cloneDeep(value)
      return
    }
  })
  const result = Object.values(hotData).map((rowData: ReduxStore.Materials.Data.RowInHOT) => {
    const copy = cloneDeep(rowData)
    copy.shape_data = JSON.stringify(copy.shape_data).replace(/[\\]+/g, '')
    return copy
  })
  return result
}

export function getHotSelectedRows(selectedLayers: Array<[number, number, number, number]>) {
  let selectedRows: number[] = []

  selectedLayers.forEach(selectionLayer => {
    const min = Math.min(selectionLayer[0], selectionLayer[2])
    const max = Math.max(selectionLayer[0], selectionLayer[2])
    selectedRows = selectedRows.concat(range(min, max))
  })

  return [...new Set(selectedRows)]
}

export function getHOTSelectedColumns(selectedCells: Array<[number, number, number, number]>) {
  if (!selectedCells) {
    return [1]
  }
  let selectedCols: number[] = []

  selectedCells.forEach(selectionLayer => {
    const min = Math.min(selectionLayer[1], selectionLayer[3])
    const max = Math.max(selectionLayer[1], selectionLayer[3])
    selectedCols = selectedCols.concat(range(min, max))
  })

  return [...new Set(selectedCols)]
}

export const getCellsTobeSelected = (function tempFunc() {
  let result: number[][] = []
  return (newRows: number[], newCols: number[]) => {
    const tempCols = isEmpty(newCols) ? [1] : [...newCols]
    newRows.sort((a, b) => a - b)
    tempCols.sort((a, b) => a - b)
    result = []
    let lastCol = tempCols[0]
    let lastRow = newRows[0]
    let temp = []
    newRows.forEach(row => {
      if (row - lastRow === 1) {
        result.map(item => {
          if (item[2] === lastRow) {
            item[2] = row
          }
          return item
        })
        lastRow = row
        return
      }
      tempCols.forEach(col => {
        if (col - lastCol === 1) {
          ;[temp] = result.slice(-1) // get the last element from result
          temp[3] = col
          result[result.length - 1] = temp
        } else {
          result.push([row, col, row, col])
        }
        lastCol = col
      })
      lastRow = row
    })
    return result
  }
})()
