import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography/Typography'
import React, { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { connect } from 'react-redux'
import 'react-resizable/css/styles.css'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import CenteredSpinner from '../../shared/CenteredSpinner'
import action from '../../store/actions'
import Floorplan from './floorplan/Floorplan'
import MaterialTable from './floorplan/MaterialTable'
import MaterialTableWithoutOverlays from './floorplan/MaterialTableWithoutOverlays'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const styles = createStyles({
  children: {
    width: '100%',
  },
  placeholder: {
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexGrow: 1,
    // Set the width to 1px to force it to grow to fill the window
    width: '1px',
  },
  draftContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '40px',
    fontWeight: 'bold',
  },
})

interface DispatchProps {
  dispatchUpdateFloorplanAndTableCanvasDimensions: (status: number) => void
}

interface StateProps {
  draft: ReduxStore.Draft.State
}

interface WindowDimensions {
  width: number
  height: number
}

type Props = WithStyles<typeof styles> & DataloaderInfo & DispatchProps & StateProps

const FloorplanViewer: React.FC<Props> = props => {
  const {
    classes,
    selectedProject,
    selectedDrawing,
    selectedMaterialType,
    dispatchUpdateFloorplanAndTableCanvasDimensions,
    draft,
  } = props
  const { draftData, isEnabled, isPending, status } = draft
  const [floorPlanSectionRatio, setFloorPlanSectionRatio] = useState<number>(0)
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    dispatchUpdateFloorplanAndTableCanvasDimensions(floorPlanSectionRatio)
  }, [floorPlanSectionRatio])

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)
  }, [])

  const placeholder = (message: string) => {
    return (
      <div className={classes.placeholder}>
        <Typography variant="h4">{message}</Typography>
      </div>
    )
  }

  const updateWindowDimensions = () => {
    setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
  }

  const floorplan = () => {
    if (selectedDrawing === 'BLANK_DRAWING') {
      return <div>You are checking materials which has no overlays</div>
    }
    return selectedProject && selectedDrawing ? <Floorplan /> : placeholder('Select a drawing')
  }

  const onResizeStop = (layout: any) => {
    const floorplanSectionHeightRatio = layout[0].h / window.innerHeight
    setFloorPlanSectionRatio(floorplanSectionHeightRatio)
  }

  const renderFloorplanAndTable = () => {
    // This is the react-grid-layout formula to calculate div height: h * rowHeight + (h - 1) * margin
    let setionsRatio = { floorplan: 0.75, recordtable: 0.25 }
    if (floorPlanSectionRatio > 0) {
      setionsRatio = { floorplan: floorPlanSectionRatio, recordtable: 1 - floorPlanSectionRatio }
    }
    const layout = {
      lg: [
        {
          i: 'floorplan',
          x: 0,
          y: 0,
          w: 12,
          h: windowDimensions.height * setionsRatio.floorplan,
          isDraggable: false, // Only allow floorplan section resize
        },
        {
          i: 'recordtable',
          x: 1,
          y: 1,
          w: 12,
          h: windowDimensions.height * setionsRatio.recordtable - 3,
        },
      ],
    }
    return (
      <ResponsiveReactGridLayout
        className="layout"
        layouts={layout}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        rowHeight={1}
        margin={[0, 0]}
        verticalCompact={true}
        isDraggable={false}
        onResizeStop={onResizeStop}
      >
        <div key="floorplan" className={classes.children}>
          {floorplan()}
        </div>
        <div key="recordtable" className={classes.children}>
          {materialTable()}
        </div>
      </ResponsiveReactGridLayout>
    )
  }

  const materialTable = () => {
    if (selectedDrawing === 'BLANK_DRAWING') {
      return <MaterialTableWithoutOverlays selectedMaterialType={selectedMaterialType} />
    }
    return selectedProject && selectedDrawing && selectedMaterialType ? (
      <MaterialTable />
    ) : (
      placeholder('Select a material type')
    )
  }

  if (status === 'CREATE_DRAFT' || status === 'PUBLISH_DRAFT') {
    return (
      <div className={classes.draftContainer}>
        <CenteredSpinner />
      </div>
    )
  }
  return <div className={classes.root}>{renderFloorplanAndTable()}</div>
}

const mapDispatchToProps = {
  dispatchUpdateFloorplanAndTableCanvasDimensions:
    action.dispatchUpdateFloorplanAndTableCanvasDimensions,
}

const mapStateToProps = (state: ReduxStore.State) => ({
  draft: state.draft,
})

export default withStyles(styles)(
  withSelectedValues(
    // @ts-ignore
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(FloorplanViewer)
  )
)
