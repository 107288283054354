const initState: ReduxStore.Projects.State = {
  isFetching: false,
  list: [],
}

export default function projectsReducer(
  state: ReduxStore.Projects.State = initState,
  action: ReduxStore.Projects.Action
) {
  switch (action.type) {
    case 'REQUEST_PROJECTS':
      return {
        ...state,
        isFetching: true,
      }

    case 'RECEIVE_PROJECTS': {
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      }
    }

    default:
      return state
  }
}
