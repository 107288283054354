import tools from 'dataloader/tools/tools'
import Konva from 'konva'
import React from 'react'
import { connect } from 'react-redux'
import { getTool } from 'store/selectors'

interface StateProps {
  tool: string
}

interface Props extends StateProps {
  width: number
  height: number
  scale: { x: number; y: number }
  image: HTMLImageElement
  imageRef: Konva.Image | null
}

class ToolOverlay extends React.PureComponent<Props> {
  render() {
    const { tool, width, height, scale, image, imageRef } = this.props
    if (tool) {
      // @ts-ignore
      const { component: Component } = tools[tool]
      return (
        <Component scale={scale} width={width} height={height} image={image} imageRef={imageRef} />
      )
    }
    return null
  }
}

const mapStateToProps = (state: ReduxStore.State) => ({
  tool: getTool(state),
})

export default connect(mapStateToProps)(ToolOverlay)
