import React from 'react';
import { connect } from 'react-redux';
import action from 'store/actions';
import SplashScreen from '../shared/SplashScreen';

class HydrateSession extends React.Component {
  state = {
    hydrating: true,
  };

  componentDidMount() {
    this.hydrateSession();
  }

  async hydrateSession() {
    const { dispatchHydrateSession } = this.props;
    await dispatchHydrateSession();
    this.setState({ hydrating: false });
  }

  render() {
    const { children } = this.props;
    const { hydrating } = this.state;
    return hydrating ? <SplashScreen /> : children;
  }
}

const mapDispatchToProps = {
  dispatchHydrateSession: action.hydrateSession,
};

export default connect(null, mapDispatchToProps)(HydrateSession);
