import { applyMiddleware, compose, createStore, Store } from 'redux'
import thunk from 'redux-thunk'
import reducer from 'store/reducers'

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store: Store<ReduxStore.State, any> = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
)

export default store
