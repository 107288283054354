import { ThunkAction } from 'redux-thunk'
import { API } from '../../api'
import { getProjectsFetchingStatus } from '../selectors'

export const requestProjects = (): ReduxStore.Projects.Action => {
  return {
    type: 'REQUEST_PROJECTS',
  }
}

export const receiveProjects = (
  projects: ReduxStore.Projects.Data.Project[]
): ReduxStore.Projects.Action => {
  return {
    type: 'RECEIVE_PROJECTS',
    payload: projects,
  }
}

interface Access {
  access: number
  company_name: string
  company_project_id: number
  project_id: number
  project_name: string
  fk_draft_project_id: number
}

export const fetchProjects = (): ThunkAction<
  void,
  ReduxStore.State,
  null,
  ReduxStore.Projects.Action
> => {
  return async (dispatch, getState) => {
    const state = getState()
    const isFetching = getProjectsFetchingStatus(state)
    if (isFetching) {
      return
    }
    dispatch(requestProjects())

    const {
      body: { access },
    } = await API.getUserInfo()

    const draftProjectMap: Array<{ draftId: number; sourceId: number }> = access
      .filter((item: Access) => item.fk_draft_project_id !== null)
      .map((item: Access) => ({ draftId: item.fk_draft_project_id, sourceId: item.project_id }))

    const projects = access.map((item: Access) => {
      const {
        company_project_id,
        company_name,
        project_name,
        project_id,
        fk_draft_project_id,
      } = item
      const isDraft = draftProjectMap.find(({ draftId }) => draftId === project_id)
      const sourceProjectId = isDraft ? isDraft.sourceId : null
      return {
        id: company_project_id,
        companyProjectId: company_project_id,
        companyName: company_name,
        projectId: project_id,
        projectName: project_name,
        draftProjectId: fk_draft_project_id,
        sourceProjectId,
        isDraft: !!isDraft,
      }
    })
    dispatch(receiveProjects(projects))
  }
}
