const initState: ReduxStore.Notifications.State = {
  notifications: [],
}

export default function(
  state: ReduxStore.Notifications.State = initState,
  action: ReduxStore.Notifications.Action
) {
  switch (action.type) {
    case 'ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      }

    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      }

    case 'REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
      }

    default:
      return state
  }
}
