import { createStyles, makeStyles, WithStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import BorderClearIcon from '@material-ui/icons/BorderClear'
import { API } from 'api'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import { getSnappingDistance, getSnappingEnabled } from 'store/selectors'
import withSelectedValues from 'utils/withSelectedValues'

const useSnappingToolStyles = makeStyles(() =>
  createStyles({
    toolWrapper: {
      width: '50%',
      boxSizing: 'border-box',
      padding: '8px',
    },
    tool: {
      width: '100%',
    },
    popperPaper: {
      padding: '10px',
    },
    popperButton: {
      margin: '5px',
    },
    popper: {
      zIndex: 2001,
      width: '300px',
      height: '50px',
      background: '#FFFFFF',
      padding: '5px 10px',
    },
  })
)

interface Props {
  dispatchUpdateSnappingDistance: (snappingDistance: number) => void
  snappingDistance: number
  snappingEnabled: boolean
}

const SnappingTool = (props: Props) => {
  const { dispatchUpdateSnappingDistance, snappingDistance, snappingEnabled } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const showSnappingBar = Boolean(anchorEl)
  const buttonRef = React.createRef<HTMLElement>()
  const classes = useSnappingToolStyles()
  const onSliderChange = (value: number) => {
    dispatchUpdateSnappingDistance(value)
  }
  const marks = {
    0: '0',
    100: '100',
    200: '200',
    300: '300',
    400: '400',
    500: '500',
    600: '600',
    700: '700',
    800: '800',
    900: '900',
    1000: 'max',
  }
  return (
    <div className={classes.toolWrapper}>
      <Button
        variant="outlined"
        className={classes.tool}
        buttonRef={buttonRef}
        onClick={e => {
          setAnchorEl(anchorEl ? null : e.currentTarget)
        }}
        disabled={!snappingEnabled}
      >
        <BorderClearIcon />
      </Button>
      <Popper
        open={showSnappingBar}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom-start"
      >
        <Paper elevation={200}>
          <Slider
            value={snappingDistance}
            dots={true}
            step={100}
            min={0}
            max={1000}
            onChange={v => onSliderChange(v)}
            marks={marks}
          />
        </Paper>
      </Popper>
    </div>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  snappingDistance: getSnappingDistance(state),
  snappingEnabled: getSnappingEnabled(state),
})
const mapDispatchToProps = {
  dispatchUpdateSnappingDistance: action.updateSnappingDistance,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(SnappingTool)
)
