import { HotTable } from '@handsontable/react'
import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Handsontable from 'handsontable'
import { range, zipObject } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import {
  getDescriptions,
  getDescriptionTypes,
  getListIds,
  getListIdValueMap,
  getMaterialsWithoutOverlays,
  getMaterialTypePresets,
} from 'store/selectors'
import classNames from 'utils/classNames'
import { getHotColumns } from 'utils/hotTable'

const styles = createStyles({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
})

interface StateProps {
  materialsWithoutOverlays: ReduxStore.Materials.Data.IMaterialWithoutOverlays[]
  descriptions: string[]
  selectedMaterialType: any
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
  descriptionTypes: string[]
  listIds: number[]
  listIdValueMap: { [key in string]: string[] }
}

interface DispatchProps {
  dispatchDeleteSelectedRecordsByIds: (selection: Array<string | number>) => void
  updateListWithoutOverlaysDes: any
}

interface OwnProps {
  className?: string
}

type Props = StateProps & WithStyles<typeof styles> & OwnProps & DispatchProps

const MaterialTableWithoutOverlays: React.FC<Props> = props => {
  const tableRef = React.createRef<HotTable>()
  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([])
  const {
    className,
    classes,
    materialsWithoutOverlays,
    dispatchDeleteSelectedRecordsByIds,
    descriptions,
    descriptionTypes,
    listIds,
    listIdValueMap,
    updateListWithoutOverlaysDes,
  } = props

  function getRecordsData() {
    return materialsWithoutOverlays.map(m => {
      const { material_id, value } = m
      value.id = material_id.toString()
      return value
    })
  }

  function emptyIdRenderer(
    this: Handsontable,
    instance: any,
    td: any,
    row: any,
    col: any,
    prop: any,
    value: any,
    cellProperties: any
  ) {
    if (col === 0 && value === '') {
      td.style.background = 'salmon'
    }
    Handsontable.renderers.TextRenderer.apply(this, [
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties,
    ])
  }

  const [data, setData] = useState(getRecordsData())

  useEffect(() => {
    setData(getRecordsData())
  }, [materialsWithoutOverlays])

  const columns = getHotColumns(descriptions, descriptionTypes, listIds, listIdValueMap)

  const deleteRowsFromHOT = (key: any, selection: any) => {
    const newData = data.filter(record => selectedMaterialIds.indexOf(parseInt(record.id, 10)) < 0)
    setData(newData)
    dispatchDeleteSelectedRecordsByIds(selectedMaterialIds)
  }

  const handleSelect = () => {
    if (tableRef.current) {
      const selectedCells = tableRef.current.hotInstance.getSelected()
      if (selectedCells) {
        const selectedRows = selectedCells.reduce((acc: number[], item) => {
          const startRow = item[0]
          const endRow = item[2]
          if (startRow === endRow) {
            acc.push(startRow)
          } else {
            acc = acc.concat(range(startRow, endRow + 1))
          }
          return acc
        }, [])
        setSelectedMaterialIds(
          selectedRows.map(rowIndex => {
            const material = materialsWithoutOverlays[rowIndex]
            return material.material_id
          })
        )
      }
    }
  }

  const getSortedSourceData = (): any[] | undefined => {
    if (tableRef.current) {
      const { getData, getColHeader } = tableRef.current.hotInstance
      const colHeader = getColHeader()
      const hotData = getData()
      return hotData.map(dataInRow => {
        return zipObject(colHeader, dataInRow)
      })
    }
    return undefined
  }

  const handleCellChange = (changes: ReduxStore.Materials.Data.HOTChanges | null) => {
    if (!changes || changes.length === 0) {
      return
    }

    const filteredChanges = changes.filter(change => change[2] !== change[3])
    const rowsTobeUpdate = new Set<number>()
    filteredChanges.forEach(change => {
      rowsTobeUpdate.add(change[0])
    })
    if (tableRef.current) {
      const { getData } = tableRef.current.hotInstance
      if (rowsTobeUpdate.size > 0) {
        const hotData = getData()
        updateListWithoutOverlaysDes({
          hotData: getSortedSourceData(),
          rowsTobeUpdated: Array.from(rowsTobeUpdate),
        })
      }
    }
  }
  return (
    <div className={classNames(className, classes.root)}>
      <HotTable
        licenseKey="bc642-c159b-ce583-14136-ac809"
        renderer={emptyIdRenderer}
        ref={tableRef}
        data={data}
        columns={columns}
        colHeaders={Array.from(descriptions)}
        afterSelectionEnd={handleSelect}
        afterChange={changes => {
          if (changes) {
            handleCellChange(changes)
          }
        }}
        outsideClickDeselects={false}
        selectionMode="multiple"
        rowHeaders={true}
        manualColumnResize={true}
        manualRowResize={true}
        autoColumnSize={true}
        autoRowSize={true}
        // @ts-ignore
        contextMenu={{
          items: {
            remove_row: {
              callback: deleteRowsFromHOT,
            },
          },
        }}
      />
    </div>
  )
}

const mapStateToProps = (
  state: ReduxStore.State,
  { selectedMaterialType }: { selectedMaterialType: string }
) => ({
  materialsWithoutOverlays: getMaterialsWithoutOverlays(state),
  descriptions: getDescriptions(state),
  presets: getMaterialTypePresets(state, selectedMaterialType),
  descriptionTypes: getDescriptionTypes(state),
  listIds: getListIds(state),
  listIdValueMap: getListIdValueMap(state),
})

const mapDispatchToProps = {
  dispatchDeleteSelectedRecordsByIds: action.deleteSelectedRecordsByIds,
  updateListWithoutOverlaysDes: action.updateListWithoutOverlaysDes,
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
    // @ts-ignore
  )(MaterialTableWithoutOverlays)
)
