import * as authActions from '../auth/actions'
import * as draftActions from '../draft/actions'
import * as drawingsActions from '../drawings/actions'
import * as errorsActions from '../errors/actions'
import * as materialPresetActions from '../materialPresets/actions'
import * as materialsActions from '../materials/actions'
import * as materialTypesActions from '../materialTypes/actions'
import * as notificationActions from '../notifier/actions'
import * as projectsActions from '../projects/actions'
import * as toolsActions from '../tool/actions'

export default {
  ...authActions,
  ...projectsActions,
  ...drawingsActions,
  ...materialTypesActions,
  ...materialsActions,
  ...toolsActions,
  ...materialPresetActions,
  ...draftActions,
  ...errorsActions,
  ...notificationActions,
}
