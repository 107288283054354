import { startCase } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { getTool } from 'store/selectors'
import tools from './tools'

interface StateProps {
  currentTool: string
}

type Props = StateProps

const ToolOptions: React.FC<Props> = props => {
  const { currentTool } = props
  // @ts-ignore
  if (tools && tools[currentTool] && tools[currentTool].options) {
    // @ts-ignore
    const { options: ToolOptionsComponent } = tools[currentTool]
    return (
      <div>
        <h3>{startCase(currentTool)} options</h3>
        <ToolOptionsComponent />
      </div>
    )
  }
  return null
}

const mapStateToProps = (state: ReduxStore.State) => ({
  currentTool: getTool(state),
})

export default connect(mapStateToProps)(ToolOptions)
