import { createStyles, WithStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import classNames from 'utils/classNames'

const styles = createStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
})

interface Props extends WithStyles<typeof styles> {
  className?: string
}

const CenteredSpinner: React.FC<Props> = ({ classes, className }) => {
  return (
    <div className={classNames(classes.root, className)}>
      <CircularProgress />
    </div>
  )
}

export default withStyles(styles)(CenteredSpinner)
