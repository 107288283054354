export default Object.freeze({
  KEY_DELETE: 8,
  KEY_ESCAPE: 27,
  SELECT_FROM_HOT: 0,
  SELECT_FROM_VIEWER: 1,
  STATUS_FAILED: 'FAILED',
  STATUS_COMPLETED: 'COMPLETED',
  Z_INDEX_SIDEBAR: 2000,
  Z_INDEX_SELECT_MENU: 1000,
  PRECISION: 2000,
  OVERLAY_BLUE: 'rgba(17,80,135,0.3)',
  OVERLAY_YELLOW: 'rgba(255,188,51,0.3)',
})
