/**
 * Throttles a function using request animation frame.
 * @param {function} userCallback
 */
export function throttleRaf(userCallback: any) {
  let nextCallArgs: any = null

  const callback = () => {
    userCallback(...nextCallArgs)
    nextCallArgs = null
  }

  return (event: any, ...args: any[]) => {
    if (!nextCallArgs) {
      // Persist synthetic events
      if ('persist' in event) {
        event.persist()
      }

      nextCallArgs = [event, ...args]
      requestAnimationFrame(callback)
    }
  }
}
