import { API, isApiError } from 'api'
import { ThunkAction } from 'redux-thunk'
import { completeStatus, failedStatus } from 'utils/status'

export const setLoginStatus = (
  status: ReduxStore.Auth.Data.LoginStatus
): ReduxStore.Auth.SetLoginStatus => {
  return {
    type: 'SET_LOGIN_STATUS',
    payload: status,
  }
}

export const setSession = (session: ReduxStore.Auth.Data.Session): ReduxStore.Auth.SetSession => {
  return {
    type: 'SET_SESSION',
    payload: session,
  }
}

export const clearSession = (): ReduxStore.Auth.ClearSession => {
  return {
    type: 'CLEAR_SESSION',
  }
}

export const setUserDetails = (
  userDetails: ReduxStore.Auth.Data.UserDetails
): ReduxStore.Auth.SetUserDetails => {
  return {
    type: 'SET_USER_DETAILS',
    payload: userDetails,
  }
}

export const hydrateSession = (): ThunkAction<
  Promise<any> | void,
  ReduxStore.State,
  void,
  ReduxStore.Auth.Action
> => {
  return async dispatch => {
    try {
      const { response, body } = await API.getUserInfo()

      if (response.ok && !body.errorCode) {
        const sessionString = localStorage.getItem('session')
        if (typeof sessionString === 'string') {
          const session: ReduxStore.Auth.Data.Session = JSON.parse(sessionString)
          const userDetails = body.details
          // @ts-ignore
          window.analytics.identify(userDetails.user_id, {
            id: userDetails.user_id,
            username: userDetails.username || '',
            email: userDetails.email || '',
            firstName: userDetails.first_name || '',
            lastName: userDetails.last_name || '',
            company: {
              id: userDetails.company_id,
              name: userDetails.company_name || '',
            },
          })
          dispatch(setUserDetails(body))
          return dispatch(setSession(session))
        }
      }
      return dispatch(clearSession())
    } catch (error) {
      // console.error('Failed to hydrate existing session', error)
    }
    return null
  }
}

export const login = (
  email: string,
  password: string
): ThunkAction<void, ReduxStore.State, any, ReduxStore.Auth.Action> => {
  return async dispatch => {
    const { body: loginBody } = await API.login(email, password)
    if (isApiError(loginBody)) {
      return dispatch(setLoginStatus(failedStatus(loginBody.errorMessage as string)))
    }

    if (loginBody === 0) {
      return dispatch(setLoginStatus(failedStatus('Invalid Parameters.')))
    }
    dispatch(setSession(loginBody as ReduxStore.Auth.Data.Session))
    const { response, body } = await API.getUserInfo()
    if (response.ok && !body.errorCode) {
      dispatch(setUserDetails(body))
      const userDetails = body.details
      // @ts-ignore
      window.analytics.identify(userDetails.user_id, {
        id: userDetails.user_id,
        username: userDetails.username || '',
        email: userDetails.email || '',
        firstName: userDetails.first_name || '',
        lastName: userDetails.last_name || '',
        company: {
          id: userDetails.company_id,
          name: userDetails.company_name || '',
        },
      })
    }

    dispatch(setLoginStatus(completeStatus()))
  }
}
