import Divider from '@material-ui/core/Divider/Divider'
import React from 'react'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import DrawingSelector from './DrawingSelector'
import MaterialTypeSelector from './MaterialTypeSelector'
import ProjectSelector from './ProjectSelector'

type Props = DataloaderInfo

const UpperSidebar: React.FC<Props> = props => {
  const { selectedProject, selectedMaterialType } = props
  return (
    <div>
      <ProjectSelector />
      {selectedProject && (
        <React.Fragment>
          <MaterialTypeSelector />
          {selectedMaterialType && <DrawingSelector />}
        </React.Fragment>
      )}
      <Divider />
    </div>
  )
}

export default withSelectedValues(UpperSidebar)
