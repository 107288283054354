import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import ReactDOM from 'react-dom'

const selectorMountId = 'selector-menu-mount'

const styles = createStyles({
  portal: {
    width: '250px',
    height: '100%',
    backgroundColor: 'white',
  },
})

interface Props extends WithStyles<typeof styles> {
  id: string
  onMenuInteraction: (isProcess: boolean) => void
}

class SelectorMenu extends React.Component<Props> {
  mount: HTMLElement | null = null

  portal = this.constructPortal()

  componentDidMount() {
    this.mount = document.getElementById(selectorMountId)
    if (!this.mount) {
      const message = `Could't mount SelectorMenu (${this.getName()}). Couldn't find a mound with id ${selectorMountId}`
      throw new Error(message)
    }
    this.mount.appendChild(this.portal)
  }

  componentWillUnmount() {
    if (this.mount) {
      this.mount.removeChild(this.portal)
    }
  }

  getName() {
    const { id } = this.props
    return id || 'Anonymous'
  }

  /**
   * This notifies parent components that the menu is being interacted with. Specifically it is to
   * allow the SelectorInput component to keep the menu open after it has lost focus and the user
   * is clicking on the menu, otherwise the click event is lost.
   */
  handleInteractionStart = () => {
    const { onMenuInteraction } = this.props
    if (onMenuInteraction) {
      onMenuInteraction(true)
    }
  }

  handleInteractionEnd = () => {
    const { onMenuInteraction } = this.props
    if (onMenuInteraction) {
      onMenuInteraction(false)
    }
  }

  constructPortal() {
    const el = document.createElement('div')
    const { id, classes } = this.props
    el.id = id
    el.className = classes.portal
    return el
  }

  render() {
    const { children } = this.props
    const content = (
      <div
        role="presentation"
        onMouseDown={this.handleInteractionStart}
        // The onClick event is used to handle interaction end events because it actually happens
        // after the onMouseUp event.
        onClick={this.handleInteractionEnd}
      >
        {children}
      </div>
    )
    return ReactDOM.createPortal(content, this.portal)
  }
}

export default withStyles(styles)(SelectorMenu)
