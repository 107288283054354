import { createStyles, WithStyles } from '@material-ui/core'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import action from 'store/actions'
import { getDrawings, getDrawingsFetchingStatus } from 'store/selectors'
import modifyQueryString from 'utils/modifyQueryString'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import CenteredSpinner from '../../../shared/CenteredSpinner'
import SelectorInput from './selectorMenu/SelectorInput'

const styles = createStyles({
  link: {
    textDecoration: 'none',
    color: 'black',
  },
})

interface StateProps {
  drawings: ReduxStore.Drawings.Data.Drawing[]
  isFetching: boolean
}

interface DispatchProps {
  fetchDrawings: (cpid: number, pid: number) => void
  fetchMaterialsWithoutOverlays: (cpid: number, mtid: number) => void
}

type Props = StateProps & DispatchProps & DataloaderInfo & WithStyles<typeof styles>

const DrawingSelector: React.FC<Props> = props => {
  const [searchText, setSearchText] = useState('')
  const [dynamicSearch, setDynamicSearch] = useState(false)
  const {
    fetchDrawings,
    selectedProject,
    selectedDrawing,
    selectedMaterialType,
    drawings,
    projectId,
    fetchMaterialsWithoutOverlays,
  } = props

  useEffect(() => {
    if (!!selectedProject) {
      fetchDrawings(selectedProject, projectId)
    }
  }, [selectedProject])

  const getSearchDisplayText = () => {
    if (dynamicSearch) {
      return searchText
    }

    if (selectedDrawing !== null) {
      return selectedDrawing
    }
    return ''
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
    setDynamicSearch(true)
  }

  const clearSearchTextOverride = (displayName: string) => {
    setSearchText(displayName)
    setDynamicSearch(false)
    if (displayName === 'BLANK_DRAWING' && selectedProject && selectedMaterialType) {
      fetchMaterialsWithoutOverlays(selectedProject, selectedMaterialType)
    }
  }

  const filterDrawings = (drawings: ReduxStore.Drawings.Data.Drawing[]) => {
    const lowerSearchText = searchText.toLowerCase()
    return drawings.filter(({ displayName }) => displayName.toLowerCase().includes(lowerSearchText))
  }

  const drawingsList = () => {
    const { isFetching, drawings } = props

    if (isFetching) {
      return <CenteredSpinner />
    }

    if (!drawings) {
      return null
    }

    if (drawings.length === 0) {
      return 'No drawings to display'
    }

    const filteredDrawings = dynamicSearch ? filterDrawings(drawings) : drawings
    if (filteredDrawings.length === 0) {
      return 'No drawings match your search'
    }

    const { selectedDrawing, classes } = props
    return (
      <List>
        {filteredDrawings.map(({ id, displayName }) => (
          <Link
            to={{
              search: modifyQueryString({
                whitelist: ['materialType'],
                assign: { drawing: displayName },
              }),
            }}
            className={classes.link}
            key={id}
          >
            <ListItem
              button={true}
              selected={!!selectedDrawing && displayName === selectedDrawing}
              onClick={() => clearSearchTextOverride(displayName)}
            >
              <ListItemText primary={displayName} />
            </ListItem>
          </Link>
        ))}
      </List>
    )
  }

  if (
    drawings &&
    drawings.length &&
    !selectedDrawing &&
    selectedProject &&
    drawings[0].cpid === selectedProject
  ) {
    return (
      <Redirect
        to={{
          search: modifyQueryString({
            whitelist: ['materialType'],
            assign: { drawing: drawings[0].displayName },
          }),
        }}
      />
    )
  }

  return (
    <SelectorInput label="Drawing" onChange={handleChange} value={getSearchDisplayText()}>
      {drawingsList()}
    </SelectorInput>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  drawings: getDrawings(state),
  isFetching: getDrawingsFetchingStatus(state),
})

const mapDispatchToProps = {
  fetchDrawings: action.fetchDrawings,
  fetchMaterialsWithoutOverlays: action.fetchMaterialsWithoutOverlays,
}

export default withStyles(styles)(
  withSelectedValues(
    // @ts-ignore
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DrawingSelector)
  )
)
