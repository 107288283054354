import { find, get } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import modifyQueryString from 'utils/modifyQueryString'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'
import * as selectors from '../../store/selectors'
import DraftButtons from './DraftButtons'
interface StateProps {
  draft: ReduxStore.Draft.State
  materialTypes: ReduxStore.MaterialTypes.Data.MaterialType[]
  materialTypeIdMap?: Array<{ sourceId: string; draftId: string }>
  projects: ReduxStore.Projects.Data.Project[]
  initDraft: (projectId: number, cpid?: number) => void
  setStatus: (status: ReduxStore.Draft.DraftStatus) => void
}

type Props = StateProps & DataloaderInfo

const getRecordTypeIdByDraftMap = (
  recordTypeIdMap: Array<{ sourceId: string; draftId: string }>,
  isEnabled: boolean,
  selectedMaterialTypeId: string
) => {
  const recordTypeId = isEnabled
    ? get(find(recordTypeIdMap, ['sourceId', selectedMaterialTypeId]), 'draftId')
    : get(find(recordTypeIdMap, ['draftId', selectedMaterialTypeId]), 'sourceId')

  // This function return reverse recordTypeId,
  // If user type url to enter draft mode, it will return undefined
  // Use default selectedMaterialTypeId ifundefined
  return recordTypeId || selectedMaterialTypeId
}

const Draft: React.FC<Props> = props => {
  const {
    draft,
    history,
    materialTypeIdMap,
    selectedMaterialType,
    selectedDrawing,
    projectId,
    initDraft,
    selectedProject,
    projects,
    location,
    setStatus,
  } = props
  const { draftData, isEnabled, status } = draft

  interface UrlObj {
    cpid: number
    projectId: number
    param?: any
  }

  const redirectToUrl = (urlObj: UrlObj) => {
    const { cpid, projectId, param } = urlObj
    const pathname = `/${cpid}/${projectId}`

    if (!param) {
      history.push({
        pathname,
        search: modifyQueryString({
          whiteList: [],
        }),
      })
      return
    }

    history.push({
      pathname,
      search: modifyQueryString({
        assign: param,
      }),
    })
  }

  const getProjectUrlParam = (projectData: ReduxStore.Projects.Data.Project) => {
    const projectId = projectData.projectId
    const cpid = projectData.companyProjectId
    return { projectId, cpid }
  }

  // Change url when draftdata changed
  useEffect(() => {
    if (!draftData) {
      return
    }

    let urlObj: UrlObj
    if (isEnabled && draftData.draftProject) {
      urlObj = getProjectUrlParam(draftData.draftProject)
    } else {
      urlObj = getProjectUrlParam(draftData.sourceProject)
    }

    // Find reverse mtid, if undefined return original
    const materialType =
      (materialTypeIdMap &&
        selectedMaterialType &&
        getRecordTypeIdByDraftMap(materialTypeIdMap, isEnabled, selectedMaterialType.toString())) ||
      (selectedMaterialType && selectedMaterialType.toString())
    const materialTypeParam = materialType ? { materialType } : {}
    const drawing = selectedMaterialType && selectedDrawing ? { drawing: selectedDrawing } : {}
    const param = { ...drawing, ...materialTypeParam }
    redirectToUrl({ ...urlObj, param })
  }, [draftData, isEnabled])

  // Init draft
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current && projects.length > 0 && selectedDrawing && selectedMaterialType) {
      if (selectedProject) {
        initDraft(projectId, selectedProject)
      } else {
        initDraft(projectId)
      }
      firstUpdate.current = false
    }
  }, [projects])

  useEffect(() => {
    if (!draftData) {
      return
    }
    switch (status) {
      case 'CLEAN_UP_DRAFT_SUCCESS':
      case 'PUBLISH_DRAFT_SUCCESS':
        const urlObj = getProjectUrlParam(draftData.sourceProject)
        redirectToUrl(urlObj)
        setStatus('Default')
        break
      default:
        break
    }
  }, [status, draftData])
  return (
    <React.Fragment>
      <DraftButtons />
    </React.Fragment>
  )
}

const mapStateToProps = (state: ReduxStore.State) => ({
  draft: state.draft,
  materialTypes: selectors.getMaterialTypes(state),
  materialTypeIdMap: selectors.getRecordTypeIdMap(state),
  projects: selectors.getProjects(state),
})

const mapDispatchToProps = {
  initDraft: action.initDraftData,
  setStatus: action.setStatus,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Draft)
)
