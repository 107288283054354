import { combineReducers } from 'redux'
import undoable, { includeAction } from 'redux-undo'
import auth from '../auth/reducers'
import draft from '../draft/reducers'
import drawings from '../drawings/reducers'
import error from '../errors/reducers'
import materialPresets from '../materialPresets/reducers'
import materials from '../materials/reducers'
import materialTypes from '../materialTypes/reducers'
import notifications from '../notifier/reducers'
import projects from '../projects/reducers'
import tool from '../tool/reducers'

const reducers = {
  auth,
  tool,
  materials: undoable(materials, {
    filter: includeAction([
      'ADD_MATERIAL',
      'MERGE_OVERLAYS',
      'UPDATE_MATERIAL_DES',
      'ADD_MATERIALS',
      'DELETE_SELECTION',
      'DELETE_SELECTED_RECORDS_BY_IDS',
      'DELETE_MATCH_SHAPE',
      'REMOVE_SELECTION',
      'UPDATE_CIRCLE_POS',
      'DELETE_MATCH_MATERIALS',
      'CONFIRM_MATCH_MATERIALS',
      'UPDATE_MATERIAL_POS',
      'UPDATE_SCALE',
      'UPDATE_TRANSFORM',
      'UPDATE_OVERLAY_SHAPE',
      'UPDATE_OVERLAY_SHAPES',
      'ALIGN_SHAPES',
      'UPDATE_MATERIAL_MATCH',
      'UPDATE_LIST_WITHOUT_OVERLAYS_DES',
    ]),
    syncFilter: true,
  }),
  projects,
  drawings,
  materialTypes,
  materialPresets,
  draft,
  error,
  notifications,
}

export type Reducers = typeof reducers
export default combineReducers(reducers)
