import { chunk } from 'lodash'
import { ThunkAction } from 'redux-thunk'
import Swal from 'sweetalert2'
import { API } from '../../api'
import { getMaterialsFetchingStatus } from '../selectors'

export const requestMaterials = (): ReduxStore.Materials.RequestMaterials => {
  return {
    type: 'REQUEST_MATERIALS',
  }
}

export const receiveMaterials = (
  payload: ReduxStore.Materials.Data.IFetchedMaterials
): ReduxStore.Materials.ReceiveMaterials => {
  return {
    type: 'RECEIVE_MATERIALS',
    payload,
  }
}

export const receiveMaterialsWithoutOverlays = (payload: {
  [key: string]: ReduxStore.Materials.Data.IMaterialWithoutOverlays
}): ReduxStore.Materials.ReceiveMaterialsWithoutOverlays => {
  return {
    type: 'RECEIVE_MATERIALS_WITHOUT_OVERLAYS',
    payload,
  }
}

export const updateListWithoutOverlays = (
  payload: number[]
): ReduxStore.Materials.UpdateListWithoutOverlays => {
  return {
    type: 'UPDATE_LIST_WITHOUT_OVERLAYS',
    payload,
  }
}

export const updateListWithoutOverlaysDes = (
  desTobeUpdated: ReduxStore.Materials.Data.IDesTobeUpdated
): ReduxStore.Materials.UpdateListWithoutOverlaysDes => {
  return {
    type: 'UPDATE_LIST_WITHOUT_OVERLAYS_DES',
    payload: desTobeUpdated,
  }
}

export const setSelection = (
  selection: Array<number | string>,
  selectFrom: number
): ReduxStore.Materials.SetSelection => {
  return {
    type: 'SET_SELECTION',
    payload: { selection, selectFrom },
  }
}

export const resetSelection = (): ReduxStore.Materials.ResetSelection => {
  return {
    type: 'RESET_SELECTION',
  }
}

export const addSelection = (
  selection: number | string,
  selectFrom: number
): ReduxStore.Materials.AddSelection => {
  return {
    type: 'ADD_SELECTION',
    payload: { selection, selectFrom },
  }
}

export const deleteSelection = (
  selection: Array<number | string>
): ReduxStore.Materials.DeleteSelection => {
  return {
    type: 'DELETE_SELECTION',
    payload: selection,
  }
}

export const deleteSelectedRecordsByIds = (
  recordIdsToDelete: Array<number | string>
): ReduxStore.Materials.DeleteSelectedRecordsByIds => {
  return {
    type: 'DELETE_SELECTED_RECORDS_BY_IDS',
    payload: recordIdsToDelete,
  }
}

export const resetSelectedRecordsIds = (): ReduxStore.Materials.ResetSelectedRecordsByIds => {
  return {
    type: 'RESET_SELECTED_RECORDS_BY_IDS',
  }
}

export const removeSelection = (id: number | string): ReduxStore.Materials.RemoveSelection => {
  return {
    type: 'REMOVE_SELECTION',
    payload: id,
  }
}

export const setSelectFrom = (selectFrom: number): ReduxStore.Materials.SetSelectFrom => {
  return {
    type: 'SET_SELECT_FROM',
    payload: selectFrom,
  }
}

export const updateCirclePos = (
  newPos: ReduxStore.Materials.Data.INewCirclePos
): ReduxStore.Materials.UpdateCirclePos => {
  return {
    type: 'UPDATE_CIRCLE_POS',
    payload: newPos,
  }
}

export const updateMaterialPos = (
  newPos: ReduxStore.Materials.Data.INewMaterialPos
): ReduxStore.Materials.UpdateMaterialPos => {
  return {
    type: 'UPDATE_MATERIAL_POS',
    payload: newPos,
  }
}

export const updateScale = (
  newScale: ReduxStore.Materials.Data.INewScale
): ReduxStore.Materials.UpdateScale => {
  return {
    type: 'UPDATE_SCALE',
    payload: newScale,
  }
}

export const updateTransform = (
  rawShapeData: ReduxStore.Materials.Data.INewTransfrom
): ReduxStore.Materials.UpdateTransform => {
  return {
    type: 'UPDATE_TRANSFORM',
    payload: rawShapeData,
  }
}

export const alignShapes = (direct: string): ReduxStore.Materials.AlignShapes => {
  return {
    type: 'ALIGN_SHAPES',
    payload: direct,
  }
}

export const addMaterial = (
  material: ReduxStore.Materials.Data.IMaterial
): ReduxStore.Materials.AddMaterial => {
  return {
    type: 'ADD_MATERIAL',
    payload: material,
  }
}

export const addMaterials = (
  materials: ReduxStore.Materials.Data.IMaterial[]
): ReduxStore.Materials.AddMaterials => {
  return {
    type: 'ADD_MATERIALS',
    payload: materials,
  }
}

export const addMatchMaterial = (
  material: ReduxStore.Materials.Data.IMaterial
): ReduxStore.Materials.AddMaterial => {
  return {
    type: 'ADD_MATERIAL',
    payload: material,
  }
}

export const mergeOverlays = (
  overlaysTobeUpdate: ReduxStore.Materials.Data.IOverlaysTobeMerged
): ReduxStore.Materials.MergeOverlays => {
  return {
    type: 'MERGE_OVERLAYS',
    payload: overlaysTobeUpdate,
  }
}

export const updateMaterialDes = (
  desTobeUpdated: ReduxStore.Materials.Data.IDesTobeUpdated
): ReduxStore.Materials.UpdateMaterialDes => {
  return {
    type: 'UPDATE_MATERIAL_DES',
    payload: desTobeUpdated,
  }
}

export const updateMaterialIds = (
  materialIdMap: ReduxStore.Materials.Data.IMaterialIdMap
): ReduxStore.Materials.UpdateMaterialIds => {
  return {
    type: 'UPDATE_MATERIAL_IDS',
    payload: materialIdMap,
  }
}

export const updateMatchShape = (
  overlayTobeUpdated: ReduxStore.Materials.Data.IMatchTobeUpdated
): ReduxStore.Materials.UpdateMaterialMatch => {
  return {
    type: 'UPDATE_MATERIAL_MATCH',
    payload: overlayTobeUpdated,
  }
}

export const updateOverlayIds = (
  overlayIdMap: ReduxStore.Materials.Data.IOverlayIdMap
): ReduxStore.Materials.UpdateOverlayIds => {
  return {
    type: 'UPDATE_OVERLAY_IDS',
    payload: overlayIdMap,
  }
}

export const updateShapeData = (
  newShape: ReduxStore.Materials.Data.IOverlay
): ReduxStore.Materials.UpdateShapeData => {
  return {
    type: 'UPDATE_OVERLAY_SHAPE',
    payload: newShape,
  }
}

export const updateShapeDatas = (
  newShapes: ReduxStore.Materials.Data.IOverlay[]
): ReduxStore.Materials.UpdateShapeDatas => {
  return {
    type: 'UPDATE_OVERLAY_SHAPES',
    payload: newShapes,
  }
}
// Update template matched material whem certainty changes
export const updataMatchedMaterial = (
  certainty: number
): ReduxStore.Materials.UpdateMatchMaterial => {
  return {
    type: 'UPDATE_MATCH_MATERIAL',
    payload: certainty,
  }
}

export const deleteMatchedMaterials = (): ReduxStore.Materials.DeleteMatchedMaterials => {
  return { type: 'DELETE_MATCH_MATERIALS' }
}

export const confirmMatchedMaterials = (): ReduxStore.Materials.ConfirmMatchedMaterials => {
  return { type: 'CONFIRM_MATCH_MATERIALS' }
}

export const setMaterialAsOld = (): ReduxStore.Materials.SetMaterialAsOld => {
  return {
    type: 'SET_MATERIAL_AS_OLD',
  }
}

export const deleteMatchShape = (id: string): ReduxStore.Materials.DeleteMatchShape => {
  return {
    type: 'DELETE_MATCH_SHAPE',
    payload: id,
  }
}

export const fetchMaterials = (
  cpid: number,
  mtid: number
): ThunkAction<void, ReduxStore.State, any, ReduxStore.Materials.Action> => {
  return async (dispatch, getState) => {
    const state = getState()
    const isFetching = getMaterialsFetchingStatus(state)
    if (isFetching) {
      return
    }
    dispatch(requestMaterials())
    const matOverlayList = await API.getMaterialsWithoutOverlays(cpid, mtid)
    await dispatch(receiveMaterialsWithoutOverlays(matOverlayList))
    const response = await API.getMaterialsByType(cpid, mtid)
    await dispatch(receiveMaterials(response))
  }
}

export const fetchMaterialsWithoutOverlays = (
  cpid: number,
  mtid: number
): ThunkAction<void, ReduxStore.State, any, ReduxStore.Materials.Action> => {
  return async (dispatch, getState) => {
    const matOverlayList = await API.getMaterialsWithoutOverlays(cpid, mtid)
    await dispatch(receiveMaterialsWithoutOverlays(matOverlayList))
  }
}

export const deleteMaterials = (
  materialIdArray: number[]
): ThunkAction<void, ReduxStore.State, any, ReduxStore.Materials.Action> => {
  return async (dispatch, getState) => {
    console.log(materialIdArray)
    await API.deleteMaterialById(materialIdArray)
    dispatch(updateListWithoutOverlays(materialIdArray))
  }
}

export const saveAction = (
  selectedMaterialType: number,
  cpid: number
): ThunkAction<void, ReduxStore.State, any, ReduxStore.Materials.Action> => {
  return async (dispatch, getState) => {
    const state = getState()
    const {
      descriptionIds,
      descriptions,
      list,
      recordIdsToDelete,
      listWithoutOverlays,
    } = state.materials.present
    const { auth, cid, userid }: ReduxStore.Auth.Data.Session = JSON.parse(
      localStorage.getItem('session') || '{}'
    )
    const idGroup: {
      [key: string]: Set<number | string>
    } = {}
    let lastMaterialId: number | string

    // Newly added materials.
    const materialsTobeAdded = {
      timeout: false,
      compress: 'false',
      matList: Object.values(list).reduce<
        Array<{
          material_id: string
          t: number
          descIds: string[]
          pid: null
          d: { [key: string]: string }
        }>
      >((acc, material) => {
        const { value, material_id, isDeleted } = material

        if (!isDeleted && lastMaterialId !== material_id && typeof material_id === 'string') {
          const d = Object.values(value).reduce<{ [key: string]: string }>((acc, v, index) => {
            acc[descriptionIds[index]] = v
            return acc
          }, {})

          acc.push({
            material_id,
            t: selectedMaterialType,
            descIds: descriptionIds,
            pid: null,
            d,
          })

          lastMaterialId = material_id
        }
        return acc
      }, []),
      userid,
      auth,
      cpid,
      cid,
    }

    // Existing materials are updated.
    const materialsTobeUpdated = Object.values(list).filter(overlay => {
      const { isUpdateDes, isDeleted, material_id } = overlay
      return isUpdateDes === true && !isDeleted && typeof material_id === 'number'
    })

    const materialWithoutOverlayTobeUpdated = listWithoutOverlays.filter(material => {
      const { isUpdateDes, material_id } = material
      return isUpdateDes === true && typeof material_id === 'number'
    })

    // Complete list of materials that will be saved.
    const modifiedMaterialIds = [
      ...materialsTobeAdded.matList.map(mat => mat.material_id),
      ...materialsTobeUpdated.map(overlay => overlay.material_id),
      ...materialWithoutOverlayTobeUpdated.map(material => material.material_id),
    ]

    const modifiedOverlays = Object.values(list).filter(overlay =>
      modifiedMaterialIds.includes(overlay.material_id)
    )

    // We will now be doing error checking, ONLY for the modified materials and overlays. The unsaved materials are ignored.
    const emptyIDErrors = []
    const emptyStatusErrors = []
    let multipleMaterialIDError = false
    // check if there is empty ID
    for (const overlay of modifiedOverlays) {
      const { value, isDeleted, material_id } = overlay
      if (value.ID === '' && !isDeleted) {
        emptyIDErrors.push(overlay)
      }

      if (value.Status === '' && !isDeleted) {
        emptyStatusErrors.push(overlay)
      }

      if (idGroup[value.ID]) {
        if (!isDeleted) {
          idGroup[value.ID].add(material_id)
        }
      } else {
        if (!isDeleted) {
          idGroup[value.ID] = new Set()
          idGroup[value.ID].add(material_id)
        }
      }
    }

    if (emptyIDErrors.length > 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `ID cannot be empty! Overlays(${emptyIDErrors
          .map(overlay => overlay.overlay_id)
          .join(', ')})`,
      })
    } else if (emptyStatusErrors.length > 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: `Status cannot be empty! ID(${emptyStatusErrors
          .map(overlay => overlay.value.ID)
          .join(', ')})`,
      })
    } else {
      for (const descriptionID of Object.keys(idGroup)) {
        const materialsIDs = idGroup[descriptionID]
        if (Array.from(materialsIDs).length > 1) {
          // Get list of drawings where the error occurs.
          const drawings = new Set()
          Array.from(materialsIDs).forEach(id => {
            const overlayIDs = Object.keys(list).filter(
              overlayId => list[overlayId].material_id === id
            )
            overlayIDs.forEach(overlayId => {
              drawings.add(list[overlayId].display_name)
            })
          })
          const drawingMessage = Array.from(drawings).join(', ')

          multipleMaterialIDError = true
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: `There are multiple different materials under the same ID: ${descriptionID}, 
            please delete the ID and re-assign it! On Drawings: ${drawingMessage}`,
          })
          break
        }
      }
    }

    // if there is no empty ID and status
    if (emptyIDErrors.length === 0 && emptyStatusErrors.length === 0 && !multipleMaterialIDError) {
      // show loading symbol
      // @ts-ignore
      Swal.fire({
        title: 'Saving...',
        allowOutsideClick: false,
        // @ts-ignore
        zIndex: 2000,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const overlaysTobeAddedAfterAddingMaterials = Object.values(list).reduce(
        (acc: ReduxStore.Materials.Data.IOverlay[], overlay) => {
          const { overlay_id, material_id, drawing_id, shape, shape_data, isDeleted } = overlay
          if (typeof overlay_id !== 'number' && typeof material_id !== 'number' && !isDeleted) {
            acc.push({
              material_id,
              overlay_id,
              drawing_id,
              shape,
              shape_data: JSON.parse(shape_data),
            })
          }
          return acc
        },
        []
      )

      const overlaysTobeUpdatedAfterAddingMaterials = Object.values(list).reduce(
        (acc: ReduxStore.Materials.Data.IOverlay[], overlay) => {
          const {
            overlay_id,
            material_id,
            drawing_id,
            shape,
            shape_data,
            isDeleted,
            dirtyOverlay,
          } = overlay
          if (
            typeof overlay_id === 'number' &&
            typeof material_id !== 'number' &&
            !isDeleted &&
            dirtyOverlay
          ) {
            acc.push({
              material_id,
              overlay_id,
              drawing_id,
              shape,
              shape_data: JSON.parse(shape_data),
            })
          }
          return acc
        },
        []
      )

      const overlaysTobeUpdatedRepeatly = Object.values(list).reduce(
        (acc: ReduxStore.Materials.Data.IOverlay[], overlay) => {
          const {
            overlay_id,
            material_id,
            drawing_id,
            shape,
            shape_data,
            isDeleted,
            dirtyOverlay,
          } = overlay
          if (
            typeof overlay_id === 'number' &&
            typeof material_id === 'number' &&
            !isDeleted &&
            dirtyOverlay
          ) {
            acc.push({
              material_id,
              overlay_id,
              drawing_id,
              shape,
              shape_data: JSON.parse(shape_data),
            })
          }
          return acc
        },
        []
      )

      const overlaysTobeAddedForExistingMaterial = Object.values(list).reduce(
        (acc: ReduxStore.Materials.Data.IOverlay[], overlay) => {
          const { overlay_id, material_id, drawing_id, shape, shape_data, isDeleted } = overlay
          if (typeof overlay_id !== 'number' && typeof material_id === 'number' && !isDeleted) {
            acc.push({
              material_id,
              overlay_id,
              drawing_id,
              shape,
              shape_data: JSON.parse(shape_data),
            })
          }
          return acc
        },
        []
      )

      const overlaysTobeDeleted = Object.values(list).reduce((acc: number[], overlay) => {
        const { overlay_id, isDeleted } = overlay
        // if this overlay is already saved in DB
        if (typeof overlay_id === 'number' && isDeleted) {
          acc.push(overlay_id)
        }
        return acc
      }, [])

      // after adding new materials, update or add overlays
      if (materialsTobeAdded.matList.length > 0) {
        const { idMap } = await API.saveShapes(materialsTobeAdded)
        dispatch(updateMaterialIds(idMap))

        // add overlays
        if (overlaysTobeAddedAfterAddingMaterials.length > 0) {
          const newOverlayIds = await API.saveOverlays(overlaysTobeAddedAfterAddingMaterials, idMap)
          dispatch(updateOverlayIds(newOverlayIds))
        }

        // update overlays
        if (overlaysTobeUpdatedAfterAddingMaterials.length > 0) {
          await API.updateOverlays(overlaysTobeUpdatedAfterAddingMaterials, idMap)
        }
      }

      if (overlaysTobeAddedForExistingMaterial.length > 0) {
        const newOverlayIds = await API.saveOverlays(
          overlaysTobeAddedForExistingMaterial,
          undefined
        )
        dispatch(updateOverlayIds(newOverlayIds))
      }

      if (overlaysTobeUpdatedRepeatly.length > 0) {
        await API.updateOverlays(overlaysTobeUpdatedRepeatly, undefined)
      }

      // delete records
      if (recordIdsToDelete && recordIdsToDelete.length) {
        await API.deleteMaterialById(recordIdsToDelete)
        dispatch(resetSelectedRecordsIds())
        // refresh list without overlays
        dispatch(updateListWithoutOverlays(recordIdsToDelete as number[]))
      } else if (overlaysTobeDeleted.length > 0) {
        // delete overlays
        await API.deleteOverlaysByIds(overlaysTobeDeleted)
      }

      const chunkedMaterials = chunk(materialsTobeUpdated, 1)
      for (const subset of chunkedMaterials) {
        const promise = subset.map(async material => {
          const { material_id, value } = material
          const dataTobeUpdated = {
            compress: 'pako',
            idarray: [material_id],
            descarray: descriptionIds,
            valuearray: descriptions.map(des => value[des]),
            updateChildren: false,
            userid,
            auth,
            cpid,
            cid,
          }
          await API.updateShapes(dataTobeUpdated)
        })
        await Promise.all(promise)
      }
      for (const subset of chunk(materialWithoutOverlayTobeUpdated, 1)) {
        const promise = subset.map(async material => {
          const { material_id, value } = material
          const dataTobeUpdated = {
            compress: 'pako',
            idarray: [material_id],
            descarray: descriptionIds,
            valuearray: descriptions.map(des => value[des]),
            updateChildren: false,
            userid,
            auth,
            cpid,
            cid,
          }
          await API.updateShapes(dataTobeUpdated)
        })
        await Promise.all(promise)
      }

      Swal.close()

      dispatch(setMaterialAsOld())
    }
  }
}
