export const removeError = (index: number): ReduxStore.Error.RemoveError => {
  return {
    type: 'REMOVE_ERROR',
    payload: { index },
  }
}

export const addError = (error: ReduxStore.Error.Data): ReduxStore.Error.AddError => {
  return {
    type: 'ADD_ERROR',
    payload: error,
  }
}
