export const setMaterialPresets = (
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
): ReduxStore.MaterialPresets.SetMaterialPresets => {
  return {
    type: 'ADD_MATERIAL_PRESETS',
    payload: presets,
  }
}

export const deleteMaterialPresets = (
  presets: ReduxStore.MaterialPresets.MaterialPreset[]
): ReduxStore.MaterialPresets.DeleteMaterialPresets => {
  return {
    type: 'DELETE_MATERIAL_PRESETS',
    payload: presets,
  }
}

export const incrementMaterialPresets = (
  materialTypeId: number,
  increment: number = 1
): ReduxStore.MaterialPresets.IncrementMaterialPresets => {
  return {
    type: 'INCREMENT_MATERIAL_PRESETS',
    materialTypeId,
    increment,
  }
}

export const setAutoIncrement = (
  autoIncrement: boolean
): ReduxStore.MaterialPresets.SetAutoIncrement => {
  return {
    type: 'SET_AUTO_INCREMENT',
    autoIncrement,
  }
}

export const resetMaterialPresets = (): ReduxStore.MaterialPresets.ResetMaterialPresets => {
  return {
    type: 'RESET_MATERIAL_PRESETS',
  }
}
