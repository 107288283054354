import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import URLSearchParams from '@ungap/url-search-params'
import React from 'react'
import { connect } from 'react-redux'
import action from 'store/actions'
import { isToolBusy } from 'store/selectors'
import Swal from 'sweetalert2'
import withSelectedValues, { DataloaderInfo } from 'utils/withSelectedValues'

const useStyles = makeStyles({
  container: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    marginLeft: '10px',
  },
  fullWidth: {
    flex: '100% 0 0',
  },
  draftBtnArea: {
    top: '20px',
    right: '10px',
    position: 'absolute',
    zIndex: 1000,
    display: 'flex',
  },
  draftBtnPopupArea: {
    width: '120px',
  },
  draftBtnPopup: {
    width: '100%',
    borderRadius: '0px',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '12px',
  },
  arrowDown: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '12px',
  },
})

interface StateProps {
  draftState: ReduxStore.Draft.State
  isToolBusy: boolean
}

interface DispatchProps {
  dispatchToggleDraftMode: (enabled: boolean, projectId: number) => void
  dispatchCreateDraft: (projectId: number) => void
  dispatchCleanUpDraft: (draftProjectId: number) => void
  dispatchPublishDraftDraft: (sourceProjectId: number, draftProjectId: number) => void
}

type Props = StateProps & DispatchProps & DataloaderInfo

const DraftButtons: React.FC<Props> = props => {
  const {
    draftState,
    projectId,
    selectedMaterialType,
    selectedDrawing,
    dispatchToggleDraftMode,
    dispatchPublishDraftDraft,
    dispatchCleanUpDraft,
    isToolBusy,
    location,
  } = props

  const {
    loading,
    container,
    draftBtnArea,
    draftBtnPopupArea,
    draftBtnPopup,
    arrowDown,
  } = useStyles()
  const { draftData, isEnabled, isPending } = draftState
  const haveDraft = draftData && draftData.draftProject
  const params = new URLSearchParams(location.search)
  const isDraftFeatureOn = params.get('draft')

  // Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const toogleDraftMode = (isEnabled: boolean, projectId: number) => {
    setAnchorEl(null)
    if (!isEnabled) {
      Swal.fire({
        type: 'warning',
        title: 'Are you sure?',
        text: 'Any unsaved changes will be lost. Do you want to continue?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result: any) => {
        if (result.value) {
          dispatchToggleDraftMode(isEnabled, projectId)
        }
      })
    } else {
      dispatchToggleDraftMode(isEnabled, projectId)
    }
  }

  const cleanUpDraft = (projectId: number) => {
    setAnchorEl(null)
    Swal.fire({
      type: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then((result: any) => {
      if (result.value) {
        dispatchCleanUpDraft(projectId)
      }
    })
  }

  const publishDraft = () => {
    setAnchorEl(null)
    if (draftData && draftData.draftProject) {
      dispatchPublishDraftDraft(draftData.sourceProject.projectId, draftData.draftProject.projectId)
    }
  }

  const renderToggleBtn = () => (
    <Button className={arrowDown} aria-describedby={id} onClick={handleClick}>
      Current Mode: {isEnabled ? 'Draft Mode' : 'Live Mode'} <KeyboardArrowDownIcon />
    </Button>
  )

  if (!projectId) {
    return <React.Fragment />
  }

  const renderDraftControl = () => (
    <div>
      <div className={draftBtnArea}>
        {selectedMaterialType && selectedDrawing && renderToggleBtn()}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={draftBtnPopupArea}>
          <Button
            disabled={isPending}
            classes={{ root: draftBtnPopup }}
            onClick={e => toogleDraftMode(!isEnabled, Number(projectId))}
            type="button"
          >
            {isEnabled ? 'Live Mode' : 'Draft Mode'}
          </Button>
          {isEnabled && draftData && draftData.draftProject && (
            <Button
              classes={{ root: draftBtnPopup }}
              disabled={isPending}
              onClick={e =>
                draftData.draftProject && cleanUpDraft(draftData.draftProject.projectId)
              }
              type="button"
            >
              Discard Draft
              {isPending && (
                <CircularProgress classes={{ root: loading }} size={12} thickness={2} />
              )}
            </Button>
          )}
          {isEnabled && (
            <Button
              classes={{ root: draftBtnPopup }}
              type="button"
              onClick={e => publishDraft()}
              disabled={isPending || isToolBusy || !haveDraft}
            >
              Publish
            </Button>
          )}
        </div>
      </Popover>
    </div>
  )

  return <div className={container}>{isDraftFeatureOn && renderDraftControl()}</div>
}

const mapStateToProps = (state: ReduxStore.State) => ({
  draftState: state.draft,
  isToolBusy: isToolBusy(state),
})

const mapDispatchToProps = {
  dispatchToggleDraftMode: action.toggleAndInitDraftMode,
  dispatchCreateDraft: action.createDraft,
  dispatchCleanUpDraft: action.cleanUpDraft,
  dispatchPublishDraftDraft: action.publishDraft,
}

export default withSelectedValues(
  // @ts-ignore
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DraftButtons)
)
