import { SnackbarKey } from 'notistack'

export const enqueueSnackbar = (
  notification: ReduxStore.Notifications.Notification
): ReduxStore.Notifications.EnqueueSnackbar => {
  const key = notification.options && notification.options.key

  return {
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  }
}

export const closeSnackbar = (key?: SnackbarKey): ReduxStore.Notifications.CloseSnackbar => ({
  type: 'CLOSE_SNACKBAR',
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeSnackbar = (key: SnackbarKey): ReduxStore.Notifications.RemoveSnackbar => ({
  type: 'REMOVE_SNACKBAR',
  key,
})
