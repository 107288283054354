import { createStyles, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Route } from 'react-router-dom'
import classNames from 'utils/classNames'
import SnackBarNotifier from 'utils/SnackBarNotifier'
import Error from '../utils/Error'
import Draft from './draft/Draft'
import FloorplanViewer from './floorplanViewer/FloorplanViewer'
import MaterialPresets from './materialPresets'
import Sidebar from './sidebar/Sidebar'

const styles = createStyles({
  root: {
    flex: 1,
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

type Props = WithStyles<typeof styles>

const Dataloader: React.FunctionComponent<Props> = ({ classes }) => {
  // Analytics
  // @ts-ignore
  window.analytics.page({
    platform: 'dataloader',
    path: '/',
  })

  return (
    <div className={classNames(classes.root, 'hide-splash')}>
      <Sidebar width="250px" />
      <Route path="/:selectedProject" component={FloorplanViewer} />
      <Route path="/:selectedProject/:projectId" component={MaterialPresets} />
      <Route path="/:selectedProject/:projectId" component={Draft} />
      <Error />
      <SnackBarNotifier />
    </div>
  )
}

export default withStyles(styles)(Dataloader)
