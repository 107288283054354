import { API } from 'api'
import { ThunkAction } from 'redux-thunk'
import { getDrawingsFetchingStatus } from 'store/selectors'

export const requestDrawings = (): ReduxStore.Drawings.RequestDrawings => {
  return {
    type: 'REQUEST_DRAWINGS',
  }
}

export const receiveDrawings = (
  drawings: ReduxStore.Drawings.Data.Drawing[]
): ReduxStore.Drawings.ReceiveDrawings => {
  return {
    type: 'RECEIVE_DRAWINGS',
    payload: drawings,
  }
}

export const fetchDrawings = (
  cpid: number,
  projectId: number
): ThunkAction<void, ReduxStore.State, null, ReduxStore.Drawings.Action> => {
  return async (dispatch, getState) => {
    const state = getState()
    const isFetching = getDrawingsFetchingStatus(state)
    if (isFetching) {
      return
    }
    dispatch(requestDrawings())
    const drawings: ReduxStore.Drawings.Data.Drawing[] = await API.getDrawings(cpid, projectId)
    drawings.push({
      id: 0,
      displayName: 'BLANK_DRAWING',
      cpid,
      projectId,
    })
    dispatch(receiveDrawings(drawings))
  }
}

export const dispatchUpdateFloorplanAndTableCanvasDimensions = (
  ratio: number
): ReduxStore.Drawings.UpdateFloorplanAndTableCanvasDimensions => {
  return {
    type: 'UPDATE_FLOORPLAN_AND_TABLE_CANVAS_DIMENSIONS',
    payload: ratio,
  }
}
