import Konva from 'konva'
import { cloneDeep } from 'lodash'
import React from 'react'
import { Group, Line, Rect } from 'react-konva'
import { connect } from 'react-redux'
import actions from 'store/actions'

interface OwnProps {
  selectedMaterials: Array<number | string>
  material: ReduxStore.Materials.Data.IMaterial
  onMouseDown: (
    e: Konva.KonvaEventObject<MouseEvent>,
    m: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragStart: (id: number | string) => void
  onDragMove: (
    e: Konva.KonvaEventObject<MouseEvent>,
    m: ReduxStore.Materials.Data.IMaterial
  ) => void
  onDragEnd: (e: Konva.KonvaEventObject<MouseEvent>, m: ReduxStore.Materials.Data.IMaterial) => void
}

interface DispatchProps {
  dispatchDeleteSelectedShapes: (selection: Array<string | number>) => void
  dispatchKeepMatchShapes: (desTobeUpdated: ReduxStore.Materials.Data.IMatchTobeUpdated) => void
}

const Rectangle: React.FunctionComponent<OwnProps & DispatchProps> = props => {
  const {
    selectedMaterials,
    material,
    onMouseDown,
    onDragStart,
    onDragMove,
    onDragEnd,
    dispatchDeleteSelectedShapes,
    dispatchKeepMatchShapes,
  } = props
  const { overlay_id, shape_data } = material
  const points: ReduxStore.Materials.Data.IPoints = JSON.parse(shape_data).points
  const dimensions: ReduxStore.Materials.Data.IDimensions = {
    x: points[0],
    y: points[1],
    width: points[4] - points[0],
    height: points[5] - points[1],
  }
  // Cross in left side
  const crossSize = Math.max(Math.min(30, dimensions.width * 0.2), 10)
  const crossPoints1 = [2, 2, crossSize - 2, crossSize - 2]
  const crossPoints2 = [2, crossSize - 2, crossSize - 2, 2]
  // Tick in right side
  const tickPoints = [2, crossSize / 2, crossSize / 2, crossSize - 2, crossSize - 2, 2]
  // Delete the shape doesn't match
  const overlayIdArray = [overlay_id as number]
  const deleteMatchedShape = () => {
    dispatchDeleteSelectedShapes(overlayIdArray)
  }

  // Keep the shape matches
  const keepMatchedShape = () => {
    const overlayId = overlay_id as number
    dispatchKeepMatchShapes({
      overlayIdTobeUpdated: overlayId,
      overlayShape: 'rectangle',
    })
  }

  return (
    <Group>
      {
        // @ts-ignore
        <Rect
          ref={instance => {
            if (instance) {
              window.shapeRefs.push(instance)
              instance.scaleX(1)
              instance.scaleY(1)
            }
          }}
          draggable={selectedMaterials.some(id => id === overlay_id)}
          shapeId={overlay_id}
          name={overlay_id.toString()}
          materialBelongsTo={overlay_id}
          shape="rect"
          fill="rgba(255,127,80,0.5)"
          onMouseDown={evt => onMouseDown(evt, material)}
          onDragStart={() => onDragStart(overlay_id)}
          onDragMove={evt => onDragMove(evt, material)}
          onDragEnd={evt => onDragEnd(evt, cloneDeep(material))}
          {...dimensions}
        />
      }
      <Group
        onClick={keepMatchedShape}
        x={dimensions.x + dimensions.width - crossSize}
        y={dimensions.y}
      >
        <Rect
          x={0}
          y={0}
          width={crossSize}
          height={crossSize}
          fill={'rgba(63,255,0,0.2)'}
          cornerRadius={4}
        />
        <Line
          x={0}
          y={0}
          points={tickPoints}
          stroke="green"
          strokeWidth={3}
          tension={0.1}
          lineCap={'round'}
          lineJoin={'round'}
        />
      </Group>
      <Group onClick={deleteMatchedShape} x={dimensions.x} y={dimensions.y}>
        <Rect
          x={0}
          y={0}
          width={crossSize}
          height={crossSize}
          fill={'rgba(255,0,0,0.2)'}
          cornerRadius={4}
        />
        <Line x={0} y={0} points={crossPoints1} strokeWidth={3} stroke="red" lineCap={'round'} />
        <Line x={0} y={0} points={crossPoints2} strokeWidth={3} stroke="red" lineCap={'round'} />
      </Group>
    </Group>
  )
}

const mapDispatchToProps = {
  dispatchDeleteSelectedShapes: actions.deleteMatchShape,
  dispatchKeepMatchShapes: actions.updateMatchShape,
}

export default connect(
  null,
  mapDispatchToProps
  // @ts-ignore
)(Rectangle)
