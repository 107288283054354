import { ToolName as OutlinerToolName } from 'dataloader/tools/OutlinerTool'

const initState: ReduxStore.Tool.State = {
  activeTool: '',
  snappingEnabled: true,
  options: {
    [OutlinerToolName]: {
      colourThreshold: 27,
      blurRadius: 5,
      contourTolerance: 0,
      minVertices: 3,
      useBoundingBox: false,
    },
  },
  snappingDistance: 1000,
  hotTableRef: null,
  image: undefined,
  alignDistance: 30,
}

function tool(state: ReduxStore.Tool.State = initState, action: ReduxStore.Tool.Action) {
  switch (action.type) {
    case 'SELECT_TOOL':
      return {
        ...state,
        activeTool: action.payload,
      }

    case 'SET_SNAPPING_ENABLED':
      return {
        ...state,
        snappingEnabled: action.payload,
      }

    case 'SET_HOTTABLE_REF':
      return {
        ...state,
        hotTableRef: action.payload,
      }

    case 'SET_TOOL_OPTIONS':
      return {
        ...state,
        options: {
          ...state.options,
          [action.toolName]: action.options,
        },
      }

    case 'SET_SNAPPING_DISTANCE': {
      return {
        ...state,
        snappingDistance: action.payload,
      }
    }

    case 'SET_ALIGN_DISTANCE': {
      return {
        ...state,
        alignDistance: action.payload,
      }
    }

    case 'SET_IMAGE': {
      return {
        ...state,
        image: action.payload,
      }
    }

    default:
      return state
  }
}

export default tool
